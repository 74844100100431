import React, { useState } from "react";
import ourLanguages from "./languages.json";
import { useDispatch, useSelector } from "react-redux";
import { CHANGE_LANG } from "../../redux/reducers/gameSlice";
import { setLocalStorageValue } from "../../lib/localStorage";

export default function Languages(props) {
  const [isHovered, setHovered] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const dispatch = useDispatch();
  const langFont = useSelector((state) => state.game.langFont)


  const list = () => {
    const list = [];
    let readyLanguages = ["ENGLISH"];

    for (let i = 0; i < ourLanguages.length; i++) {
      list.push(
        <div
          style={{...langFont.stats}}
          className= {ourLanguages[i].isReady ? "lang-item" : 'dull-lang'}
          onClick={() => {
            dispatch(CHANGE_LANG(ourLanguages[i]));
            setLocalStorageValue("language", ourLanguages[i]);
            props.handleDropdown();
            props.analyticsFunc("language", ourLanguages[i].code)
          }}
          onMouseEnter={() => {
            setHovered(true);
            setHoveredIndex(i);
          }}
          onMouseLeave={() => {
            setHovered(false);
            setHoveredIndex(null);
          }}
        >
          <span
            className={`eng-script ${
              isHovered && hoveredIndex === i ? `script` : ``
            } `}
          >
            {ourLanguages[i]["eng-script"]}
          </span>
          <span
            className={`own-script ${
              isHovered && hoveredIndex === i ? `script` : ``
            } `}
          >
            {ourLanguages[i]["own-script"]}
          </span>
        </div>
      );
    }

    // //for pushing only non-ready languages which are in dull and not having the hovering effect with background color 
    // for (let i = 0; i < ourLanguages.length; i++) {
    //   list.push(
    //     <div
    //       className="lang-item"
    //       onClick={() => {
    //         dispatch(CHANGE_LANG(ourLanguages[i]));
    //         props.handleDropdown();
    //       }}
    //       onMouseEnter={() => {
    //         setHovered(true);
    //         setHoveredIndex(i);
    //       }}
    //       onMouseLeave={() => {
    //         setHovered(false);
    //         setHoveredIndex(null);
    //       }}
    //     >
    //       <span
    //         className={`eng-script ${
    //           isHovered && hoveredIndex === i ? `script` : ``
    //         } `}
    //       >
    //         {ourLanguages[i]["eng-script"]}
    //       </span>
    //       <span
    //         className={`own-script ${
    //           isHovered && hoveredIndex === i ? `script` : ``
    //         } `}
    //       >
    //         {ourLanguages[i]["own-script"]}
    //       </span>
    //     </div>
    //   );
    // }
    return list;
  };
  return (
    <div
      className="lang-list"
      style={{
        display: props.listLanguage ? "block" : "none",

      }}
    >
      {list()}
    </div>
    // <div></div>
  );
}
