import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import Countdown from "react-countdown";
import CloseIcon from "@mui/icons-material/Close";
import Switch from "@mui/material/Switch";
import "../App.css";
import { connect } from "react-redux";
import { gameActions } from "../redux/constants";
import { CHANGE_DARK_MODE } from "../redux/reducers/gameSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  ENABLE_GUIDE,
  DISABLE_GUIDE,
  TOGGLE_TOOLTIP
} from "../redux/reducers/gameSlice";
import { getLocalState, setLocalStorageValue } from "../lib/localStorage";
import Guess from "./Guess";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faCaretRight,
} from "@fortawesome/free-solid-svg-icons";
import { trumpCard, emptyCard } from "./Walkthrough";
import SolutionModal from "./modals/Solution";
import { tomorrow } from "./Home";
import { useTranslation } from "react-i18next";
import Intro from "./modals/Intro";
import Languages from "./languages/Languages";
import { v4 as uuidv4 } from 'uuid';
import { analytics } from '../firebase_setup/firebase';
import { logEvent } from "firebase/analytics";
import { userID } from "../App";
import { nanoid } from "nanoid";

function NavModal(props) {
  const dispatch = useDispatch();
  const getLocalStorageValue = getLocalState("gamestats");
  const { t } = useTranslation();

  const attemptsDist =
    getLocalStorageValue === null ? undefined : getLocalStorageValue.attempts;
  
  const state = useSelector((state) => state);
  const langFont = state.game.langFont;
  const isDarkMode = state.game.isDarkMode;
  const columnhint = state.game.columnHint;
  const isGuideNeed = state.game.isGuideNeed;
  const suitColor = state.game.suitColor;
  const deviceSize = state.game.deviceSize;

  const [language, setLanguage] = useState("ENGLISH");

  const [listLanguage, setListLanguage] = useState(false);
  
  const handleDropdown = () => {
    setListLanguage(!listLanguage);
  };

  let tutorial = getLocalState("tutorial");
  let a = [0, 1, 2, 3, 4];

  const maxValue = attemptsDist ? Math.max(...attemptsDist, 1) : 1;

  const [checked, setChecked] = useState(isDarkMode);
  const [alertChecked,setAlertChecked] = useState(state.game.tooltip)
  const [check, setCheck] = useState(false); // false showTutorial

  const [isTab1Active, setTab1Active] = useState(true);
  const [isTab2Active, setTab2Active] = useState(false);
  const handleShare = async () => {
    props.handleShareStatus();
  };

  const analyticsFunc = (decide, val) => {
    if(decide == "theme"){
      logEvent(analytics, 'selected_theme', {
        event_id: uuidv4(), 
        event_timestamp: Date.now(), 
        user_id: userID,
        session_id: uuidv4(),
        theme: val
      });
    } else if(decide == "tooltip") {
      logEvent(analytics, 'tooltip_changed', {
        event_id: uuidv4(), 
        event_timestamp: Date.now(), 
        user_id: userID,
        session_id: uuidv4(),
        tooltip: val
      });
    } else if (decide == "contact") {
      logEvent(analytics, 'contact', {
        event_id: uuidv4(), 
        event_timestamp: Date.now(), 
        user_id: userID,
        session_id: uuidv4(),
        contact: val
      });
    } else {
      logEvent(analytics, 'language_changed', {
        event_id: uuidv4(), 
        event_timestamp: Date.now(), 
        user_id: userID,
        session_id: uuidv4(),
        language: val
      });
    }
  }

  let theme = "dark"
  let tooltip = true

  const handleChange = (event) => {
    theme = event.target.checked ? "light" : "dark"
    setChecked(event.target.checked);
    analyticsFunc("theme", theme)
  };

  const handleAlert = (event) => {
    tooltip = event.target.checked ? true : false;
    analyticsFunc("tooltip", tooltip)
    setAlertChecked(event.target.checked);
  };

  const handleGuide = (event) => {
    if (event.target.check) dispatch(ENABLE_GUIDE(true));
    else dispatch(DISABLE_GUIDE(false));
  };

  // For checking CHECK buttton selected or not
  const handleTutorial = () => {
    if (check && props.showTutoModal) {
      setLocalStorageValue("tutorial", props.showTutoModal);
    }
    props.handleCloseTutorial(true);
  };

  useEffect(() => {
    dispatch(CHANGE_DARK_MODE(!checked));
    setLocalStorageValue("lightmode", checked);
  }, [checked]);

   useEffect(() => {

     dispatch(TOGGLE_TOOLTIP(alertChecked));
     setLocalStorageValue("tooltip", alertChecked);
   }, [alertChecked]);

  useEffect(() => {
    setTab1Active(true);
    setTab2Active(false);
  }, [props.showTutoModal]);

  const makeHintCards = () => {
    let hintCards = [];
    columnhint
      ? columnhint.map((hintCard) => {
          hintCards.push(
            emptyCard(suitColor.get(hintCard.suit), hintCard.rank)
          );
        })
      : hintCards.push(
          <span
            style={{ fontFamily: "satoshi-bold" }}
          >{`Start with any card`}</span>
        );
    return hintCards;
  };

  let hints = makeHintCards();


  return (
    <div>
      {/**Hint modal */}
      <Dialog
        PaperProps={{
          sx: {
            top: 0,
            backgroundColor: isDarkMode
              ? "var(--dark-body)"
              : "var(--light-body)",

            color: isDarkMode ? "#fbfbfb" : "#332d2d",
            width: "30vw",
            margin: "auto",
            ["@media (max-width: 468px)"]: {
              width: "calc(100vw - 2rem)",
              height: "fit-content",
              overflowY: "scroll",
              left: 0,
            },
            ["@media (min-width: 470px) and (max-width: 550px)"]: {
              width: "60vw",
              height: "fit-content",
              overflowY: "scroll",
              left: 0,
            },
            ["@media (min-width: 551px) and (max-width: 767px)"]: {
              width: "55vw",
              height: "fit-content",
              left: 0,
            },
            ["@media (min-width: 768px) and (max-width: 1024px)"]: {
              width: "calc(100vw - 17rem)",
              height: "fit-content",
              left: 0,
            },
            height: "fit-content",
          },
        }}
        open={props.isHintModalOpen}
        // TransitionComponent={Transition}
        onClose={props.handleCloseHint}
        onBackdropClick={true}
      >
        <DialogTitle style={{ fontFamily: "satoshi-light" }}>
          {t("labels.hint")}
          <IconButton
            onClick={props.handleCloseHint}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: isDarkMode ? "#fbfbfb" : "#332d2d",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Typography component={"div"}>
            <div
              className="hint-container"
              style={{ fontFamily: "satoshi-light", ...langFont.tutorial }}
            >
              {hints.length === 1
                ? t("hintMessages.startWithFollowingCard")
                : t("hintMessages.startWithFollowingCards")}
            </div>
            {isGuideNeed ? (
              <></>
            ) : (
              <div
                className="hint-container"
                style={{ marginTop: "var(--card-gap)" }}
              >
                {hints.map((hint) => {
                  return (
                    <div key={nanoid()} style={{ fontFamily: "satoshi-light" }}>
                      {hint}
                      <span>{` `}</span>
                    </div>
                  );
                })}
              </div>
            )}
          </Typography>
        </DialogContent>
      </Dialog>

      {/**First modal */}
      <Dialog
        PaperProps={{
          sx: {
            top: 0,
            backgroundColor: isDarkMode
              ? "var(--dark-body)"
              : "var(--light-body)",
            color: isDarkMode ? "#fbfbfb" : "#332d2d",
            width: "30vw",
            overflow: "hidden",
            margin: "auto",
            ["@media (max-width: 468px)"]: {
              width: "calc(100vw - 2rem)",
              height: "fit-content",
              overflowY: "hidden",
              left: 0,
            },
            ["@media (min-width: 470px) and (max-width: 550px)"]: {
              width: "60vw",
              height: "fit-content",
              overflowY: "hidden",
              left: 0,
            },
            ["@media (min-width: 551px) and (max-width: 767px)"]: {
              width: "55vw",
              height: "fit-content",
              left: 0,
            },
            ["@media (min-width: 768px) and (max-width: 1024px)"]: {
              width: "calc(100vw - 17rem)",
              height: "fit-content",
              left: 0,
            },
            ["@media (min-width: 768px) and (max-width: 1024px)"]: {
              width: "calc(100vw - 17rem)",
              height: "fit-content",
              left: 0,
            },

            height: "fit-content",
          },
        }}
        open={props.showTutoModal}
      >
        <DialogTitle>
          <IconButton
            onClick={() => {
              handleTutorial()
              props.buttonClick("intro", "close")
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: isDarkMode ? "#fbfbfb" : "#332d2d",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent
          style={{ display: "grid", gap: "calc(var(--card-size) / 4)" }}
        >
          <Intro
            isTab1Active={isTab1Active}
            setTab1Active={setTab1Active}
            setTab2Active={setTab2Active}
            isTab2Active={isTab2Active}
            handleTutorial={handleTutorial}
            check={check}
            setCheck={setCheck}
            handleHelp={props.handleHelp}
            buttonClick={props.buttonClick}
          />
        </DialogContent>
      </Dialog>

      {/**Guide modal */}
      <Dialog
        PaperProps={{
          sx: {
            top: 0,
            backgroundColor: isDarkMode
              ? "var(--dark-body)"
              : "var(--light-body)",
            color: isDarkMode ? "#fbfbfb" : "#332d2d",
            width: "30vw",
            ["@media (max-width: 468px)"]: {
              width: "calc(100vw - 2rem)",
              height: "fit-content",
              overflowY: "scroll",
              left: 0,
            },
            ["@media (min-width: 470px) and (max-width: 550px)"]: {
              width: "60vw",
              height: "fit-content",
              overflowY: "scroll",
              left: 0,
            },
            ["@media (min-width: 551px) and (max-width: 767px)"]: {
              width: "55vw",
              height: "fit-content",
              left: 0,
            },
            ["@media (min-width: 768px) and (max-width: 1024px)"]: {
              width: "calc(100vw - 17rem)",
              height: "fit-content",
              left: 0,
            },
            height: "fit-content",
          },
        }}
        open={props.isInfoModalOpen}
        onClose={props.handleCloseInfo}
        onBackdropClick={true}
      >
        <DialogTitle style={{ fontFamily: "satoshi-light" }}>
          {t("labels.help")}
          <IconButton
            onClick={props.handleCloseInfo}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: isDarkMode ? "#fbfbfb" : "#332d2d",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom></Typography>
          <Typography
            style={{
              display: "grid",
              gridTemplateColumns: "100px 100px",
              alignItems: "center",
              fontFamily: "satoshi-light",
            }}
          >
            {t("labels.guide")}
            <Switch checked={isGuideNeed} onChange={handleGuide} />
          </Typography>
        </DialogContent>
      </Dialog>

      {/**Settings modal */}
      <Dialog
        open={props.isSettingModalOpen}
        onClose={props.handleCloseSettings}
        onBackdropClick={true}
        PaperProps={{
          sx: {
            width: "30vw",
            backgroundColor: isDarkMode
              ? "var(--dark-body)"
              : "var(--light-body)",
            color: isDarkMode ? "#fbfbfb" : "#332d2d",
            ["@media (max-width: 468px)"]: {
              width: "calc(100vw - 2rem)",
              height: "fit-content",
              overflowY: "scroll",
              left: 0,
            },
            ["@media (min-width: 470px) and (max-width: 550px)"]: {
              width: "60vw",
              height: "fit-content",
              overflowY: "scroll",
              left: 0,
            },
            ["@media (min-width: 551px) and (max-width: 767px)"]: {
              width: "55vw",
              height: "fit-content",
              left: 0,
            },
            ["@media (min-width: 768px) and (max-width: 1024px)"]: {
              width: "calc(100vw - 17rem)",
              height: "fit-content",
              left: 0,
            },
            height: "fit-content",
            userSelect: "none",
          },
        }}
      >
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontFamily: "satoshi-light",
          }}
        >
          {t("labels.settings")}
          <IconButton
            onClick={props.handleCloseSettings}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: isDarkMode ? "#fbfbfb" : "#332d2d",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Typography component={"div"}>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: deviceSize < 550 ? "3fr calc(var(--card-size) * 3)" : "3fr calc(var(--card-size) * 4)",
                alignItems: "center",
                paddingLeft: "14px",
                ...langFont.stats,
              }}
            >
              <div style={{ fontFamily: "satoshi-light" }}>
                {t("labels.lightmode")}
              </div>
              <Switch checked={checked} onChange={handleChange} />

              <div
                style={{
                  fontFamily: "satoshi-light",
                  ...langFont.stats,
                }}
              >
                {t("labels.tooltip")}
              </div>
              <Switch checked={alertChecked} onChange={handleAlert} />

              <div>{t("labels.contact")}</div>
              <div className="selected-lang flex">
                <a
                  onClick={()=> {analyticsFunc("contact", "contact")}}
                  href="mailto:contact@cardoo.today?subject=Cardoo feedback&body=Hi, this is "
                  style={{ fontFamily: "satoshi-light" }}
                >
                  contact@cardoo.today
                </a>
              </div>

              <div
                style={{
                  fontFamily: "satoshi-light",
                  ...langFont.stats,
                }}
              >
                {t("labels.language")}
              </div>
              <div className="selected-lang flex" onClick={handleDropdown}>
                <span style={{ fontFamily: "satoshi-light" }}>
                  {state.game.language["own-script"]}
                </span>
                <FontAwesomeIcon
                  icon={listLanguage ? faCaretDown : faCaretRight}
                />
              </div>
            </div>
            {listLanguage ? (
              <Languages
                handleDropdown={handleDropdown}
                listLanguage={listLanguage}
                deviceSize={deviceSize}
                analyticsFunc={analyticsFunc}
              />
            ) : (
              <></>
            )}
          </Typography>
        </DialogContent>
      </Dialog>

      {/**Stats modal */}
      <Dialog
        open={props.isShareModalOpen}
        onClose={props.handleCloseShare}
        onBackdropClick={true}
        PaperProps={{
          sx: {
            // userSelect: "none",
            width: "30vw",
            backgroundColor: isDarkMode
              ? "var(--dark-body)"
              : "var(--light-body)",
            color: isDarkMode ? "#fbfbfb" : "#332d2d",
            ["@media (max-width: 468px)"]: {
              width: "calc(100vw - 2rem)",
              height: "fit-content",
              overflowY: "scroll",
              left: 0,
            },
            ["@media (min-width: 470px) and (max-width: 550px)"]: {
              width: "60vw",
              height: "fit-content",
              overflowY: "scroll",
              left: 0,
            },
            ["@media (min-width: 551px) and (max-width: 767px)"]: {
              width: "55vw",
              height: "fit-content",
              left: 0,
            },
            ["@media (min-width: 768px) and (max-width: 1024px)"]: {
              width: "calc(100vw - 17rem)",
              height: "fit-content",
              left: 0,
            },

            margin: "auto",
            height: "fit-content",
          },
        }}
      >
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontFamily: "satoshi-light",
          }}
        >
          {t("tooltip.statistics")}
          <IconButton
            onClick={props.handleCloseShare}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: isDarkMode ? "#fbfbfb" : "#332d2d",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography component={"div"}>
            <div className="stats-container">
              <div
                className="inner-stats-grid-container"
                style={{ ...langFont.stats }}
              >
                <div
                  className="stats-squares"
                  style={{
                    border: isDarkMode
                      ? "1px solid #eae5be"
                      : "1px solid black",
                    ...langFont.stats,
                  }}
                >
                  <div className="font">
                    {getLocalStorageValue
                      ? getLocalStorageValue.gamesPlayed
                      : 0}
                  </div>
                  <div className="light-font">{t("labels.stats.played")}</div>
                </div>
                <div
                  className="stats-squares"
                  style={{
                    border: isDarkMode
                      ? "1px solid #eae5be"
                      : "1px solid black",
                    ...langFont.stats,
                  }}
                >
                  <div className="font">
                    {getLocalStorageValue
                      ? getLocalStorageValue.winningPercentage
                      : 0}
                  </div>
                  <div className="light-font">
                    {t("labels.stats.winningPercentage")}
                  </div>
                </div>
                <div
                  className="stats-squares"
                  style={{
                    border: isDarkMode
                      ? "1px solid #eae5be"
                      : "1px solid black",
                    ...langFont.stats,
                  }}
                >
                  <div className="font">
                    {getLocalStorageValue
                      ? getLocalStorageValue.currentStreak
                      : 0}
                  </div>
                  <div
                    className="streak light-font"
                    style={{ ...langFont.stats }}
                  >
                    {t("labels.stats.current")}{" "}
                  </div>
                </div>
                <div
                  className="stats-squares"
                  style={{
                    border: isDarkMode
                      ? "1px solid #eae5be"
                      : "1px solid black",
                    ...langFont.stats,
                  }}
                >
                  <div className="font">
                    {getLocalStorageValue
                      ? getLocalStorageValue.maximumStreak
                      : 0}
                  </div>
                  <div
                    className="streak light-font"
                    style={{ ...langFont.stats }}
                  >
                    {t("labels.stats.max")}
                  </div>
                </div>
              </div>
              <div className="attempts-flex">
                <div className="inner-stats-guess-container">
                  {attemptsDist
                    ? attemptsDist.map((value, i) => {
                        return (
                          <Guess
                            key={i}
                            ind={i}
                            size={60 * (value / maxValue)}
                            label={value}
                          />
                        );
                      })
                    : a.map((i) => {
                        return (
                          <Guess
                            key={i}
                            ind={i}
                            size={60 * (0 / maxValue)}
                            label={0}
                          />
                        );
                      })}
                </div>
                <div className="attempts">
                  <div
                    style={{ fontFamily: "satoshi-light", ...langFont.stats }}
                  >
                    {t("labels.stats.winsPerAttempts")}
                  </div>
                </div>
              </div>
            </div>
          </Typography>
        </DialogContent>

        <DialogTitle>
          <div className="stats-footer">
            <div
              className="nxt-cardle"
              style={{ fontFamily: "satoshi-light", width: "100%" }}
            >
              {t("labels.nextCardleIn")
                .split(" ")
                .map((text) => {
                  if (text === "<countdown/>") {
                    return (
                      <span
                        key="12"
                        className="timer"
                        style={{
                          fontFamily: "satoshi-light",
                          fontWeight: "400",
                        }}
                      >
                        <Countdown date={tomorrow} daysInHours={true} />
                      </span>
                    );
                  }

                  return ` ${text} `;
                })}
            </div>

            <div className="two-stats-buttons" style={{ gap: "" }}></div>
          </div>
        </DialogTitle>
      </Dialog>

      <SolutionModal
        isSolutionModalOpen={props.isSolutionModalOpen}
        closeSolutionModal={props.closeSolutionModal}
        solution={props.solution}
        linesOfPlay={props.linesOfPlay}
        trump={props.trump}
        hands={props.hands}
        target={props.target}
      />
    </div>
  );
}


const mapStateToProps = (state) => {
  const { game } = state;
  return {
    isDarkMode: game.isDarkMode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setDarkMode: (params) =>
      dispatch({ type: gameActions.CHANGE_DARK_MODE, params: params }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavModal);

/**
 *           <Intro 
            isTab1Active={isTab1Active}
            setTab1Active={setTab1Active}
            setTab2Active={setTab2Active}
            isTab2Active={isTab2Active}
            handleTutorial={handleTutorial}
            check={check}
            setCheck={setCheck}
          />
 */
