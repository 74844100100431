import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { SET_ALERT_MESSAGE } from "../../redux/reducers/gameSlice";

const useAlert = () => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState("error");
  const [statusVisible, setStatusVisible] = useState(false);

  const alertMessage = useSelector((state) => state.game.alertMessage);

  useEffect(() => {
    const show = () => {
      setStatusVisible(true);
      setTimeout(() => {
        setStatusVisible(false);
        dispatch(SET_ALERT_MESSAGE(null));
      },4200);
    };
    if (alertMessage) show();
  }, [alertMessage]);

  const setAlertMessage = (statusType, message) => {
    setStatus(statusType);
    dispatch(SET_ALERT_MESSAGE(message));
  };

  return {
    status,
    statusVisible,
    setAlertMessage,
  };
};

export default useAlert;
