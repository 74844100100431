import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import useAlert from "./hooks/useAlert";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const Alert = () => {
  let alertMessage = useSelector((state) => state.game.alertMessage);
  const { statusVisible } = useAlert();
  const deviceSize = useSelector((state) => state.game.deviceSize);
  const { t } = useTranslation();
  const state = useSelector((state) => state);
  const isGuideNeed = useSelector((state) => state.game.isGuideNeed);
  const tricks = useSelector((state) => state.game.tricks);
  const isDarkMode = useSelector((state) => state.game.isDarkMode);
  const langFont = useSelector((state) => state.game.langFont);
  const alertStop = useSelector((state) => state.game.alertStop);
  let currentTrick = tricks[tricks.length - 1];
  let playedCards = currentTrick.filter((card) => card !== "");
  let color;

  // this only set bgColor for text alerting:-
  let bgColor;

  let textColor = isDarkMode ? "#ffffff" : "var(--font-color-tuto)";

  const manipulation = (string) => {
    console.log("messtatge", string);
    return string.map((word) => {
      if (word.startsWith("<i>") && word.endsWith("</i>")) {
        return <i className="light-font">{" " + word.slice(3, -4) + " "}</i>;
      }
      if (word.startsWith("<8>") && word.endsWith("</8>")) {
        color = "var(--green)";
        return (
          <span className="light-font">{" " + word.slice(3, -4) + " "}</span>
        );
      }
      if (word.startsWith("<9>") && word.endsWith("</9>")) {
        color = "var(--red)";
        return (
          <span className="light-font">{" " + word.slice(3, -4) + " "}</span>
        );
      }
      return <span className="light-font">{` ${word} `}</span>;
    });
  };

  const renderEnterButtonText = (string) => {
    // for manipulating the text from the translation
    return string.map((text) => {
      if (text === "<button/>") {
        // for enter button
        return (
          <span
            style={{
              fontFamily: "satoshi-light",
              border: "1px solid",
              display: "inline-flex",
              height:
                deviceSize < 550 ? "calc(var(--card-size) * .32)" : "1.2rem",
              width:
                deviceSize < 550 ? "calc(var(--card-size) * .32)" : "1.2rem",
              alignItems: "center",
              justifyContent: "center",
              verticalAlign: "middle",
              borderRadius: "0.2rem",
            }}
          >
            {" "}
            <FontAwesomeIcon icon={faCheck} fontSize="x-small" />
          </span>
        );
      }

      // for all other texts
      return <span className="light-font">{" " + text + " "}</span>;
    });
  };

  // if (alertMessage === t('alertMessages.followColor')) {
  //   let firstTurn = tricks[tricks.length - 1].find(playedCard => playedCard.turnOrder === 1)
  //   let playedCard
  //   if(firstTurn){
  //     playedCard = firstTurn.card
  //     if (playedCard.suit === 'P')
  //       color = '#14A44D'
  //     else if (playedCard.suit === 'Y')
  //       color = '#E4A11B'
  //     else if (playedCard.suit === 'B')
  //       color = '#54B4D3'
  //     else if (playedCard.suit === 'T')
  //       color = '#332D2D'
  //     else
  //       color = '#DC4C64'
  //   }
  // }

  let colorString = alertMessage?.split(" ");

  let needBg = false; //the flag says do we need the background color for the alert in the normal play
  let textWithButton = false; //the flag says do the alert message having button

  if (alertMessage) {
    if (alertMessage === t("alertMessages.followColor")) {
      needBg = true;
      color = state.game.suitColor.get(state.game.followSuit);
      textColor = "white";
    } else if (alertMessage.includes("<button/>")) {
      textWithButton = true;
    }
  }

  const alertsBg = (alertMessage) => {
    console.log("alerted color", alertMessage);
    if (alertMessage) alertMessage = manipulation(alertMessage.split(" "));

    return (
      <div
        style={{
          backgroundColor: color,
          color: textColor,
          borderRadius: "0.5rem",
          margin: "auto",
          boxShadow: "1px",
          width: "max-content",
          padding: ".25rem .6rem",
          textAlign: "center"
        }}
      >
        {alertMessage}
      </div>
    );
  };

  console.log("alert vio", color);

  return (
    alertStop ? null : 
    <div
      className="alert-size"
      style={{
        backgroundColor:
          isGuideNeed || needBg
            ? color
            : isDarkMode
            ? "white"
            : "var(--light-active-column)",
        borderRadius: "0.5rem",
        margin: "auto",
        boxShadow: "1px",
        position: "fixed",
        transform: "translateX(-50%)",
        left: "50%",
        top: isGuideNeed ? "8.2vh" : "10.5vh",
        width:
          deviceSize < 550
            ? "calc(var(--card-size) * 6)"
            : textWithButton
            ? "calc(var(--card-size) * 4)"
            : "max-content",
        visibility: !statusVisible || alertMessage === null ? "hidden" : "",
        zIndex: "9999",
        ...langFont.alert,
      }}
    >
      {isGuideNeed ? (
        <div style={{ padding: "0.5rem" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              color: textColor,
              fontSize:
                deviceSize < 550
                  ? "calc(var(--card-size) * .24)"
                  : "calc(var(--card-size) * .26)",
              lineHeight: "1.25rem",
              margin: "0",
              fontFamily: "satoshi",
              ...langFont.alert,
            }}
          >
            {alertsBg(alertMessage)}
          </div>
        </div>
      ) : (
        <div
          className={isDarkMode ? "dark-main-alert" : "light-main-alert"}
          style={{ color: needBg ? "white" : "", ...langFont.alert }}
        >
          {alertMessage
            ? textWithButton
              ? renderEnterButtonText(alertMessage.split(" "))
              : manipulation(alertMessage.split(" "))
            : ""}
        </div>
      )}
    </div>
  );
};
export default Alert;