import React from 'react'

export default function Guess(props) {  
  return (
    <div style={{display: 'flex', flexDirection:'column', justifyContent: 'flex-end'}}>
        <div style={{display: 'flex', justifyContent: 'center', fontWeight: '600', fontFamily: "satoshi"}} className="">{props.label}</div>
        <div style={{ height: `${ 2 + props.size }%`, backgroundColor: '#EAE5BE', padding: '0 10px'}} ></div>
      <div style={{ padding: '0 0px', fontSize: '15px', display: 'flex', justifyContent: 'center', fontFamily: "satoshi" }}>{props.ind + 1 }</div> 
    </div>
  )
}
