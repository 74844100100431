import { React, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  REDUCE_BALL_COUNT,
  SET_ANIMATED_TARGET,
  SET_ANIMATION,
  SET_ANIMATION_COMPLETE,
  SET_FADE_IN,
  UPDATE_INFINITE_ANIMATED_TARGET,
} from "../../redux/reducers/gameSlice";
import { darkBgColor, tricks } from "../Home";
import "../../App.css";
import { Color } from "../../constants/Colors";

const duration = 1000;
let animationInterval;

export default function Target(props) {
  const dispatch = useDispatch();
  const isDarkMode = useSelector((state) => state.game.isDarkMode);
  const deviceSize = useSelector((state) => state.game.deviceSize);
  const state = useSelector((state) => state);
  const ballCount = useSelector((state) => state.game.ballCount);
  const isAnimationNeed = useSelector((state) => state.game.isAnimationNeed);
  let animatedTarget = useSelector((state) => state.game.animatedTarget);
  const gameWon = useSelector((state) => state.game.gameWon);
  const gameLost = useSelector((state) => state.game.gameLost);
  const tricksArray = useSelector((state) => state.game.tricks);
  const isGuideNeed = useSelector((state) => state.game.isGuideNeed);
  const infiniteAnimation = useSelector(
    (state) => state.game.infiniteAnimation
  );
  const infiniteAnimatedTarget = useSelector(
    (state) => state.game.infiniteAnimatedTarget
  );
  const stopAnimation = useSelector((state) => state.game.stopAnimation);

  animatedTarget = props.target ? props.target : animatedTarget;
  const helpIntroFlag = useSelector((state) => state.game.helpIntroFlag);
  const target = state.game.target;
  const yesterdaySol = true;

  const emptyColor = isDarkMode ? Color.DARK_BODY : Color.LIGHT_BODY;
  const lastWinner = state.game.winners.length
    ? state.game.winners[state.game.winners.length - 1]
    : undefined;
  // lastWinner ? console.log("winner", lastWinner) : <></>;
    const length = props.target ? props.target.length : target.length; 

  const trick = useSelector(
    (state) => state.game.tricks[state.game.tricks.length - 1]
  );

  // For styling purpose only
  const getId = (card) => {
    let marginBallPlaceHolder = !state.game.helpIntroFlag
      ? `ball-placeholder-${card.length + 1}`
      : `guide-ball-placeholder-${card.length + 1}`;
    return marginBallPlaceHolder;
  };

  const targetGrid = {
    //target-grid
    width: `calc(var(--card-size) * ( ${length} + 2 )+ calc(var(--card-gap) * 14))`,
    // height: 'calc(var(--card-size) * 2)', // helpIntroFlag ? '' :  not need
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    // gap: , // not need
    marginBottom: helpIntroFlag
      ? "var(--card-gap)"
      : state.game.gameLost || state.game.gameWon
      ? ""
      : "",
    marginTop: helpIntroFlag ? "var(--card-gap)" : "",
    gap: props.target ? "calc(var(--card-gap) * 3)" : isGuideNeed ? "" : "calc(var(--card-gap) * 1.5)",
  };

  const innerBall = {
    width: ".15rem",
    height: ".15rem",
    backgroundColor: "red",
  }

  const trickyBalls = {
    //trick-balls comp
    width: helpIntroFlag
      ? "calc(var(--guide-card) / 3.5)"
      : "calc(var(--card-size) / 3.4)",
    height: helpIntroFlag
      ? "calc(var(--guide-card) / 3.5)"
      : "calc(var(--card-size) / 3.4)",
    backgroundColor: "var(--ball-color)",
    borderRadius: "50%",
  };

  const outerBall = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: ".15rem",
    border: ".1rem solid white",
  }

  // this is replaced by above
  const targetBalls = {
    //target-balls comp
    width: !helpIntroFlag
      ? deviceSize < 550
        ? "calc(var(--card-size) / 3.4)"
        : "calc(var(--card-size) / 3.2)"
      : "70%",
    height: !helpIntroFlag
      ? deviceSize < 550
        ? "calc(var(--card-size) / 3.4)"
        : "calc(var(--card-size) / 3.2)"
      : "70%",
    backgroundColor: "var(--ball-color)",
    boxShadow: `0px 0px 0px 0.1rem ${
      isDarkMode ? "var(--dark-body)" : "var(--light-body)"
    }, 0px 0px 0px 0.15rem var(--ball-color)`,
    borderRadius: "50%",
    overFlow: "visible",
  };

  const targetBallsWrapper = {
    display: "grid",
    gridTemplateColumns: "repeat(2, auto)",
    gridTemplateRows: "repeat(2, auto)",
    gap: ".2rem"
  }

  // this is replaced by above
  const targetBallsPlaceHolder = {
    //target-balls-placeholder comp
    width: helpIntroFlag
      ? "calc(var(--guide-card) / 2.51)"
      : "calc(var(--card-size) / 2.51)", //calc(var(--card-size) / 3.6)
    height: helpIntroFlag
      ? "calc(var(--guide-card) / 2.51)"
      : "calc(var(--card-size) / 2.51)",
    border: !helpIntroFlag
      ? ".09rem solid var(--ball-color)"
      : "", //not need
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderColor: "var(--ball-color)",
    overFlow: "visible",
  };


  const containerFlex = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: ".5rem",
    border: ".15rem solid white",
    width: "fit-content",
    height: "fit-content",
    borderRadius: ".5rem"
  }

  // this is replaced by above
  const targetCards = {
    width: helpIntroFlag
      ? "calc(var(--guide-card) + (2 * var(--card-gap)))"
      : "calc(var(--card-size) + (2 * var(--card-gap)))",
    height: helpIntroFlag
      ? "calc(var(--guide-card) + (2 * var(--card-gap)))"
      : "calc(var(--card-size) + (2 * var(--card-gap)))",
    display: "grid",
    gridTemplateColumns: helpIntroFlag
      ? deviceSize < 550
        ? "repeat(2, calc(var(--card-size) / 2.1))"
        : "repeat(2, calc(var(--card-size) / 2.5))"
      : "repeat(2, calc(var(--card-size) / 2))",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 auto",
    // borderRadius: (props.solution && props.handTarget) || helpIntroFlag  ? '0.5rem' : '.5rem .5rem 0px 0px',
    borderRadius: "0.5rem",
    border: "var(--card-gap) solid",
    rowGap: "2%",
    borderWidth: "6px",
    borderColor: "var(--hand-highlight)",
    backgroundColor: isDarkMode ? "rgb(51,51,51)" : "#ffffff",
  };

  const [fadeOut, setFadeOut] = useState(false);

  const [animatedColumn, setAnimatedColumn] = useState(null);

  const [animateBrokenBall, setAnimateBrokenBall] = useState(false);

  const highlightBox = isDarkMode ? Color.WHITE : "var(--hand-highlight)";
  const dullBox = isDarkMode
    ? darkBgColor.containers
    : "var(--light-container)";

  useEffect(() => {
    if (
      isAnimationNeed &&
      (state.game.currentTrick > 1 || state.game.gameLost || state.game.gameWon)
    ) {
      dispatch(SET_ANIMATION_COMPLETE(false));
      setFadeOut(true);
      if (target.some((t) => t == -1)) setAnimateBrokenBall(true);

      animationInterval = setTimeout(() => {
        setFadeOut(false);
        if (target.some((t) => t == -1)) setAnimateBrokenBall(true);

        dispatch(REDUCE_BALL_COUNT());
        dispatch(SET_ANIMATED_TARGET());

        if (target.every((t) => t != -1)) {
          setTimeout(() => {
            dispatch(SET_FADE_IN(false));
          }, duration);
        }
        setTimeout(() => {
          dispatch(SET_ANIMATION_COMPLETE(true));
        }, duration);
      }, duration);

      if (state.game.gameLost || state.game.gameLost) {
        dispatch(SET_ANIMATION(false));
      }
    }
  }, [
    state.game.gameLost,
    state.game.gameWon,
    state.game.currentTrick,
    isAnimationNeed,
    target,
  ]);

  useEffect(() => {
    if (lastWinner) setAnimatedColumn(lastWinner.player);
  }, [lastWinner]);

  useEffect(() => {
    if (isGuideNeed && infiniteAnimation) {
      if (
        !gameLost &&
        !gameWon &&
        tricksArray[tricksArray.length - 1].every(
          (playedCard) => playedCard != ""
        )
      ) {
        if (tricks.length > 0) {
          let winningPlayer = tricks.getWinningCard(tricksArray.length).player;
          dispatch(UPDATE_INFINITE_ANIMATED_TARGET(winningPlayer));
          setAnimatedColumn(winningPlayer);
          setAnimateBrokenBall(true);
        }
      }
    }
  }, [tricksArray, gameLost, gameWon, isGuideNeed, infiniteAnimation]);

  // Creates target container with or without balls
  const makeTargetCard = (currentTarget, currentPlayer) => {
    if (currentTarget === -1) currentTarget = 0;
    let card = [];
    //for empty placeholder

    for (let i = 0; i < currentTarget; i++) {
      card.push(
        // <div className={getClass(card)}>
        <div
          key={i}
          // className="target-balls-placeholder"
          style={targetBallsPlaceHolder}
          id={getId(card)}
        >
          <div
            key={i + 5}
            // className="target-balls" // trick-balls nu irukku @balaji
            // className = {classes.targetBalls}
            style={{
              ...targetBalls,
              backgroundColor: emptyColor,
            }} //props.solution ? yesterdaySol ? Color.BALL_GREY : emptyColor :
          ></div>
        </div>
      );
    }

    //for filled placeholder
    for (let i = 0; i < state.game.cardCount - currentTarget; i++) {
      // console.log(
      //   "fade in",
      //   i === 0 && animatedColumn === currentPlayer && state.game.fadeIn
      // );

      card.push(
        // winningRow ? <div></div> :
        // <div className={getClass(card)}>
        <div key={i + 4} style={targetBallsPlaceHolder} id={getId(card)}>
          <div
            key={i + 5}
            style={targetBalls}
            className={`target-balls ${
              i === 0 &&
              animatedColumn === currentPlayer &&
              state.game.fadeIn &&
              !stopAnimation &&
              !props.solution
                ? `ballFadeIn`
                : ``
            } ${
              i === 0 &&
              animatedColumn === currentPlayer &&
              infiniteAnimation &&
              infiniteAnimatedTarget[currentPlayer] != -1 &&
              !props.solution
                ? `infiniteBallFadeIn`
                : ``
            } `}
          ></div>
          {/* </div> *className="target-balls"  style={targetBalls} */}
        </div>
      );
    }

    return card;
  };

  const makeTrickBalls = () => {
    let trickBalls = [];

    for (let i = 0; i < ballCount; i++) {
      trickBalls.push(
        <div
          key={i + 3}
          className={
            props.forIntroPage && i === 0
              ? `infiniteBallFadeOut`
              : `${i === 0 && fadeOut ? "ballFadeOut" : ""} ${
                  i === 0 && infiniteAnimation ? "infiniteBallFadeOut" : ""
                }`
          }
          style={{
            ...trickyBalls,
            backgroundColor: props.final
              ? isDarkMode
                ? "var(--dark-body)"
                : "var(--light-body)"
              : "var(--ball-color)",
          }}
        ></div>
      );
    }
    return trickBalls;
  };

  const makeTargetItems = () => {
    let targetItems = [];
    let winnerCard = false;
    for (let i = 0; i <  length ; i++) {
      winnerCard = lastWinner
        ? lastWinner.player === i
          ? true
          : false
        : false;
      targetItems.push(
        <div key={i + 1} className="target-item">
          {!props.final ? (
            !props.forGuide ? (
              (infiniteAnimation && infiniteAnimatedTarget[i] === "-1") ||
              animatedTarget[i] === "-1" ? (
                //broken ball
                <div
                  className={`broken-ball 
                  ${
                    animateBrokenBall
                      ? infiniteAnimation
                        ? "infiniteBallFadeIn"
                        : !stopAnimation
                        ? "ballFadeIn"
                        : ""
                      : ""
                  }`}
                >
                  <svg
                    width="100%"
                    height="100%"
                    viewBox="0 0 48.037395 40.76619"
                    fill="none"
                    version="1.1"
                    id="svg25"
                    sodipodiDocname="bb"
                    inkscapeVersion="1.2.2 (b0a8486541, 2022-12-01, custom)"
                    xmlnsInkscape="http://www.inkscape.org/namespaces/inkscape"
                    xmlnsSodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnssvg="http://www.w3.org/2000/svg"
                  >
                    <defs id="defs29">
                      <clipPath
                        clipPathUnits="userSpaceOnUse"
                        id="clipPath1390"
                      >
                        <path
                          d="M 34.9162,52.6025 28.7249,51.6872 0,49.4624 8.7961197,0.21935 36.9723,11.4608 l -1.1992,8.1119 -2.2882,3.8063 3.5606,7.0392 -4.0624,4.1361 2.2636,8.0317 -3.8054,2.3978 z"
                          fill="#54b4d3"
                          id="path1392"
                          style={{
                            fill: "var(--ball-color)",
                            fillOpacity: "1",
                          }}
                        />
                      </clipPath>
                      <clipPath
                        clipPathUnits="userSpaceOnUse"
                        id="clipPath2919"
                      >
                        <path
                          d="M 0.326931,9.5477 8.62266,8.20034 46.3921,-6.1e-5 52.5772,50.646299 12.3082,49.9555 10.9677,41.7025 12.56,37.2085 5.47355,31.7038 9.23253,26.2529 3.46291,19.3243 7.50913,15.6418 Z"
                          fill="#54b4d3"
                          id="path2921"
                          style={{
                            fill: "var(--ball-color)",
                            fillOpacity: "1",
                          }}
                        />
                      </clipPath>
                    </defs>
                    <sodipodiNamedview
                      id="namedview27"
                      pagecolor="#ffffff"
                      bordercolor="#000000"
                      borderopacity="0.25"
                      inkscapeShowpageshadow="2"
                      inkscapePageopacity="0.0"
                      inkscapePagecheckerboard="0"
                      inkscapeDeskcolor="#d1d1d1"
                      showgrid="false"
                      inkscapeZoom="14.327273"
                      inkscapeCx="19.263959"
                      inkscapeCy="22.998096"
                      inkscapeWindow-width="1920"
                      inkscapeWindow-height="1016"
                      inkscapeWindow-x="0"
                      inkscapeWindow-y="0"
                      inkscapeWindow-maximized="1"
                      inkscapeCurrent-layer="svg25"
                    />
                    <g
                      mask="none"
                      id="g23"
                      transform="translate(-15.0194,-10.627474)"
                      style={{ fill: "var(--ball-color)", fillOpacity: "1" }}
                      clip-path="url(#clipPath1390)"
                    >
                      <circle
                        cx="35.019402"
                        cy="31.3895"
                        r="20"
                        transform="rotate(-81.5906,35.0194,31.3895)"
                        fill="#145FA4"
                        id="circle21"
                        style={{
                          mixBlendMode: "multiply",
                          fill: "var(--ball-color)",
                          fillOpacity: "1",
                          fillRule: "nonzero",
                        }}
                      />
                    </g>
                    <g
                      mask="none"
                      id="g1415"
                      transform="rotate(6.5942724,88.556311,189.64431)"
                      style={{ fill: "var(--ball-color)", fillOpacity: "1" }}
                      clip-path="url(#clipPath2919)"
                    >
                      <ellipse
                        rx="20.393101"
                        ry="20"
                        transform="matrix(0.160315,0.987066,0.987066,-0.160315,7.54385,29.036)"
                        fill="var(--ball-color)"
                        id="ellipse1413"
                        cx="0"
                        cy="0"
                        style={{ fill: "var(--ball-color)", fillOpacity: "1" }}
                      />
                    </g>
                  </svg>
                </div>
              ) : (
                <div
                  // className="trick-balls"
                  style={{
                    ...trickyBalls,
                    backgroundColor: emptyColor,
                  }}
                ></div>
              )
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
          <div
            style={{
              backgroundColor:
                // (!props.solution || helpIntroFlag)
                !props.solution
                  ? !helpIntroFlag
                    ? state.game.tricks.length === 1
                      ? trick[i] === ""
                        ? highlightBox
                        : dullBox
                      : trick.every((card) => card === "")
                      ? lastWinner.player === i
                        ? highlightBox
                        : dullBox
                      : trick[i] === ""
                      ? highlightBox
                      : dullBox
                    : dullBox
                  : dullBox,
              borderRadius: props.solution
                ? props.solution && props.handTarget
                  ? "0.5rem"
                  : "0.5rem 0.5rem 0 0"
                : helpIntroFlag || tricksArray.length === 1
                ? props.buttonVisible
                  ? "0.5rem 0.5rem 0 0"
                  : "0.5rem"
                : ".5rem .5rem 0px 0px",
              // marginBottom: tricksArray.length === 1 && trick.every((card) => card === "") ? "6px" : ""
            }}
          >
            <div
              style={{
                ...targetCards,
                borderColor: !props.solution
                  ? !helpIntroFlag
                    ? state.game.tricks.length === 1
                      ? trick[i] === ""
                        ? highlightBox
                        : dullBox
                      : trick.every((card) => card === "")
                      ? lastWinner.player === i
                        ? highlightBox
                        : dullBox
                      : trick[i] === ""
                      ? highlightBox
                      : dullBox
                    : dullBox
                  : dullBox,
              }}
            >
              {props.final
                ? makeTargetCard(0)
                : props.target
                ? makeTargetCard(
                    +props.target[i],
                    i,
                    yesterdaySol,
                    props.winner
                  )
                : makeTargetCard(
                    infiniteAnimation
                      ? +infiniteAnimatedTarget[i]
                      : +animatedTarget[i],
                    i
                  )}
            </div>
          </div>
        </div>
      );
    }
    return targetItems;
  };

  return (
    <>
      <div style={targetGrid}>
        {props.solution || props.handTarget ? (
          <></>
        ) : (
          <div
            className="trick-balls-container"
            style={{
              marginBottom: helpIntroFlag ? "calc(var(--card-gap) * 1.3)" : "",
            }}
          >
            {makeTrickBalls()}
          </div>
        )}
        <div>
          <div className="target-cards-container" style={{ '--player-count': length}}>{makeTargetItems()}</div>
        </div>
      </div>
    </>
  );
}
