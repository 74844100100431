import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { analytics } from "../firebase_setup/firebase";
import { logEvent } from "firebase/analytics";
import {
  UPDATE_WALKTHROUGH_STEP,
  DISABLE_GUIDE,
  SET_INTRO_FLAG,
  GAME_UNLOCK,
  SET_ALERT_MESSAGE,
} from "../redux/reducers/gameSlice";
import "../App.css";
import {
  redBorder,
  greenBorder,
  blueBorder,
  yellowBorder,
} from "./Grid/HandGridData";
import tutorialProblems from "../constants/tutorialProblems.json";
import { tricks, darkBgColor } from "./Home";
import { trump, ourTrumpColor } from "./Home";
import Target from "./Grid/Target";
import { userID } from "../App";
import { createGlobalStyle } from "styled-components";

export let followColor;
export let suitColors;

export const emptyCard = (bgColor, rank) => {
  return (
    <div
      className="empty-card"
      style={{
        backgroundColor: bgColor,
        color: !rank ? bgColor : "white",
        fontFamily: "satoshi-bold",
        fontSize: "0.8rem",
      }}
    >
      {rank ? rank : 1}
    </div>
  );
};
export const trumpCard = (winner) => {
  return (
    <span
      className="empty-trump-outer"
      style={{ backgroundColor: ourTrumpColor }}
    >
      <span
        className="empty-trump-inner"
        style={{
          backgroundColor: ourTrumpColor,
          color: winner ? "var(--white)" : ourTrumpColor,
          border: "1px solid",
          fontSize: "12px",
          borderColor: "var(--white)",
          fontFamily: "satoshi-bold",
        }}
      >
        {winner !== undefined ? `${winner.card.rank}` : `5`}
      </span>
    </span>
  );
};

export default function Walkthrough(props) {
  const state = useSelector((state) => state);
  const isDarkMode = state.game.isDarkMode;
  const langFont = state.game.langFont;
  const hands = state.game.hands;
  const step = state.game.walkthroughStep;
  const gameWon = state.game.gameWon;
  const helpIntroFlag = state.game.helpIntroFlag;
  const deviceSize = state.game.deviceSize;
  const isGuideNeed = state.game.isGuideNeed;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isGuideHovered, setGuideHovered] = useState(false);
  const [isGotItHovered, setGotItHovered] = useState(false);
  const [isRepeatHovered, setRepeatHovered] = useState(false);
  const [isTodaysCardleHovered, setTodaysCardleHovered] = useState(false);
  const [isNextHovered, setNextHovered] = useState(false);
  const [isPrevHovered, setPrevHovered] = useState(false);

  let currentTrick = state.game.tricks[state.game.currentTrick - 1];
  let lastWinner = state.game.winners.length
    ? state.game.winners[state.game.winners.length - 1]
    : undefined;
  let lastTrick =
    state.game.tricks && state.game.winners.length
      ? state.game.tricks[state.game.winners.length - 1]
      : undefined;

  // let guideCardSize = isGuideNeed ?

  const suitColor = new Map([
    ["G", greenBorder.backgroundColor],
    ["R", redBorder.backgroundColor],
    ["Y", yellowBorder.backgroundColor],
    ["B", blueBorder.backgroundColor],
    ["T", ourTrumpColor],
  ]);
  suitColors = suitColor;
  const followSuit = new Map([
    ["R", { text: "red", color: redBorder.backgroundColor }],
    ["G", { text: "green", color: greenBorder.backgroundColor }],
    ["B", { text: "blue", color: blueBorder.backgroundColor }],
    ["Y", { text: "yellow", color: yellowBorder.backgroundColor }],
    ["T", { text: "power", color: ourTrumpColor }],
  ]);
  const columnName = new Map([
    [0, "left"],
    [1, "middle"],
    [2, "right"],
  ]);
  let aa = [...currentTrick];

  var todaysCardoo = "todaysCardoo";
  var practiceCardoo = "practiceCardoo";
  var resetWon = "gameWonAfterReset";
  var midReset = "midReset";

  // This is for Google Analytics
  const tutorialLogFunction = (decide, eventName) => {
    if (decide == "start") {
      logEvent(analytics, "tutorial_start", {
        event_id: uuidv4(),
        event_timestamp: Date.now(),
        user_id: userID,
        session_id: uuidv4(),
      });
    } else {
      logEvent(analytics, "tutorial_end", {
        event_id: uuidv4(),
        event_timestamp: Date.now(),
        user_id: userID,
        session_id: uuidv4(),
        end_type:
          eventName == todaysCardoo
            ? todaysCardoo
            : eventName == practiceCardoo
            ? practiceCardoo
            : resetWon,
      });
    }
  };

  aa.sort((a, b) => {
    return a && b ? a.card.rank - b.card.rank : <></>;
  });

  let followText = state.game.followSuit
    ? followSuit.get(state.game.followSuit).text
    : "no";
  followColor =
    state.game.followSuit === ""
      ? lastWinner
        ? followSuit.get(lastWinner.card.suit).color
        : "black"
      : state.game.followSuit === "T"
      ? ourTrumpColor
      : followSuit.get(state.game.followSuit).color;
  //checking trump problem or not
  let isTrump = trump ? true : false;
  // if it is atrump problem, checking if we have a trump card or not
  let havingTrump;

  let playedCardCount = 0;
  let canMatchTheColour = false;
  let playedHandsCount = 0;
  let winner;
  let emptyCardsIndex = [];
  for (let i = 0; i < currentTrick.length; i++) {
    if (currentTrick[i] === "") {
      emptyCardsIndex.push(i);
    }
  }
  let nonMatchingCard = currentTrick.filter(
    (cardPlayed) =>
      cardPlayed !== "" && cardPlayed.card.suit !== state.game.followSuit
  );
  let nonMatchingTrump = nonMatchingCard.filter(
    (cardPlayed) => cardPlayed.card.suit === "T"
  );
  let isWinnerHavingTrump = lastWinner
    ? hands[lastWinner.player].find((card) => card.suit === "T")
      ? true
      : false
    : false;
  let isWinnerHavingColor = lastWinner
    ? hands[lastWinner.player].find((card) => card.suit !== "T")
      ? true
      : false
    : false;
  ////styling

  const resetFont = {
    fontSize: "calc(var(--card-size) * .20)",
    fontFamily: "satoshi-light",
    ...langFont.tutorialButtons,
  };

  const style = {
    gotItButton: {
      color: "rgb(45 45 45 / 70%)",
      fontWeight: "bold",
      fontSize: "calc(var(--card-size) * .21)",
      ...langFont.tutorial,
      backgroundColor:
        !props.isTouchEnabled &&
        (props.isHandHovered || isGuideHovered || isGotItHovered)
          ? isDarkMode
            ? "var(--white)"
            : "var(--light-active-column)"
          : isDarkMode
          ? "var(--dark-container)"
          : "var(--light-container)",
      borderColor:
        !props.isTouchEnabled &&
        (props.isHandHovered || isGuideHovered || isGotItHovered)
          ? isDarkMode
            ? "var(--dark-container)"
            : "var(--light-container)"
          : isDarkMode
          ? "var(--white)"
          : "var(--light-active-column)",
      color:
        !props.isTouchEnabled &&
        (props.isHandHovered || isGuideHovered || isGotItHovered)
          ? isDarkMode
            ? "var(--dark-container)"
            : "var(--light-container)"
          : isDarkMode
          ? "var(--white)"
          : "var(--font-color-tuto)",
      padding: "12px",
      marginTop: "15px",
      //borderRadius: "calc(var(--card-gap) * 2.5)",
      boxShadow: isDarkMode
        ? "0px 1px 2.5px var(--button-shadow)"
        : "1px 2px 4px",
      whiteSpace: "nowrap",
    },
    repeatButton: {
      // border: "2px solid rgb(45 45 45 / 70%)",
      color: "rgb(45 45 45 / 70%)",
      fontWeight: "700",
      fontSize: "calc(var(--card-size) * .21)",
      ...langFont.tutorial,
      backgroundColor:
        !props.isTouchEnabled &&
        (props.isHandHovered || isGuideHovered || isRepeatHovered)
          ? isDarkMode
            ? "var(--white)"
            : "var(--light-active-column)"
          : isDarkMode
          ? "var(--dark-container)"
          : "var(--light-container)",
      borderColor:
        !props.isTouchEnabled &&
        (props.isHandHovered || isGuideHovered || isRepeatHovered)
          ? isDarkMode
            ? "var(--dark-container)"
            : "var(--light-container)"
          : isDarkMode
          ? "var(--white)"
          : "var(--light-active-column)",
      color:
        !props.isTouchEnabled &&
        (props.isHandHovered || isGuideHovered || isRepeatHovered)
          ? isDarkMode
            ? "var(--dark-container)"
            : "var(--light-container)"
          : isDarkMode
          ? "var(--white)"
          : "var(--font-color-tuto)",
      padding: "12px",
      marginTop: "15px",
      //borderRadius: "calc(var(--card-gap) * 2.5)",
      boxShadow: isDarkMode
        ? "0px 1px 2.5px var(--button-shadow)"
        : "1px 2px 4px",
      whiteSpace: "nowrap",
    },
    todaysCardleButton: {
      // border: "2px solid rgb(45 45 45 / 70%)",
      color: "rgb(45 45 45 / 70%)",
      fontWeight: "bold",
      fontSize: "calc(var(--card-size) * .21)",
      ...langFont.tutorial,
      backgroundColor:
        !props.isTouchEnabled &&
        (props.isHandHovered || isGuideHovered || isTodaysCardleHovered)
          ? isDarkMode
            ? "var(--white)"
            : "var(--light-active-column)"
          : isDarkMode
          ? "var(--dark-container)"
          : "var(--light-container)",
      borderColor:
        !props.isTouchEnabled &&
        (props.isHandHovered || isGuideHovered || isTodaysCardleHovered)
          ? isDarkMode
            ? "var(--dark-container)"
            : "var(--light-container)"
          : isDarkMode
          ? "var(--white)"
          : "var(--light-active-column)",
      color:
        !props.isTouchEnabled &&
        (props.isHandHovered || isGuideHovered || isTodaysCardleHovered)
          ? isDarkMode
            ? "var(--dark-container)"
            : "var(--light-container)"
          : isDarkMode
          ? "var(--white)"
          : "var(--font-color-tuto)",
      padding: "12px",
      marginTop: "15px",
      //borderRadius: "calc(var(--card-gap) * 2.5)",
      boxShadow: isDarkMode
        ? "0px 1px 2.5px var(--button-shadow)"
        : "1px 2px 4px",
      whiteSpace: "nowrap",
    },
    nextButton: {
      // border: "2px solid rgb(45 45 45 / 70%)",
      color: "rgb(45 45 45 / 70%)",
      fontWeight: "bold",
      fontSize: "calc(var(--card-size) * .21)",
      ...langFont.tutorial,
      backgroundColor:
        !props.isTouchEnabled &&
        (props.isHandHovered || isGuideHovered || isNextHovered)
          ? isDarkMode
            ? "var(--white)"
            : "var(--light-active-column)"
          : isDarkMode
          ? "var(--dark-container)"
          : "var(--light-container)",
      borderColor:
        !props.isTouchEnabled &&
        (props.isHandHovered || isGuideHovered || isNextHovered)
          ? isDarkMode
            ? "var(--dark-container)"
            : "var(--light-container)"
          : isDarkMode
          ? "var(--white)"
          : "var(--light-active-column)",
      color:
        !props.isTouchEnabled &&
        (props.isHandHovered || isGuideHovered || isNextHovered)
          ? isDarkMode
            ? "var(--dark-container)"
            : "var(--light-container)"
          : isDarkMode
          ? "var(--white)"
          : "var(--font-color-tuto)",
      padding: "12px",
      marginTop: "15px",
      //borderRadius: "calc(var(--card-gap) * 2.5)",
      boxShadow: isDarkMode
        ? "0px 1px 2.5px var(--button-shadow)"
        : "1px 2px 4px",
      whiteSpace: "nowrap",
    },
    prevButton: {
      // border: "2px solid rgb(45 45 45 / 70%)",
      color: "rgb(45 45 45 / 70%)",
      fontWeight: "bold",
      fontSize: "calc(var(--card-size) * .21)",
      ...langFont.tutorial,
      backgroundColor:
        !props.isTouchEnabled &&
        (props.isHandHovered || isGuideHovered || isPrevHovered)
          ? isDarkMode
            ? "var(--white)"
            : "var(--light-active-column)"
          : isDarkMode
          ? "var(--dark-container)"
          : "var(--light-container)",
      borderColor:
        !props.isTouchEnabled &&
        (props.isHandHovered || isGuideHovered || isPrevHovered)
          ? isDarkMode
            ? "var(--dark-container)"
            : "var(--light-container)"
          : isDarkMode
          ? "var(--white)"
          : "var(--light-active-column)",
      color:
        !props.isTouchEnabled &&
        (props.isHandHovered || isGuideHovered || isPrevHovered)
          ? isDarkMode
            ? "var(--dark-container)"
            : "var(--light-container)"
          : isDarkMode
          ? "var(--white)"
          : "var(--font-color-tuto)",
      padding: "12px",
      marginTop: "15px",
      //borderRadius: "calc(var(--card-gap) * 2.5)",
      boxShadow: isDarkMode
        ? "0px 1px 2.5px var(--button-shadow)"
        : "1px 2px 4px",
      whiteSpace: "nowrap",
    },
    containerWidth: {
      width:
        "calc(var(--first-card) * (2 + 3) + var(--card-gap) * (3 * 5 - 3))",
    },
  };

  const buttonsTutorial = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height:
      deviceSize < 550
        ? "calc(var(--card-gap) * 2)"
        : "calc(var(--card-gap) * 2.5)",
    width:
      deviceSize < 550
        ? "calc(var(--card-size) * 1.4)"
        : "calc(var(--card-size) * 2.1)",
  };

  const fontClass = {
    color: isDarkMode ? "var(--white)" : "var(--font-color-tuto)",
    lineHeight: helpIntroFlag
      ? deviceSize < 550
        ? "1.9"
        : "1.6"
      : isGuideNeed
      ? deviceSize < 550
        ? "2.1"
        : "2.25"
      : "",
    fontFamily: "satoshi-light",
    ...langFont.tutorial,
  };

  hands.map((hand, i) => {
    //decide whether it is the played hand or unplayed hand for currentTrick
    let handStatus = currentTrick.find((card) => card.player === i)
      ? "played"
      : "unplayed";
    handStatus === "played"
      ? (playedHandsCount += 1)
      : (playedHandsCount = playedHandsCount);

    // dispatch(SET_HANDS_COUNT(playedHandsCount))
    hand.map((card) => {
      if (card.isPlayed) {
        playedCardCount += 1;
      }

      if (isTrump && card.suit === "T") {
        //TODO:(isTrump && card.suit === trump)
        havingTrump = true;
      }
      if (
        handStatus === "unplayed" &&
        !canMatchTheColour &&
        state.game.followSuit === card.suit &&
        !card.isPlayed
      ) {
        canMatchTheColour = true;
      }
    });
  });

  if (playedHandsCount === 3) {
    winner = tricks.getWinningCard();
  }

  dispatch(UPDATE_WALKTHROUGH_STEP(playedCardCount));

  const winnerStyledCard = () => {
    const style = {
      height: deviceSize < 550 ? "calc(var(--card-size) * .42)" : "1.2rem",
      width: deviceSize < 550 ? "calc(var(--card-size) * .42)" : "1.2rem",
      backgroundColor: followColor,
      color: "white",
      verticalAlign: "middle",
      borderRadius: "0.15rem",
      fontSize: "0.8rem",
    };
    return (
      <div
        style={{
          ...style,
          display: "inline-flex",
          justifyContent: "center",
          alignItems: "center",
          fontFamily: "satoshi-bold",
        }}
      >
        {state.game.lose || (state.game.currentTrick !== 1 && !winner)
          ? lastWinner
            ? lastWinner.card.rank
            : ""
          : winner
          ? winner.card.rank
          : "-"}
      </div>
    );
  };
  const emptyCard = (bgColor, rank) => {
    return (
      <div
        className="empty-card"
        style={{
          backgroundColor: bgColor,
          color: !rank ? bgColor : "white",
          height: deviceSize < 550 ? "calc(var(--card-size) * .42)" : "1.2rem",
          width: deviceSize < 550 ? "calc(var(--card-size) * .42)" : "1.2rem",
          verticalAlign: "initial",
          fontFamily: "satoshi-bold",
          borderRadius: "0.15rem",
          fontSize: deviceSize < 550 ? "inherit" : ".8rem",
        }}
      >
        {rank ? rank : 1}
      </div>
    );
  };
  const trumpCard = (winner) => {
    return (
      <span
        className="empty-trump-outer"
        style={{
          backgroundColor: ourTrumpColor,
          height:
            deviceSize < 550
              ? "calc(var(--card-size) * .32)"
              : "calc(var(--card-size) * .4)",
          width:
            deviceSize < 550
              ? "calc(var(--card-size) * .32)"
              : "calc(var(--card-size) * .4)",
          verticalAlign: "middle",
        }}
      >
        <span
          className="empty-trump-inner"
          style={{
            backgroundColor: ourTrumpColor,
            color: winner ? "var(--white)" : ourTrumpColor,
            border: "1px solid",
            fontSize: "calc(var(--card-gap) * 1.2)",
            borderColor: "var(--white)",
            height:
              deviceSize < 550
                ? "calc(var(--card-size) * .25)"
                : "calc(var(--card-size) * .3)",
            width:
              deviceSize < 550
                ? "calc(var(--card-size) * .25)"
                : "calc(var(--card-size) * .3)",
          }}
        >
          {winner !== undefined ? `${winner.card.rank}` : `5`}
        </span>
      </span>
    );
  };
  const aim = () => {
    let aim = []; //final string to return as aim
    aim.push(<span>{`In this tutorial, you have to fill `}</span>);
    let nonZeroTargets = []; //to know the non-zero targets
    state.game.target.forEach((target, ind) => {
      if (+target !== 0) {
        let nonZeroTarget = {
          target: +target,
          player: ind,
        };
        nonZeroTargets.push(nonZeroTarget); //finally made the non-zero targets
      }
    });
    for (let i = 0; i < nonZeroTargets.length; i++) {
      aim.push(
        <span>{`${nonZeroTargets[i].target} ${
          +nonZeroTargets[i].target === 1 ? ` ball ` : ` balls `
        } in the ${columnName.get(nonZeroTargets[i].player)} container`}</span>
      );
      if (i === nonZeroTargets.length - 2) {
        aim.push(<span>{` and `}</span>);
      } else if (i === nonZeroTargets.length - 1) {
        aim.push(<span>{`. `}</span>);
      } else {
        aim.push(<span>{`, `}</span>);
      }
    }
    return aim;
  };
  const warning = () => {
    let warning = [];
    warning.push(
      <span>{` Notice that the ball is trying to drop in the `}</span>
    );
    if (winner && +state.game.target[winner.player] - 1 === -1) {
      warning.push(
        <span>
          {`${columnName.get(
            winner.player
          )} container where there is no empty circle. You are on the `}
          <span style={{ color: "var(--red)" }}>{` wrong `}</span>
          <span>{`track.`}</span>
        </span>
      );
    } else {
      warning.push(
        <span>
          {`empty circle of the ${columnName.get(
            winner ? winner.player : <></>
          )} container. You are on the `}
          <span style={{ color: "var(--green)" }}>{` right `}</span>
          <span>{`track.`}</span>
        </span>
      );
    }
    return warning;
  };

  let prevIndex =
    state.game.gameIndex === 0 ? tutorialProblems.length : state.game.gameIndex;
  let nextIndex =
    state.game.gameIndex === tutorialProblems.length - 1
      ? 1
      : state.game.gameIndex + 2;
  // console.log(
  //   "check || curr ind",
  //   state.game.gameIndex + 1,
  //   "prev",
  //   prevIndex,
  //   "\nnext",
  //   nextIndex
  // );

  let attemptString = state.game.attempts > 1 ? "attempts" : "attempt";

  let lastPlayed = state.game.tricks[state.game.currentTrick - 1].find(
    (card) => card.turnOrder === 3 - emptyCardsIndex.length
  );

  // for trick_1 text messages for tutorial
  const trickOneTextOne =
    columnName.get(emptyCardsIndex[0]) === "left" &&
    columnName.get(emptyCardsIndex[1]) === "right"
      ? t("walkThroughMessages.trickOne.three").split(" ")
      : columnName.get(emptyCardsIndex[0]) === "middle" &&
        columnName.get(emptyCardsIndex[1]) === "right"
      ? t("walkThroughMessages.trickOne.four").split(" ")
      : t("walkThroughMessages.trickOne.five").split(" ");
  const trickOneTextTwo =
    columnName.get(emptyCardsIndex[0]) === "left"
      ? t("walkThroughMessages.trickOne.six").split(" ")
      : columnName.get(emptyCardsIndex[0]) === "middle"
      ? t("walkThroughMessages.trickOne.seven").split(" ")
      : t("walkThroughMessages.trickOne.eight").split(" ");
  const trickOneTextThree = winner
    ? winner.player === 1
      ? t("walkThroughMessages.trickOne.ten").split(" ")
      : t("walkThroughMessages.trickOne.eleven").split(" ")
    : columnName.get(emptyCardsIndex[0] === "middle")
    ? t("walkThroughMessages.trickOne.ten").split(" ")
    : columnName.get(emptyCardsIndex[0] === "right")
    ? t("walkThroughMessages.trickOne.eleven").split(" ")
    : "";

  const trickOneTextFour = t("walkThroughMessages.trickOne.twelve").split(" "); // for 'Click Enter' text

  // for trick_2 text messages for tutorial
  const trickTwoTextOne = lastWinner
    ? columnName.get(lastWinner.player) === "left"
      ? t("walkThroughMessages.trickTwo.one-left").split(" ")
      : columnName.get(lastWinner.player) === "middle"
      ? t("walkThroughMessages.trickTwo.one-middle").split(" ")
      : t("walkThroughMessages.trickTwo.one-right").split(" ")
    : [];
  const trickTwoTextTwo = lastWinner
    ? columnName.get(lastWinner.player) === "left"
      ? t("walkThroughMessages.trickTwo.two").split(" ")
      : columnName.get(lastWinner.player) === "middle"
      ? t("walkThroughMessages.trickTwo.three").split(" ")
      : t("walkThroughMessages.trickTwo.four").split(" ")
    : [];
  const trickTwoTextThree =
    columnName.get(emptyCardsIndex[0]) === "left" &&
    columnName.get(emptyCardsIndex[1]) === "right"
      ? t("walkThroughMessages.trickTwo.seven").split(" ")
      : columnName.get(emptyCardsIndex[0]) === "middle" &&
        columnName.get(emptyCardsIndex[1]) === "right"
      ? t("walkThroughMessages.trickTwo.six").split(" ")
      : t("walkThroughMessages.trickTwo.five").split(" ");
  const trickTwoTextFour =
    columnName.get(emptyCardsIndex[0]) === "left"
      ? t("walkThroughMessages.trickTwo.eight").split(" ")
      : columnName.get(emptyCardsIndex[0]) === "middle"
      ? t("walkThroughMessages.trickTwo.nine").split(" ")
      : t("walkThroughMessages.trickTwo.ten").split(" ");
  const trickTwoTextFive = winner
    ? winner.player === 1
      ? t("walkThroughMessages.trickTwo.twelve").split(" ")
      : t("walkThroughMessages.trickTwo.thirteen").split(" ")
    : columnName.get(emptyCardsIndex[0] === "middle")
    ? t("walkThroughMessages.trickTwo.twelve").split(" ")
    : columnName.get(emptyCardsIndex[0] === "right")
    ? t("walkThroughMessages.trickTwo.thirteen").split(" ")
    : "";

  // for trick three messages for tutorial
  const trickThreeTextOne = lastWinner
    ? columnName.get(lastWinner.player) === "left"
      ? t("walkThroughMessages.trickThree.one-left").split(" ")
      : columnName.get(lastWinner.player) === "middle"
      ? t("walkThroughMessages.trickThree.one-middle").split(" ")
      : t("walkThroughMessages.trickThree.one-right").split(" ")
    : [];
  const trickThreeTextTwo = lastWinner
    ? columnName.get(lastWinner.player) === "left"
      ? lastWinner.player === 0
        ? t("walkThroughMessages.trickThree.two").split(" ")
        : t("walkThroughMessages.trickThree.three").split(" ")
      : columnName.get(lastWinner.player) === "middle"
      ? lastWinner.player === 0
        ? t("walkThroughMessages.trickThree.four").split(" ")
        : t("walkThroughMessages.trickThree.five").split(" ")
      : lastWinner.player === 0
      ? t("walkThroughMessages.trickThree.six").split(" ")
      : t("walkThroughMessages.trickThree.seven").split(" ")
    : [];

  const trickThreeTextThree = lastPlayed
    ? columnName.get(lastPlayed.player) === "left"
      ? t("walkThroughMessages.trickThree.nine").split(" ")
      : columnName.get(lastPlayed.player) === "middle"
      ? t("walkThroughMessages.trickThree.ten").split(" ")
      : t("walkThroughMessages.trickThree.eleven").split(" ")
    : null;
  const trickThreeTextFour =
    columnName.get(emptyCardsIndex[0]) === "left"
      ? t("walkThroughMessages.trickThree.twelve").split(" ")
      : columnName.get(emptyCardsIndex[0]) === "middle"
      ? t("walkThroughMessages.trickThree.thirteen").split(" ")
      : t("walkThroughMessages.trickThree.fourteen").split(" ");
  const trickThreeTextFive =
    columnName.get(emptyCardsIndex[0]) === "left"
      ? t("walkThroughMessages.trickThree.fifteen").split(" ")
      : columnName.get(emptyCardsIndex[0]) === "middle"
      ? t("walkThroughMessages.trickThree.sixteen").split(" ")
      : t("walkThroughMessages.trickThree.seventeen").split(" ");
  const trickThreeTextSix = winner
    ? winner.player === 1
      ? t("walkThroughMessages.trickThree.nineteen").split(" ")
      : t("walkThroughMessages.trickThree.twenty").split(" ")
    : columnName.get(emptyCardsIndex[0] === "middle")
    ? t("walkThroughMessages.trickThree.nineteen").split(" ")
    : columnName.get(emptyCardsIndex[0] === "right")
    ? t("walkThroughMessages.trickThree.twenty").split(" ")
    : "";

  // for text with green color
  const matchTheGreenColorText = () => {
    return (
      <span style={fontClass}>
        {t("walkThroughMessages.trickOne.two")
          .split(" ")
          .map((text) => {
            if (text.startsWith("<1>") && text.endsWith("</1>")) {
              // for green color text
              return (
                <span
                  style={{ ...fontClass, color: "var(--green)" }}
                >{` ${text.slice(3, -4)} `}</span>
              );
            }

            // for all other normal texts
            return <span style={fontClass}>{` ${text} `}</span>;
          })}
      </span>
    );
  };

  // if one card is played
  const oneCardPlayed = (textArray) => {
    // manipulating the text from translation file
    return textArray.map((text) => {
      if (text === "<card/>") {
        // for green card
        return <span> {emptyCard(state.game.suitColor.get("G"))}</span>;
      }

      if (text.startsWith("<1>") && text.endsWith("</1>")) {
        // for green text
        return (
          <span style={{ ...fontClass, color: "var(--green)" }}>{` ${text.slice(
            3,
            -4
          )} `}</span>
        );
      }

      if (text.startsWith("<2>") && text.endsWith("</2>")) {
        // for right,left or middle column highlighting
        return renderTextWithBorder(text);
      }
      // for other texts
      return <span style={fontClass}>{` ${text} `}</span>;
    });
  };

  // if two card is played
  const twoCardPlayed = (trickArray) => {
    // manipulating the text from translation file
    return trickArray.map((text) => {
      if (text === "<card/>") {
        // for green card
        return <span> {emptyCard(state.game.suitColor.get("G"))}</span>;
      }

      if (text.startsWith("<1>") && text.endsWith("</1>")) {
        // for green text
        return (
          <span style={{ ...fontClass, color: "var(--green)" }}>{` ${text.slice(
            3,
            -4
          )} `}</span>
        );
      }

      if (text.startsWith("<2>") && text.endsWith("</2>")) {
        // for right,left or middle column highlighting
        return renderTextWithBorder(text);
      }
      // for other texts
      return <span style={fontClass}>{` ${text} `}</span>;
    });
  };

  // for rendering played cards text
  const renderPlayedCardsText = () => {
    if (playedCardCount === 3 && currentTrick) {
      return (
        <>
          <span>
            {emptyCard(
              state.game.suitColor.get(currentTrick[0].card.suit),
              currentTrick[0].card.rank
            )}
            {" - "}
          </span>
          <span>
            {emptyCard(
              state.game.suitColor.get(currentTrick[1].card.suit),
              currentTrick[1].card.rank
            )}
            {" - "}
          </span>
          <span>
            {emptyCard(
              state.game.suitColor.get(currentTrick[2].card.suit),
              currentTrick[2].card.rank
            )}
          </span>
        </>
      );
    } else {
      return null;
    }
  };

  const renderCardWinningTextOne = (textArray) => {
    if (textArray) {
      // for manipulating the text form the translation
      return textArray.map((text) => {
        // for the green empty card
        if (text === "<card/>") {
          return (
            <span>
              {playedCardCount === 3 && winner
                ? emptyCard(
                    state.game.suitColor.get(winner.card.suit),
                    winner.card.rank
                  )
                : ""}
            </span>
          );
        }

        if (text.startsWith("<i>") && text.endsWith("</i>")) {
          // for italic texts
          return italicText(text.slice(3, -4));
        }

        if (text.startsWith("<2>") && text.endsWith("</2>")) {
          // for right,left or middle column highlighting
          return renderTextWithBorder(text);
        }

        // for all other texts
        return <span style={fontClass}>{` ${text} `}</span>;
      });
    } else {
      return null;
    }
  };

  const renderEnterButtonText = () => {
    // for manipulating the text from the translation
    return trickOneTextFour.map((text) => {
      if (text === "<button/>") {
        // for enter button
        return (
          <span
            key={2}
            style={{
              ...fontClass,
              border: "1px solid",
              display: "inline-flex",
              height:
                deviceSize < 550 ? "calc(var(--card-size) * .32)" : "1.2rem",
              width:
                deviceSize < 550 ? "calc(var(--card-size) * .32)" : "1.2rem",
              alignItems: "center",
              justifyContent: "center",
              verticalAlign: "middle",
              borderRadius: "0.2rem",
            }}
          >
            {" "}
            <FontAwesomeIcon icon={faCheck} fontSize="x-small" />
          </span>
        );
      }

      // for all other texts
      return <span style={fontClass}>{" " + text + " "}</span>;
    });
  };

  const renderTextWithBorder = (text) => {
    return (
      <span // for left,right and middle column highlighting
        className="no-breaking"
        style={{
          ...fontClass,
          padding: "0.1em 0.5em",
          borderRadius: "0.5em",
          border: "3px solid",
          borderColor: isDarkMode
            ? "var(--white)"
            : "var(--light-active-column)",
        }}
      >
        {text.includes("-")
          ? text.slice(3, -4).split("-").join(" ")
          : text.slice(3, -4)}
      </span>
    );
  };

  const renderWinningColumnTextOne = (textArray) => {
    if (textArray) {
      return textArray.map((text) => {
        if (text === "<card/>") {
          // for winning card
          return winnerStyledCard();
        }

        if (text.startsWith("<i>") && text.endsWith("</i>")) {
          // for italic texts
          return italicText(text.slice(3, -4));
        }

        if (text.startsWith("<2>") && text.endsWith("</2>")) {
          // for text with border
          return renderTextWithBorder(text);
        }

        // for all other texts
        return <span style={fontClass}>{" " + text + " "}</span>;
      });
    } else {
      return null;
    }
  };

  const renderWinningColumnTextTwo = (textArray, card) => {
    if (textArray) {
      return textArray.map((text) => {
        if (text.startsWith("<2>") && text.endsWith("</2>")) {
          // for text with border
          return renderTextWithBorder(text);
        }

        if (text === "<card/>") {
          return <span style={fontClass}>{card}</span>;
        }

        if (text.startsWith("<i>") && text.endsWith("</i>")) {
          // for italic texts
          return italicText(text.slice(3, -4));
        }

        // for all other texts
        return <span style={fontClass}>{" " + text + " "}</span>;
      });
    } else {
      return null;
    }
  };

  const italicText = (text) => (
    <i style={{ fontFamily: "satoshi-light", paddingRight: ".1rem" }}>
      {" "}
      {` ${text} `}{" "}
    </i>
  );

  const italic_trick = (
    <span style={{ fontFamily: "satoshi-italic" }}> {` trick `} </span>
  );

  const intro = [
    <div
      style={{
        ...fontClass,
        width: deviceSize < 550 ? "calc(var(--card-size) * 6.5)" : "auto",
      }}
    >
      <p style={{ ...fontClass, marginBlockStart: "0" }}>
        {t("walkThroughMessages.intro.one")}
      </p>
      <div
        className="intro-target"
        style={{
          backgroundColor: isDarkMode
            ? "var(--dark-body)"
            : "var(--light-body)",
          opacity: isGuideHovered ? "0.5" : "1",
        }}
      >
        <Target forGuide={true} />
      </div>
      <p style={fontClass}> {t("walkThroughMessages.intro.two")}</p>
      <div
        className="final-target"
        style={{
          backgroundColor: isDarkMode
            ? "var(--dark-body)"
            : "var(--light-body)",
          opacity: isGuideHovered ? "0.5" : "1",
        }}
      >
        <Target final={true} />
      </div>
      <div
        className="three-buttons"
        style={{
          width: deviceSize < 550 ? "auto" : "calc(var(--card-size) * 7)",
        }}
      >
        <span
          className="three-buttons"
          onMouseEnter={() => {
            setNextHovered(true);
            setGuideHovered(false);
          }}
          onMouseLeave={() => {
            setNextHovered(false);
            setGuideHovered(true);
          }}
        >
          <button
            className="buttons"
            style={{
              buttonsTutorial,
              ...style.nextButton,
              width:
                deviceSize < 550
                  ? "calc(var(--card-size) * 2.1)"
                  : "calc(var(--card-size) * 1.9)",
            }}
            onClick={() => {
              props.setShowTutoModal(true);
              props.buttonClick("tutorial", "quick_look");
            }}
          >
            <span
              style={{
                fontFamily: "satoshi-light",
                fontSize: isGuideNeed
                  ? deviceSize < 550
                    ? "calc(var(--card-size) * .2)"
                    : "calc(var(--card-size) * .22)"
                  : "",
              }}
            >
              {t("labels.rules")}
            </span>
          </button>
        </span>
        <span
          className="three-buttons"
          onMouseEnter={() => {
            setGotItHovered(true);
            setGuideHovered(false);
          }}
          onMouseLeave={() => {
            setGotItHovered(false);
            setGuideHovered(true);
          }}
        >
          <button
            className="buttons"
            style={{
              buttonsTutorial,
              ...style.gotItButton,
              width:
                deviceSize < 550
                  ? "calc(var(--card-size) * 2.1)"
                  : "calc(var(--card-size) * 1.9)",
            }}
            onClick={() => {
              dispatch(SET_INTRO_FLAG(false));
              dispatch(GAME_UNLOCK());
              tutorialLogFunction("start", "start");
              props.buttonClick("tutorial", "start");
            }}
          >
            <span
              style={{
                fontFamily: "satoshi-light",
                fontSize: isGuideNeed
                  ? deviceSize < 550
                    ? "calc(var(--card-size) * .2)"
                    : "calc(var(--card-size) * .22)"
                  : "",
              }}
            >
              {t("labels.startTutorial")}
            </span>
          </button>
        </span>
        <span
          className="three-buttons"
          style={
            {
              // marginRight: deviceSize < 550 ? '0' : "0px"
            }
          }
          onMouseEnter={() => {
            setPrevHovered(true);
            setGuideHovered(false);
          }}
          onMouseLeave={() => {
            setPrevHovered(false);
            setGuideHovered(true);
          }}
        >
          <button
            className="buttons"
            style={{
              buttonsTutorial,
              ...style.prevButton,
              opacity: "0.5",
              pointerEvents: "none",
              width:
                deviceSize < 550
                  ? "calc(var(--card-size) * 2.1)"
                  : "calc(var(--card-size) * 1.9)",
            }}
            // onClick={() => {
            //   dispatch(SET_INTRO_FLAG(false));
            //   dispatch(GAME_UNLOCK());
            // }}
          >
            <span
              style={{
                fontFamily: "satoshi-light",
                fontSize: isGuideNeed
                  ? deviceSize < 550
                    ? "calc(var(--card-size) * .2)"
                    : "calc(var(--card-size) * .22)"
                  : "",
              }}
            >
              {t("labels.watchVideo")}
            </span>
          </button>
        </span>
      </div>
    </div>,
  ];

  const trick_1 = [
    [
      <span style={fontClass}>
        {oneCardPlayed(t("walkThroughMessages.trickOne.one").split(" "))}
      </span>,
    ],
    [
      <span style={{ ...fontClass }}>
        {matchTheGreenColorText()}
        {oneCardPlayed(trickOneTextOne)}
      </span>,
    ],
    [
      <span style={{ ...fontClass }}>
        {matchTheGreenColorText()}
        {twoCardPlayed(trickOneTextTwo)}
      </span>,
    ],
    // First row is complete. - G7 triggers the first ball that is going to fill the empty pocket above it.
    [
      <span style={{ ...fontClass, lineHeight: "2.25" }}>
        {t("walkThroughMessages.trickOne.nine")
          .split(" ")
          .map((text) => {
            if (text.startsWith("<i>") && text.endsWith("</i>")) {
              // for italic texts
              return italicText(text.slice(3, -4));
            }

            if (text === "<cards/>") {
              // for rendering playing cards
              return renderPlayedCardsText();
            }

            return (
              <span style={{ ...fontClass, lineHeight: "2.25" }}>
                {" " + text + " "}
              </span>
            );
          })}
        <div style={{ ...fontClass, lineHeight: "2.25" }}>
          {renderCardWinningTextOne(trickOneTextThree)}
          {renderEnterButtonText()}
        </div>
      </span>,
    ],
  ];

  const trick_2 = [
    [
      <div style={{ ...fontClass }}>
        <span style={fontClass}>
          {" "}
          {/*  after completing the first trick  */}
          {renderWinningColumnTextOne(trickTwoTextOne)}
          {renderWinningColumnTextTwo(
            trickTwoTextTwo,
            emptyCard(state.game.suitColor.get("G"))
          )}
        </span>
      </div>,
    ],
    [
      <span style={{ ...fontClass }}>
        {oneCardPlayed(trickTwoTextThree)} {/* after playing one card */}
      </span>,
    ],
    [
      <span style={{ ...fontClass }}>
        {twoCardPlayed(trickTwoTextFour)} {/* after playing two cards */}
      </span>,
    ],
    [
      <span style={{ ...fontClass, lineHeight: "2.25" }}>
        <span style={{ ...fontClass, lineHeight: "2.25" }}>
          {t("walkThroughMessages.trickTwo.eleven")
            .split(" ")
            .map((text) => {
              if (text.startsWith("<i>") && text.endsWith("</i>")) {
                // for italic texts
                return italicText(text.slice(3, -4));
              }

              if (text === "<cards/>") {
                // for rendering playing cards
                return renderPlayedCardsText();
              }

              return (
                <span style={{ ...fontClass, lineHeight: "2.25" }}>
                  {" " + text + " "}
                </span>
              );
            })}
          <div style={fontClass}>
            {renderCardWinningTextOne(trickTwoTextFive)}
            {renderEnterButtonText()}
          </div>
        </span>
      </span>,
    ],
  ];

  const trick_3 = [
    [
      <div style={{ ...fontClass }}>
        <span style={fontClass}>
          {renderWinningColumnTextOne(trickThreeTextOne)}
          {renderWinningColumnTextTwo(
            trickThreeTextTwo,
            emptyCard(state.game.suitColor.get("R"))
          )}
        </span>
      </div>,
    ],
    [
      <span style={{ ...fontClass }}>
        {t("walkThroughMessages.trickThree.eight")
          .split(" ")
          .map((text) => {
            if (text.startsWith("<1>") && text.endsWith("</1>")) {
              // for red text
              return (
                <span
                  style={{ ...fontClass, color: "var(--red)" }}
                >{` ${text.slice(3, -4)} `}</span>
              );
            }
            // for all other texts
            return <span style={fontClass}>{` ${text} `}</span>;
          })}
      </span>,
    ],
    /*  Since you couldn’t match red color for red color from the left column - you played Y9 
Now match red for red from the x column */
    [
      lastPlayed && lastPlayed.player === 0 ? (
        <div style={{ ...fontClass }}>
          <span style={{ ...fontClass }}>
            {
              // for string manipulation translation
              trickThreeTextThree
                ? trickThreeTextThree.map((text) => {
                    if (text === "<card/>") {
                      // for the card
                      return (
                        <span>
                          {" "}
                          {emptyCard(
                            state.game.suitColor.get(lastPlayed.card.suit),
                            lastPlayed.card.rank
                          )}
                        </span>
                      );
                    }

                    if (text.startsWith("<1>") && text.endsWith("</1>")) {
                      // for colored texts
                      return (
                        <span
                          style={{ ...fontClass, color: "var(--red)" }}
                        >{` ${text.slice(3, -4)} `}</span>
                      );
                    }

                    if (text.startsWith("<2>") && text.endsWith("</2>")) {
                      // for bordered text
                      return renderTextWithBorder(text);
                    }

                    if (text === ".") {
                      return <span style={fontClass}>{` ${text}`}</span>;
                    }
                    return <span style={fontClass}>{` ${text} `}</span>;
                  })
                : null
            }
            {/* for extra spacing between . and Now text */}
            <span
              style={{
                ...fontClass,
                color: !isDarkMode
                  ? "var(--light-container)"
                  : "var(--dark-container)",
              }}
            >{`1`}</span>

            {
              // for string manipulation
              trickThreeTextFour
                ? trickThreeTextFour.map((text) => {
                    if (text.startsWith("<1>") && text.endsWith("</1>")) {
                      // for colored texts
                      return (
                        <span
                          style={{ ...fontClass, color: "var(--red)" }}
                        >{` ${text.slice(3, -4)} `}</span>
                      );
                    }

                    if (text.startsWith("<2>") && text.endsWith("</2>")) {
                      // for bordered text
                      return renderTextWithBorder(text);
                    }

                    return <span style={fontClass}>{` ${text} `}</span>;
                  })
                : null
            }
          </span>
        </div>
      ) : (
        /*  Because you cannot match red color for red color from the left column - click any card */
        <div>
          <span style={{ ...fontClass }}>
            {trickThreeTextFive.map((text) => {
              if (text.startsWith("<1>") && text.endsWith("</1>")) {
                // for colored texts
                return (
                  <span
                    style={{ ...fontClass, color: "var(--red)" }}
                  >{` ${text.slice(3, -4)} `}</span>
                );
              }

              if (text.startsWith("<2>") && text.endsWith("</2>")) {
                // for bordered text
                return renderTextWithBorder(text);
              }

              return <span style={fontClass}>{` ${text} `}</span>;
            })}
          </span>
        </div>
      ),
    ],
    [
      <span style={{ ...fontClass, lineHeight: "2.25" }}>
        <span style={{ ...fontClass, lineHeight: "2.25" }}>
          {t("walkThroughMessages.trickThree.eighteen")
            .split(" ")
            .map((text) => {
              if (text.startsWith("<i>") && text.endsWith("</i>")) {
                // for italic texts
                return italicText(text.slice(3, -4));
              }

              if (text === "<cards/>") {
                // for rendering playing cards
                return renderPlayedCardsText();
              }

              return (
                <span
                  style={{ ...fontClass, lineHeight: "2.25" }}
                >{` ${text} `}</span>
              );
            })}
          <div style={fontClass}>
            {renderCardWinningTextOne(trickThreeTextSix)}
            {renderEnterButtonText()}
          </div>
        </span>
      </span>,
    ],
  ];

  let otherSuit =
    lastTrick && lastTrick.filter((card) => card.card.suit !== "R");

  const trick_4 = [
    [
      <div style={{ ...fontClass }}>
        <span style={fontClass}>
          {lastWinner
            ? lastWinner.player === 0
              ? renderWinningColumnTextOne(
                  t("walkThroughMessages.trickFour.one-left").split(" ")
                )
              : lastWinner.player === 1
              ? renderWinningColumnTextOne(
                  t("walkThroughMessages.trickFour.one-middle").split(" ")
                )
              : renderWinningColumnTextOne(
                  t("walkThroughMessages.trickFour.one-right").split(" ")
                )
            : ""}
          {t("walkThroughMessages.trickFour.two")
            .split(" ")
            .map((text) => {
              if (text.startsWith("<1>") && text.endsWith("</1>")) {
                // for colored texts
                return (
                  <span
                    style={{ ...fontClass, color: "var(--red)" }}
                  >{` ${text.slice(3, -4)} `}</span>
                );
              }

              if (text === "<card/>") {
                // for other cards
                return (
                  <span>
                    {otherSuit &&
                      otherSuit.length === 1 &&
                      emptyCard(
                        state.game.suitColor.get(otherSuit[0].card.suit),
                        otherSuit[0].card.rank
                      )}
                  </span>
                );
              }

              return (
                <span
                  style={{ ...fontClass, lineHeight: "2.25" }}
                >{` ${text} `}</span>
              );
            })}
          {renderWinningColumnTextOne(
            t("walkThroughMessages.trickFour.three").split(" ")
          )}
        </span>
      </div>,
    ],
    [
      <span style={{ ...fontClass }}>
        {renderWinningColumnTextOne(
          t("walkThroughMessages.trickFour.four").split(" ")
        )}
      </span>,
    ],
    [
      <span style={{ ...fontClass }}>
        {t("walkThroughMessages.trickFour.five")}
      </span>,
    ],
    [
      <span style={{ ...fontClass, lineHeight: "2.25" }}>
        <span style={{ ...fontClass, lineHeight: "2.25" }}>
          {t("walkThroughMessages.trickFour.six")
            .split(" ")
            .map((text) => {
              if (text.startsWith("<i>") && text.endsWith("</i>")) {
                // for italic texts
                return italicText(text.slice(3, -4));
              }

              if (text === "<cards/>") {
                // for rendering playing cards
                return renderPlayedCardsText();
              }

              return (
                <span style={{ ...fontClass, lineHeight: "2.25" }}>
                  {" " + text + " "}
                </span>
              );
            })}
          <div style={fontClass}>
            {renderCardWinningTextOne(
              t("walkThroughMessages.trickFour.seven").split(" ")
            )}
            {renderEnterButtonText()}
          </div>
        </span>
      </span>,
    ],
  ];

  const won = [
    <div>
      <div style={{ ...fontClass }}>
        <span style={fontClass}>
          {renderWinningColumnTextOne(
            t("walkThroughMessages.won.one").split(" ")
          )}
          {t("walkThroughMessages.won.two")
            .split(" ")
            .map((text, index, textArray) => {
              if (text.startsWith("<1>") && text.endsWith("</1>")) {
                // for colored texts
                return (
                  <span
                    style={{ ...fontClass, color: "var(--red)" }}
                  >{` ${text.slice(3, -4)} `}</span>
                );
              }

              if (text === "<card/>") {
                // for other cards
                if (otherSuit && otherSuit.length === 2) {
                  if (
                    textArray
                      .slice(index + 1)
                      .filter((text) => text === "<card/>").length > 0
                  ) {
                    return (
                      <span>
                        {emptyCard(
                          state.game.suitColor.get(otherSuit[0].card.suit),
                          otherSuit[0].card.rank
                        )}
                      </span>
                    );
                  } else {
                    return (
                      <span>
                        {emptyCard(
                          state.game.suitColor.get(otherSuit[1].card.suit),
                          otherSuit[1].card.rank
                        )}
                      </span>
                    );
                  }
                } else {
                  return null;
                }
              }

              return (
                <span
                  style={{ ...fontClass, lineHeight: "2.25" }}
                >{` ${text} `}</span>
              );
            })}

          {t("walkThroughMessages.won.three")}
        </span>
      </div>

      <div className="three-buttons">
      <span
          className="three-buttons"
          style={
            {
              // marginRight: deviceSize < 550 ? '0' : "0px"
            }
          }
          onMouseEnter={() => {
            setGotItHovered(true);
            setGuideHovered(false);
          }}
          onMouseLeave={() => {
            setGotItHovered(false);
            setGuideHovered(true);
          }}
        >
          <button
            className="buttons"
            style={{
              buttonsTutorial,
              // width: "calc(var(--card-size) * 1.7)",
              width:
                deviceSize < 550
                  ? "calc(var(--card-size) * 2.1)"
                  : "calc(var(--card-size) * 2.5)",
              ...style.gotItButton,
              ...(!state.game.isAnimationComplete
                ? {
                    pointerEvents: "none",
                  }
                : {}),
            }}
            onClick={() => {
              dispatch(SET_INTRO_FLAG(false));
              dispatch(GAME_UNLOCK());
              props.handlePractice();
              tutorialLogFunction("end", "practiceCardoo");
              props.buttonClick("tutorial", "practice_cardoo");
            }}
          >
            <span style={{ ...resetFont }}>{t("tooltip.practiceCardles")}</span>
          </button>
        </span>
        <span
          className="three-buttons no-breaking"
          onMouseEnter={() => {
            setRepeatHovered(true);
            setGuideHovered(false);
          }}
          onMouseLeave={() => {
            setRepeatHovered(false);
            setGuideHovered(true);
          }}
        >
          <button
            className="cardle-btn buttons"
            style={{
              buttonsTutorial,
              width:
                deviceSize < 550
                  ? "calc(var(--card-size) * 2.1)"
                  : "calc(var(--card-size) * 2.5)",
              ...style.repeatButton,
              opacity: state.game.isAnimationComplete ? "1" : "0.5",
              pointerEvents: state.game.isAnimationComplete ? "auto" : "none",
            }}
            onClick={() => {
              if (state.game.isAnimationComplete) {
                dispatch(SET_INTRO_FLAG(true));
                props.reset();
                tutorialLogFunction("end", "gameWonAfterReset");
                props.buttonClick("tutorial", "reset");
              }
            }}
          >
            <span style={{ ...resetFont }}>{t("buttons.reset")}</span>
          </button>
        </span>
        <span
          className="three-buttons no-breaking"
          onMouseEnter={() => {
            setTodaysCardleHovered(true);
            setGuideHovered(false);
          }}
          onMouseLeave={() => {
            setTodaysCardleHovered(false);
            setGuideHovered(true);
          }}
        >
          <button
            className="cardle-btn buttons"
            style={{
              buttonsTutorial,
              width:
                deviceSize < 550
                  ? "calc(var(--card-size) * 2.1)"
                  : "calc(var(--card-size) * 2.5)",
              ...style.todaysCardleButton,
              ...(!state.game.isAnimationComplete
                ? {
                    pointerEvents: "none",
                  }
                : {}),
            }}
            onClick={() => {
              dispatch(SET_INTRO_FLAG(false));
              dispatch(GAME_UNLOCK());
              props.loadTodaysCardle();
              tutorialLogFunction("end", "todaysCardoo");
              props.buttonClick("tutorial", "todays_cardoo");
            }}
          >
            <span style={{ ...resetFont }}>{t("labels.todaysCardoo")}</span>
          </button>
        </span>
      </div>
    </div>,
  ];

  const handleGuideClose = () => {
    dispatch(DISABLE_GUIDE());
    dispatch(
      SET_ALERT_MESSAGE("You can enable the Help mode in the *help icon")
    );
  };

  const handleTutorial = () => {
    let guide;
    if (!gameWon) {
      if (state.game.currentTrick === 1) {
        guide = trick_1[step];
      } else if (state.game.currentTrick === 2) {
        guide = trick_2[step];
      } else if (state.game.currentTrick === 3) {
        guide = trick_3[step];
      } else if (state.game.currentTrick === 4) {
        guide = trick_4[step];
      }
    } else {
      guide = won;
    }
    return guide;
  };

  return (
    <>
      <div
        className="guide-container"
        style={{
          padding: "15px",
          textAlign: "center",
          borderRadius: "calc(var(--card-gap) * 2.5)",
          margin: "auto",
          backgroundColor: isDarkMode ? darkBgColor.containers : "#EAE5BE",
          position: "relative",
          // height: state.game.helpIntroFlag ? 'calc(var(--card-size) * 5)' : 'calc(var(--card-size) * 1.5)',
          // marginTop: state.game.helpIntroFlag ? "calc(var(--card-size) * 1.2)" : 0,
          color: !isDarkMode ? "rgb(45 45 45 / 70%)" : "#FBFBFB",
          lineHeight:
            helpIntroFlag || isGuideNeed
              ? deviceSize < 550
              : "1.85"
              ? "1.85"
              : "1.85",
          width: isGuideNeed
            ? deviceSize < 550
              ? helpIntroFlag
                ? "calc(var(--card-size) * 8)"
                : ""
              : "calc(var(--card-size) * 8 + var(--card-gap) * (3 * 5 - 2))"
            : "calc(var(--card-size) * 7 + var(--card-gap) * (3 * 5 - 3))",
        }}
        onMouseEnter={() => {
          setGuideHovered(true);
        }}
        onMouseLeave={() => {
          setGuideHovered(false);
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          {state.game.helpIntroFlag ? intro : handleTutorial()}
        </div>
        {state.game.helpIntroFlag || gameWon ? (
          <></>
        ) : (
          // ithi vanthu normal resetButton
          <span
            className="three-buttons no-breaking"
            onMouseEnter={() => {
              setRepeatHovered(true);
              setGuideHovered(false);
            }}
            onMouseLeave={() => {
              setRepeatHovered(false);
              setGuideHovered(true);
            }}
          >
            <button
              className="cardle-btn buttons"
              style={{
                buttonsTutorial,
                width:
                  deviceSize < 550
                    ? "calc(var(--card-size) * 2.1)"
                    : "calc(var(--card-size) * 1.9)",
                ...style.repeatButton,
                opacity: state.game.isAnimationComplete ? "1" : "0.5",
                pointerEvents: state.game.isAnimationComplete ? "auto" : "none",
              }}
              onClick={() => {
                if (state.game.isAnimationComplete) {
                  dispatch(SET_INTRO_FLAG(true));
                  props.reset();
                }
              }}
            >
              <span style={{ ...resetFont }}>{t("buttons.reset")}</span>
            </button>
          </span>
        )}
      </div>
    </>
  );
}

// switch(event){
//   case "tutorial_start":
//     logEvent(analytics, 'tutorial_start', {
//       event_id: uuidv4(),
//       event_timestamp: Date.now(),
//       user_id: userID,
//       session_id: uuidv4(),
//     });
//     break;

//   case "tutorial_end":
//     logEvent(analytics, 'tutorial_end', {
//       event_id: uuidv4(),
//       event_timestamp: Date.now(),
//       user_id: userID,
//       session_id: uuidv4(),
//     });
//     break;
// }
