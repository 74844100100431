export const gameActions = {
  CHANGE_PLAYER_COUNT: "CHANGE_PLAYER_COUNT",
  CHANGE_CARD_COUNT: "CHANGE_CARD_COUNT",
  SET_HANDS: "SET_HANDS",
  SET_TRICKS: "SET_TRICKS",
  SET_TARGET: "SET_TARGET",
  UPDATE_ATTEMPTS: "UPDATE_ATTEMPTS",
  SET_GAME_WON: "SET_GAME_WON",
  SET_GAME_LOST: "SET_GAME_LOST",
  SET_SCORE: "SET_SCORE",
  SET_CURRENT_TRICK: "SET_CURRENT_TRICK",
  SET_ALERT_MESSAGE: "SET_ALERT_MESSAGE",
  SET_IS_PLAYED: "SET_IS_PLAYED",
  UNDO_CARD: "UNDO_CARD",
  CHANGE_DARK_MODE: "CHANGE_DARK_MODE"
};
