import React from "react";
import "../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { darkBgColor } from "./Home";
import { Color } from "../constants/Colors";
import { setLocalStorageValue } from "../lib/localStorage";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { SET_ALERT_STOP } from '../redux/reducers/gameSlice'

export default function NavBar(props) {
  const state = useSelector((state) => state);
  const isDarkMode = useSelector((state) => state.game.isDarkMode);
  const gamePractice = useSelector((state) => state.game.gamePractice);
  const isGuideNeed = useSelector((state) => state.game.isGuideNeed);
  const deviceSize = useSelector((state) => state.game.deviceSize);
  // for translation
  const { t } = useTranslation()

  return (
    <>
      <nav
        style={{
          backgroundColor: isDarkMode ? darkBgColor.body : "#fbfbfb",
          boxShadow: isDarkMode
            ? "rgb(255 255 255 / 20%) 0px 1px 0px"
            : "0px 2px 0px #e9e9e9",
          height: "8vh",
        }}
      >
        <ul className="ul-left">
          <li
            className="icon1"
            title={t("tooltip.tutorial")}
            style={{
              ...(!state.game.isAnimationComplete
                ? {
                    pointerEvents: "none",
                  }
                : {}),
            }}
            onClick={() => {
              props.handleHelp();
              props.buttonClick("navbar", "tutorial");
              // dispatchEvent(SET_ALERT_STOP(true));
            }}
            opacity="0.8"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={deviceSize < 550 ? "22" : "28"}
              height={deviceSize < 550 ? "22" : "28"}
              fill={
                state.game.isGuideNeed || state.game.helpIntroFlag
                  ? "var(--green)"
                  : isDarkMode
                  ? "var(--dull-white)"
                  : "var(--dull-black)"
              }
              style={{ marginTop: "2px", marginRight: "2px", opacity: "0.8" }}
              className="bi bi-question-circle"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
            </svg>
          </li>

          <li
            className="icon1"
            title={t("tooltip.practiceCardles")}
            style={{
              marginTop: "2px",
              marginBottom: 0,
              ...(!state.game.isAnimationComplete
                ? {
                    pointerEvents: "none",
                  }
                : {}),
            }}
            onClick={() => {
              props.handlePractice()
              props.buttonClick("navbar", "practice");
              // dispatchEvent(SET_ALERT_STOP(true));
            }}
            opacity="0.8"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={deviceSize < 550 ? "22" : "28"}
              height={deviceSize < 550 ? "22" : "28"}
              fill={
                state.game.gamePractice
                  ? "var(--green)"
                  : isDarkMode
                  ? "var(--dull-white)"
                  : "var(--dull-black)"
              }
              style={{ marginTop: "2px", opacity: "0.8" }}
              className="bi bi-grid"
              viewBox="0 0 16 16"
            >
              <path d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zM2.5 2a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zM1 10.5A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3z" />
            </svg>
          </li>

          <li
            className="icon1"
            title={t("labels.hint")}
            style={{
              visibility:
                state.game.practiceSelection || isGuideNeed
                  ? "hidden"
                  : "visible",
            }}
            onClick={() => {
              props.setIsHintModalOpen(true);
              props.setHardMode(false);
              setLocalStorageValue("hardmode", false);
              props.buttonClick("navbar", "hint");
            }}
            opacity="0.8"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={deviceSize < 550 ? "20" : "26"}
              height={deviceSize < 550 ? "22" : "26"}
              fill={isDarkMode ? "var(--dull-white)" : "var(--dull-black)"}
              style={{ marginTop: "2px", marginRight: "1px", opacity: "0.8" }}
              className="bi bi-lightbulb"
              viewBox="0 0 16 16"
            >
              <path d="M2 6a6 6 0 1 1 10.174 4.31c-.203.196-.359.4-.453.619l-.762 1.769A.5.5 0 0 1 10.5 13a.5.5 0 0 1 0 1 .5.5 0 0 1 0 1l-.224.447a1 1 0 0 1-.894.553H6.618a1 1 0 0 1-.894-.553L5.5 15a.5.5 0 0 1 0-1 .5.5 0 0 1 0-1 .5.5 0 0 1-.46-.302l-.761-1.77a1.964 1.964 0 0 0-.453-.618A5.984 5.984 0 0 1 2 6zm6-5a5 5 0 0 0-3.479 8.592c.263.254.514.564.676.941L5.83 12h4.342l.632-1.467c.162-.377.413-.687.676-.941A5 5 0 0 0 8 1z" />
            </svg>
          </li>
        </ul>

        <div
          className="title"
          style={{
            fontSize: "x-large",
            fontFamily: "satoshi-light",
            opacity: "0.8",
          }}
        >
          Cardoo
        </div>

        <ul style={{ paddingInlineStart: "0" }} className="ul-right">
          <li
            className="icon2"
            title={t("tooltip.statistics")}
            style={{
              // fontSize: "1.5rem",
              visibility: gamePractice || isGuideNeed ? "hidden" : "visible",
              opacity: "0.8",
            }}
            onClick={() => {
              props.setIsShareModalOpen(true);
              props.buttonClick("navbar", "stats");
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.2}
              stroke={isDarkMode ? "var(--dull-white)" : "var(--dull-black)"}
              width={deviceSize < 550 ? "25" : "30"}
              height={deviceSize < 550 ? "25" : "30"}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z"
              />
            </svg>
          </li>

          <li
            className="icon2"
            title={
              gamePractice
                ? t("tooltip.solution")
                : t("tooltip.yesterdaysSolution")
            }
            style={{
              pointerEvents: state.game.helpIntroFlag ? "none" : "",
              visibility:
                isGuideNeed || state.game.practiceSelection
                  ? "hidden"
                  : "visible",
              opacity: "0.8",
            }}
            onClick={() => {
              props.setIsSolutionModalOpen((flag) => !flag);
              props.buttonClick("navbar", "solution");
            }}
          >
            <svg
              width={deviceSize < 550 ? "25" : "30"}
              height={deviceSize < 550 ? "25" : "30"}
              version="1.1"
              viewBox="0 0 490.98999 221.88"
              id="svg1815"
              xmlns="http://www.w3.org/2000/svg"
              stroke={isDarkMode ? "var(--dull-white)" : "var(--dull-black)"}
              strokeWidth={5}
              fill="none"
              overflow="visible"
              style={{ margin: "0 1px" }}
            >
              <defs id="defs1701">
                <symbol id="x" overflow="visible">
                  <path
                    d="M 9.375,-0.5625 C 8.89453,-0.3125 8.39062,-0.125 7.8594,0 7.33596,0.13281 6.7969,0.20312 6.2344,0.20312 c -1.7109,0 -3.0586,-0.47266 -4.0469,-1.4219 -0.99219,-0.95703 -1.4844,-2.25 -1.4844,-3.875 0,-1.6328 0.49219,-2.9258 1.4844,-3.875 0.98828,-0.94531 2.3359,-1.4219 4.0469,-1.4219 0.5625,0 1.1016,0.0625 1.625,0.1875 0.53125,0.125 1.0352,0.32031 1.5156,0.57812 v 2.1094 C 8.88281,-7.84769 8.39844,-8.09378 7.9219,-8.25004 7.45315,-8.40629 6.95706,-8.48442 6.4375,-8.48442 c -0.9375,0 -1.6797,0.30469 -2.2188,0.90625 -0.53125,0.60547 -0.79688,1.4336 -0.79688,2.4844 0,1.0547 0.26562,1.8828 0.79688,2.4844 0.53906,0.60547 1.2812,0.90625 2.2188,0.90625 0.51953,0 1.0156,-0.078125 1.4844,-0.23438 0.47656,-0.15625 0.96094,-0.39844 1.4531,-0.73438 z"
                    id="path1629"
                  />
                </symbol>
                <symbol id="d" overflow="visible">
                  <path
                    d="M 6.8594,-5.5781 C 6.64846,-5.67185 6.43752,-5.73826 6.21878,-5.78122 6.00784,-5.832001 5.7969,-5.859345 5.57816,-5.859345 c -0.625,0 -1.1094,0.20312 -1.4531,0.60938 -0.33594,0.39844 -0.5,0.96875 -0.5,1.7188 v 3.5312 h -2.4531 v -7.6562 h 2.4531 v 1.25 c 0.3125,-0.5 0.67188,-0.86328 1.0781,-1.0938 0.40625,-0.22656 0.89453,-0.34375 1.4688,-0.34375 0.082031,0 0.17188,0.00781 0.26562,0.015625 0.09375,0 0.23438,0.015625 0.42188,0.046875 z"
                    id="path1632"
                  />
                </symbol>
                <symbol id="a" overflow="visible">
                  <path
                    d="m 8.8125,-3.8438 v 0.6875 H 3.0937 c 0.0625,0.57422 0.26953,1.0078 0.625,1.2969 0.35156,0.29297 0.84766,0.4375 1.4844,0.4375 0.51953,0 1.0508,-0.078125 1.5938,-0.23438 0.53906,-0.15625 1.0938,-0.39062 1.6562,-0.70312 v 1.8906 c -0.57422,0.21875 -1.1523,0.38672 -1.7344,0.5 -0.57422,0.11328 -1.1523,0.17188 -1.7344,0.17188 -1.3867,0 -2.4648,-0.35156 -3.2344,-1.0625 -0.76172,-0.70703 -1.1406,-1.6953 -1.1406,-2.9688 0,-1.2383 0.375,-2.2188 1.125,-2.9375 0.75,-0.7187 1.7852,-1.0781 3.1094,-1.0781 1.207,0 2.1719,0.36719 2.8906,1.0938 0.71875,0.71875 1.0781,1.6875 1.0781,2.9062 z M 6.2969,-4.6563 c 0,-0.46875 -0.13672,-0.84375 -0.40625,-1.125 -0.27344,-0.28906 -0.625,-0.4375 -1.0625,-0.4375 -0.46875,0 -0.85547,0.13672 -1.1562,0.40625 -0.29297,0.26172 -0.47656,0.64844 -0.54688,1.1562 z"
                    id="path1635"
                  />
                </symbol>
                <symbol id="c" overflow="visible">
                  <path
                    d="m 4.6094,-3.4531 c -0.51172,0 -0.89844,0.089844 -1.1562,0.26562 -0.26172,0.17969 -0.39062,0.43359 -0.39062,0.76562 0,0.3125 0.10156,0.55859 0.3125,0.73438 0.20703,0.17969 0.5,0.26562 0.875,0.26562 0.45703,0 0.84375,-0.16406 1.1562,-0.5 0.3125,-0.33203 0.46875,-0.75 0.46875,-1.25 V -3.45311 Z M 8.3438,-4.37498 V 2e-5 H 5.875 v -1.1406 c -0.32422,0.46875 -0.69531,0.8125 -1.1094,1.0312 -0.40625,0.20703 -0.90234,0.3125 -1.4844,0.3125 -0.80469,0 -1.4492,-0.22656 -1.9375,-0.6875 -0.49219,-0.46875 -0.73438,-1.0703 -0.73438,-1.8125 0,-0.89453 0.30469,-1.5508 0.92188,-1.9688 0.61328,-0.42578 1.582,-0.64062 2.9062,-0.64062 h 1.4375 v -0.1875 c 0,-0.38281 -0.15234,-0.66406 -0.45312,-0.84375 -0.30469,-0.1875 -0.77734,-0.28125 -1.4219,-0.28125 -0.53125,0 -1.0234,0.054688 -1.4688,0.15625 -0.44922,0.10547 -0.87109,0.26562 -1.2656,0.48438 v -1.875 c 0.53125,-0.125 1.0547,-0.21875 1.5781,-0.28125 0.53125,-0.070313 1.0625,-0.10938 1.5938,-0.10938 1.3828,0 2.3789,0.27734 2.9844,0.82812 0.61328,0.54297 0.92188,1.4219 0.92188,2.6406 z"
                    id="path1638"
                  />
                </symbol>
                <symbol id="b" overflow="visible">
                  <path
                    d="m 3.8438,-9.8281 v 2.1719 H 6.375 v 1.75 H 3.8438 v 3.25 c 0,0.35547 0.066406,0.59375 0.20312,0.71875 0.14453,0.125 0.42969,0.1875 0.85938,0.1875 h 1.25 V 5e-5 H 4.0625 C 3.09375,5e-5 2.4063,-0.19526 2,-0.5937 1.60156,-0.99995 1.40625,-1.6875 1.40625,-2.6562 v -3.25 h -1.2188 v -1.75 h 1.2188 v -2.1719 z"
                    id="path1641"
                  />
                </symbol>
                <symbol id="m" overflow="visible">
                  <path
                    d="m 6.3906,-6.5312 v -4.1094 H 8.8437 V 4e-4 H 6.3906 V -1.109 c -0.34375,0.44922 -0.71875,0.78125 -1.125,1 -0.40625,0.20703 -0.875,0.3125 -1.4062,0.3125 -0.9375,0 -1.7148,-0.375 -2.3281,-1.125 -0.60547,-0.75 -0.90625,-1.7188 -0.90625,-2.9062 0,-1.1758 0.30078,-2.1406 0.90625,-2.8906 0.61328,-0.75 1.3906,-1.125 2.3281,-1.125 0.53125,0 1,0.10938 1.4062,0.32812 0.40625,0.21094 0.78125,0.53906 1.125,0.98438 z m -1.625,4.9531 c 0.53125,0 0.92969,-0.19141 1.2031,-0.57812 0.28125,-0.38281 0.42188,-0.94141 0.42188,-1.6719 0,-0.72656 -0.14062,-1.2812 -0.42188,-1.6562 -0.27344,-0.38281 -0.67188,-0.57812 -1.2031,-0.57812 -0.51172,0 -0.90625,0.19531 -1.1875,0.57812 -0.27344,0.375 -0.40625,0.92969 -0.40625,1.6562 0,0.73047 0.13281,1.2891 0.40625,1.6719 0.28125,0.38672 0.67578,0.57812 1.1875,0.57812 z"
                    id="path1644"
                  />
                </symbol>
                <symbol id="l" overflow="visible">
                  <path
                    d="m 5.25,-1.5781 c 0.51953,0 0.91406,-0.19141 1.1875,-0.57812 0.28125,-0.38281 0.42188,-0.94141 0.42188,-1.6719 0,-0.72656 -0.14062,-1.2812 -0.42188,-1.6562 C 6.16406,-5.86713 5.76953,-6.06244 5.25,-6.06244 c -0.52344,0 -0.92188,0.19531 -1.2031,0.57812 -0.28125,0.38672 -0.42188,0.9375 -0.42188,1.6562 0,0.73047 0.14062,1.2891 0.42188,1.6719 C 4.32815,-1.7695 4.72659,-1.5781 5.25,-1.5781 Z M 3.625,-6.5312 c 0.33203,-0.44531 0.70312,-0.77344 1.1094,-0.98438 0.41406,-0.21875 0.89062,-0.32812 1.4219,-0.32812 0.94531,0 1.7227,0.375 2.3281,1.125 0.60156,0.75 0.90625,1.7148 0.90625,2.8906 0,1.1875 -0.30469,2.1562 -0.90625,2.9062 -0.60547,0.75 -1.3828,1.125 -2.3281,1.125 -0.53125,0 -1.0078,-0.10547 -1.4219,-0.3125 -0.40625,-0.21875 -0.77734,-0.55078 -1.1094,-1 V 0 H 1.1719 V -10.641 H 3.625 Z"
                    id="path1647"
                  />
                </symbol>
                <symbol id="k" overflow="visible">
                  <path
                    d="m 0.17188,-7.6562 h 2.4531 l 2.0469,5.1875 1.75,-5.1875 h 2.4531 l -3.2188,8.375 C 5.33196,1.57036 4.95306,2.1641 4.51558,2.5 4.08589,2.84375 3.51949,3.01562 2.81248,3.01562 H 1.40628 V 1.42182 H 2.1719 c 0.41406,0 0.71875,-0.070313 0.90625,-0.20312 0.1875,-0.13672 0.33203,-0.375 0.4375,-0.71875 l 0.0625,-0.20312 z"
                    id="path1650"
                  />
                </symbol>
                <symbol id="j" overflow="visible">
                  <path
                    d="m 1.2812,-10.203 h 3.3594 l 2.3125,5.4531 2.3438,-5.4531 h 3.3438 V 0 h -2.4844 v -7.4688 l -2.3594,5.5 H 6.125 l -2.3438,-5.5 V 0 h -2.5 z"
                    id="path1653"
                  />
                </symbol>
                <symbol id="w" overflow="visible">
                  <path
                    d="M 3.1094,-3.9219 0.3438,-7.6563 h 2.5938 l 1.5625,2.2656 1.5938,-2.2656 H 8.6877 L 5.9221,-3.9375 8.8127,0 H 6.2346 L 4.5002,-2.4219 2.7971,0 H 0.219 Z"
                    id="path1656"
                  />
                </symbol>
                <symbol id="f" overflow="visible">
                  <path
                    d="M 1.1719,-7.6562 H 3.625 V 0 H 1.1719 Z m 0,-2.9844 H 3.625 v 2 H 1.1719 Z"
                    id="path1659"
                  />
                </symbol>
                <symbol id="i" overflow="visible">
                  <path
                    d="m 8.2656,-6.3906 c 0.3125,-0.46875 0.67969,-0.82812 1.1094,-1.0781 0.42578,-0.25 0.89453,-0.375 1.4062,-0.375 0.875,0 1.5391,0.27344 2,0.8125 0.46875,0.54297 0.70312,1.3359 0.70312,2.375 V 0 h -2.4688 v -4 c 0.0078,-0.050781 0.01563,-0.10938 0.01563,-0.17188 v -0.28125 c 0,-0.53906 -0.07813,-0.92969 -0.23438,-1.1719 -0.15625,-0.25 -0.41797,-0.375 -0.78125,-0.375 -0.46094,0 -0.82031,0.19531 -1.0781,0.57812 -0.25,0.38672 -0.38281,0.9375 -0.39062,1.6562 v 3.7656 h -2.4531 v -4 c 0,-0.84375 -0.074219,-1.3828 -0.21875,-1.625 -0.14844,-0.25 -0.40625,-0.375 -0.78125,-0.375 -0.46875,0 -0.83594,0.19531 -1.0938,0.57812 -0.25,0.38672 -0.375,0.9375 -0.375,1.6562 v 3.7656 h -2.4688 v -7.6562 h 2.4688 v 1.125 c 0.30078,-0.4375 0.64453,-0.76562 1.0312,-0.98438 0.39453,-0.21875 0.82812,-0.32812 1.2969,-0.32812 0.51953,0 0.98438,0.13281 1.3906,0.39062 0.40625,0.25 0.71094,0.60547 0.92188,1.0625 z"
                    id="path1662"
                  />
                </symbol>
                <symbol id="v" overflow="visible">
                  <path
                    d="m 5.375,-6.25 c 0.41406,0 0.72656,-0.085938 0.9375,-0.26562 0.21875,-0.1875 0.32812,-0.45703 0.32812,-0.8125 0,-0.35156 -0.10938,-0.625 -0.32812,-0.8125 C 6.10156,-8.32812 5.78906,-8.42187 5.375,-8.42187 H 3.9219 v 2.1719 z m 0.09375,4.4531 c 0.51953,0 0.91406,-0.10938 1.1875,-0.32812 0.26953,-0.22656 0.40625,-0.56641 0.40625,-1.0156 0,-0.4375 -0.13672,-0.76562 -0.40625,-0.98438 C 6.39453,-4.35156 6,-4.46875 5.46875,-4.46875 h -1.5469 v 2.6719 z m 2.4375,-3.6719 c 0.5625,0.16797 1,0.47656 1.3125,0.92188 0.3125,0.4375 0.46875,0.98047 0.46875,1.625 0,0.98047 -0.33594,1.7148 -1,2.2031 C 8.01953,-0.23835 7.0078,-7e-5 5.6563,-7e-5 h -4.375 v -10.203 h 3.9531 c 1.4141,0 2.4414,0.21484 3.0781,0.64062 0.63281,0.42969 0.95312,1.1172 0.95312,2.0625 0,0.49219 -0.11719,0.91406 -0.34375,1.2656 -0.23047,0.34375 -0.57031,0.60156 -1.0156,0.76562 z"
                    id="path1665"
                  />
                </symbol>
                <symbol id="h" overflow="visible">
                  <path
                    d="m 7.1562,-7.4219 v 1.8594 C 6.63276,-5.78125 6.1289,-5.94141 5.6406,-6.04688 5.14841,-6.16016 4.69138,-6.21876 4.2656,-6.21876 c -0.46875,0 -0.82031,0.0625 -1.0469,0.1875 -0.21875,0.11719 -0.32812,0.29297 -0.32812,0.53125 0,0.19922 0.082031,0.35156 0.25,0.45312 0.16406,0.10547 0.47266,0.18359 0.92188,0.23438 l 0.42188,0.046875 c 1.2578,0.16797 2.1016,0.43359 2.5312,0.79688 0.4375,0.36719 0.65625,0.9375 0.65625,1.7188 0,0.8125 -0.30469,1.4297 -0.90625,1.8438 -0.60547,0.40625 -1.5,0.60938 -2.6875,0.60938 -0.51172,0 -1.0391,-0.042969 -1.5781,-0.125 -0.53125,-0.082031 -1.0859,-0.20312 -1.6562,-0.35938 v -1.8594 c 0.48828,0.24219 0.98828,0.42188 1.5,0.54688 0.50781,0.11719 1.0312,0.17188 1.5625,0.17188 0.47656,0 0.83594,-0.066406 1.0781,-0.20312 0.23828,-0.13281 0.35938,-0.33203 0.35938,-0.59375 0,-0.21875 -0.085938,-0.37891 -0.25,-0.48438 -0.16797,-0.10156 -0.49609,-0.1875 -0.98438,-0.25 l -0.4375,-0.0625 c -1.0938,-0.13281 -1.8594,-0.38281 -2.2969,-0.75 -0.4375,-0.375 -0.65625,-0.9375 -0.65625,-1.6875 0,-0.8125 0.27344,-1.4102 0.82812,-1.7969 0.5625,-0.39453 1.4141,-0.59375 2.5625,-0.59375 0.45703,0 0.92969,0.039062 1.4219,0.10938 0.5,0.0625 1.0391,0.16797 1.625,0.3125 z"
                    id="path1668"
                  />
                </symbol>
                <symbol id="g" overflow="visible">
                  <path
                    d="M 8.875,-4.6562 V 0 H 6.4062 v -3.5625 c 0,-0.66406 -0.015625,-1.125 -0.046875,-1.375 -0.023437,-0.25 -0.070313,-0.42969 -0.14062,-0.54688 -0.09375,-0.16406 -0.22656,-0.28906 -0.39062,-0.375 C 5.660115,-5.95313 5.472615,-6 5.265585,-6 c -0.51172,0 -0.91406,0.19922 -1.2031,0.59375 -0.29297,0.39844 -0.4375,0.94531 -0.4375,1.6406 V -5e-5 h -2.4531 v -7.6562 h 2.4531 v 1.125 c 0.36328,-0.44531 0.75391,-0.77344 1.1719,-0.98438 0.41406,-0.21875 0.875,-0.32812 1.375,-0.32812 0.88281,0 1.5547,0.27344 2.0156,0.8125 0.45703,0.54297 0.6875,1.3359 0.6875,2.375 z"
                    id="path1671"
                  />
                </symbol>
                <symbol id="u" overflow="visible">
                  <path
                    d="M 1.1719,-10.641 H 3.625 v 5.7969 L 6.4375,-7.6566 H 9.2813 L 5.5469,-4.141 9.5781,-4e-4 H 6.6093 L 3.6249,-3.1879 V -4e-4 H 1.1718 Z"
                    id="path1674"
                  />
                </symbol>
                <symbol id="t" overflow="visible">
                  <path
                    d="m 6.2188,-10.641 v 1.6094 H 4.8594 c -0.34375,0 -0.58594,0.0625 -0.71875,0.1875 -0.13672,0.125 -0.20312,0.34375 -0.20312,0.65625 v 0.53125 h 2.0781 v 1.75 H 3.93753 V -4e-4 h -2.4531 v -5.9062 h -1.2188 v -1.75 h 1.2188 v -0.53125 c 0,-0.83203 0.22656,-1.4453 0.6875,-1.8438 0.46875,-0.40625 1.1914,-0.60938 2.1719,-0.60938 z"
                    id="path1677"
                  />
                </symbol>
                <symbol id="e" overflow="visible">
                  <path
                    d="m 4.8125,-6.0938 c -0.54297,0 -0.95312,0.19922 -1.2344,0.59375 -0.28125,0.38672 -0.42188,0.94531 -0.42188,1.6719 0,0.74219 0.14062,1.3086 0.42188,1.7031 0.28125,0.38672 0.69141,0.57812 1.2344,0.57812 0.53906,0 0.95312,-0.19141 1.2344,-0.57812 0.28125,-0.39453 0.42188,-0.96094 0.42188,-1.7031 0,-0.72656 -0.14062,-1.2852 -0.42188,-1.6719 C 5.76565,-5.89458 5.35159,-6.0938 4.8125,-6.0938 Z m 0,-1.75 c 1.3203,0 2.3516,0.35938 3.0938,1.0781 0.73828,0.71094 1.1094,1.6875 1.1094,2.9375 0,1.2617 -0.37109,2.25 -1.1094,2.9688 C 7.16411,-0.14846 6.1329,0.2031 4.8125,0.2031 3.5,0.2031 2.4687,-0.14846 1.7187,-0.8594 0.97651,-1.57815 0.6093,-2.5664 0.6093,-3.8282 c 0,-1.25 0.36719,-2.2266 1.1094,-2.9375 0.75,-0.71875 1.7812,-1.0781 3.0938,-1.0781 z"
                    id="path1680"
                  />
                </symbol>
                <symbol id="s" overflow="visible">
                  <path
                    d="M 8.875,-4.6562 V 0 H 6.4062 v -3.5625 c 0,-0.66406 -0.015625,-1.125 -0.046875,-1.375 -0.023437,-0.25 -0.070313,-0.42969 -0.14062,-0.54688 -0.09375,-0.16406 -0.22656,-0.28906 -0.39062,-0.375 C 5.660115,-5.95313 5.472615,-6 5.265585,-6 c -0.51172,0 -0.91406,0.19922 -1.2031,0.59375 -0.29297,0.39844 -0.4375,0.94531 -0.4375,1.6406 V -5e-5 h -2.4531 v -10.641 h 2.4531 v 4.1094 c 0.36328,-0.44531 0.75391,-0.77344 1.1719,-0.98438 0.41406,-0.21875 0.875,-0.32812 1.375,-0.32812 0.88281,0 1.5547,0.27344 2.0156,0.8125 0.45703,0.54297 0.6875,1.3359 0.6875,2.375 z"
                    id="path1683"
                  />
                </symbol>
                <symbol id="r" overflow="visible">
                  <path
                    d="m 1.2812,-10.203 h 2.9375 l 3.7188,7 v -7 h 2.5 V 0 H 7.5 L 3.7812,-7 v 7 h -2.5 z"
                    id="path1686"
                  />
                </symbol>
                <symbol id="q" overflow="visible">
                  <path
                    d="m 1.0938,-2.9844 v -4.6719 h 2.4688 v 0.76562 c 0,0.41797 -0.00781,0.9375 -0.015625,1.5625 v 1.25 c 0,0.61719 0.015625,1.0586 0.046875,1.3281 0.03125,0.27344 0.082031,0.46875 0.15625,0.59375 0.10156,0.15625 0.23438,0.28125 0.39062,0.375 0.16406,0.085938 0.35156,0.125 0.5625,0.125 0.50781,0 0.91016,-0.19141 1.2031,-0.57812 0.28906,-0.39453 0.4375,-0.94141 0.4375,-1.6406 v -3.7812 h 2.4531 V -5e-5 h -2.4531 v -1.1094 c -0.36719,0.44922 -0.75781,0.78125 -1.1719,1 -0.41797,0.20703 -0.87109,0.3125 -1.3594,0.3125 -0.88672,0 -1.5625,-0.26953 -2.0312,-0.8125 -0.46094,-0.53906 -0.6875,-1.332 -0.6875,-2.375 z"
                    id="path1689"
                  />
                </symbol>
                <symbol id="p" overflow="visible">
                  <path
                    d="m 1.2812,-10.203 h 4.375 c 1.3008,0 2.2969,0.28906 2.9844,0.85938 0.69531,0.57422 1.0469,1.3984 1.0469,2.4688 0,1.0742 -0.35156,1.8984 -1.0469,2.4688 C 7.9531,-3.8318 6.957,-3.54664 5.6562,-3.54664 H 3.9218 V 2.6e-4 H 1.2812 Z m 2.6406,1.9062 v 2.8438 h 1.4531 c 0.50781,0 0.89844,-0.11719 1.1719,-0.35938 0.28125,-0.25 0.42188,-0.60156 0.42188,-1.0625 0,-0.45703 -0.14062,-0.80469 -0.42188,-1.0469 -0.27344,-0.25 -0.66406,-0.375 -1.1719,-0.375 z"
                    id="path1692"
                  />
                </symbol>
                <symbol id="o" overflow="visible">
                  <path
                    d="M 1.1719,-7.6562 H 3.625 v 7.5156 c 0,1.0312 -0.25,1.8125 -0.75,2.3438 C 2.38281,2.74226 1.6758,3.0157 0.75,3.0157 H -0.4688 V 1.4219 h 0.42188 c 0.46875,0 0.78516,-0.10938 0.95312,-0.32812 0.17578,-0.21094 0.26562,-0.62109 0.26562,-1.2344 z m 0,-2.9844 H 3.625 v 2 H 1.1719 Z"
                    id="path1695"
                  />
                </symbol>
                <symbol id="n" overflow="visible">
                  <path
                    d="m 7.3594,-7.4219 v 2 c -0.33594,-0.22656 -0.66797,-0.39453 -1,-0.5 -0.33594,-0.11328 -0.68359,-0.17188 -1.0469,-0.17188 -0.67969,0 -1.2109,0.20312 -1.5938,0.60938 -0.375,0.39844 -0.5625,0.94922 -0.5625,1.6562 0,0.71875 0.1875,1.2812 0.5625,1.6875 0.38281,0.39844 0.91406,0.59375 1.5938,0.59375 0.38281,0 0.75,-0.054687 1.0938,-0.17188 0.34375,-0.11328 0.66016,-0.28516 0.95312,-0.51562 v 2.0156 C 6.9727,-0.08213 6.58208,0.01943 6.18752,0.09365 5.8008,0.163962 5.41018,0.20303 5.01562,0.20303 c -1.3867,0 -2.4688,-0.35156 -3.25,-1.0625 -0.77344,-0.70703 -1.1562,-1.6953 -1.1562,-2.9688 0,-1.2578 0.38281,-2.2422 1.1562,-2.9531 0.78125,-0.70703 1.8633,-1.0625 3.25,-1.0625 0.39453,0 0.78516,0.039062 1.1719,0.10938 0.39453,0.074219 0.78516,0.17969 1.1719,0.3125 z"
                    id="path1698"
                  />
                </symbol>
              </defs>
              <g id="g2240" transform="rotate(90,399.5275,160.4725)">
                <path
                  d="m 350,247.96 c -37.508,0 -68.02,-30.512 -68.02,-68.023 0,-37.504 30.508,-68.02 68.02,-68.02 37.508,0 68.023,30.512 68.023,68.02 0,37.512 -30.516,68.023 -68.023,68.023 z m 0,-118.33 c -27.734,0 -50.301,22.562 -50.301,50.301 0,27.742 22.566,50.309 50.301,50.309 27.738,0 50.309,-22.566 50.309,-50.309 0,-27.738 -22.57,-50.301 -50.309,-50.301 z"
                  id="path1703"
                  fill={isDarkMode ? "var(--dull-white)" : "var(--dull-black)"}
                />
                <path
                  d="M 381.14,560 H 318.867 V 286.42 c -46.648,-13.664 -79.812,-57.344 -79.812,-106.48 0,-61.164 49.77,-110.93 110.95,-110.93 61.164,0 110.93,49.762 110.93,110.93 0,49.145 -33.152,92.82 -79.789,106.48 v 125.95 h 66.656 v 54.84 H 414.49 v 19.398 h 33.312 v 54.828 h -66.656 z m -44.559,-17.715 h 26.844 v -18.57 h 66.652 v -19.398 h -33.312 v -54.828 h 33.312 v -19.41 h -66.652 v -157.62 l 6.9336,-1.5469 c 42.215,-9.4023 72.855,-47.668 72.855,-90.977 0,-51.398 -41.816,-93.215 -93.211,-93.215 -51.406,0 -93.23,41.816 -93.23,93.215 0,43.309 30.652,81.574 72.883,90.977 l 6.9258,1.5469 0.004,269.82 z"
                  id="path1705"
                  fill={isDarkMode ? "var(--dull-white)" : "var(--dull-black)"}
                />
              </g>
            </svg>
          </li>

          <li
            className="icon2"
            title={t("labels.settings")}
            onClick={() => {
              props.setIsSettingModalOpen(true);
              props.buttonClick("navbar", "settings");
            }}
            style={{ opacity: "0.8" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.2}
              stroke={isDarkMode ? "var(--dull-white)" : "var(--dull-black)"}
              width={deviceSize < 550 ? "25" : "30"}
              height={deviceSize < 550 ? "25" : "30"}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
          </li>
        </ul>
      </nav>
    </>
  );
}
