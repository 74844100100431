import i18next from 'i18next'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import languagedetector from 'i18next-browser-languagedetector'

const languages = ['en-IN', 'ta', 'th', 'hi']

i18next
  // load translation using http -> see /public/locales
  .use(Backend)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
    // detect user language
  .use(languagedetector)

  // init i18next
  .init({
    fallbackLng: 'en-IN',
    debug: true,
    whitelist: languages,
    react: {
      useSuspense: true
    }
  })

const userLanguage = window.navigator.language

if (languages.some(lang => lang.toLowerCase() === userLanguage.toLowerCase()))
  i18next.changeLanguage(userLanguage)

// console.log('language',i18next.language)
// console.log('user language', userLanguage)
// console.log('condition', languages.some(lang => lang.toLowerCase() === userLanguage.toLowerCase()))

export const userLang = i18next.language
export default i18next
