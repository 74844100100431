import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";

import gameSlice from "./reducers/gameSlice";

import { enableMapSet } from 'immer';
enableMapSet()

const store = configureStore({
    reducer:{
        game: gameSlice
    },
    middleware: getDefaultMiddleware({
        serializableCheck: false,
    }),
})

export default store;