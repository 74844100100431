import { useSelector } from "react-redux";
import {
    redBorder,
    greenBorder,
    blueBorder,
    yellowBorder,
    outerTrumpBorder,
    innerTrumpBorder,
} from "../components/Grid/HandGridData";
import { nanoid } from 'nanoid'

import { followSuit, darkBgColor } from "../components/Home";

const RenderTrick = (tricks, winners, trump, isDarkMode) => {
  
  // to finding the trump color
  let ourTrumpColor = followSuit.get(trump) ? followSuit.get(trump).color : null
  // const isDarkMode = useSelector((state) => state.game.isDarkMode)

  let innerTrump = { ...innerTrumpBorder }
  innerTrump.backgroundColor = ourTrumpColor
  let outerTrump = { ...outerTrumpBorder }
  outerTrump.backgroundColor = ourTrumpColor
  outerTrump.borderColor = ourTrumpColor

  const normalSuit = "white"

  const winningFontColor = "white"
  
  const dullColumn = {
    backgroundColor: isDarkMode ? darkBgColor.containers : "#EAE5BE", //rgb(51 45 45 / 40%) LIGHT
    // borderRadius: "0.5rem",
  };

  const winningTrumpTrick = (playerIndex, trickIndex, card) => {
    return (
      <div
        className="trick-card"
        key={trickIndex}
        style={
          typeof(winners[trickIndex]) !== "undefined"
            ? winners[trickIndex] === playerIndex //choosing winning or losing card
              ? {
                //winning card
                ...outerTrump,
                color: 'black'
              }
              : {
                //losing card
                ...outerTrump,
                backgroundColor: !isDarkMode ? "#FBFBFB" : darkBgColor.body,

              }
            : {
              //finishedTrick
              ...outerTrump,
              backgroundColor: !isDarkMode ? "#FBFBFB" : darkBgColor.body,

            }
        }
      >
        <div
          key={card.rank}
          className="trick-card"
          style={
            typeof (winners[trickIndex]) !== "undefined"
              ? winners[trickIndex] === playerIndex //choosing winning or losing card
                ? {
                  //winning card
                  ...innerTrump,
                  color: winningFontColor,
                  borderColor: isDarkMode ? '#fbfbfb' : '#fbfbfb',
                  margin: "0px",
                }
                : {
                  //losing card
                  ...innerTrump,
                  backgroundColor: isDarkMode ? darkBgColor.body : "#FBFBFB",
                  color: ourTrumpColor,
                  borderColor: ourTrumpColor,
                  margin: "0px",
                  height: "calc(var(--card-size) / 1.5)",
                  width: "calc(var(--card-size) / 1.5)",
                }
              : {
                //finishedTrick
                ...innerTrump,
                color: normalSuit,
                borderColor: normalSuit,
                margin: "0px",
              }
          }
        >
          <p
            style={{
              margin: "3px",
              fontSize: "calc(var(--card-size) * 0.36)",
            }}
          >
            {card.rank}
          </p>
        </div>
      </div>
    )
  }

  const winningNTtrick = (playerIndex, trickIndex, card) => {
    return (
      <div
        className="trick-card"
        style={{
          paddingTop: 0, 
          ...(card.suit === "B"
            ? typeof (winners[trickIndex]) !== "undefined"
              ? winners[trickIndex] === playerIndex
                ? { ...blueBorder, color: winningFontColor } // winning card
                : {
                  ...blueBorder,
                  color: blueBorder.backgroundColor,  // losing card
                  backgroundColor: isDarkMode
                    ? darkBgColor.body
                    : "#FBFBFB",
                } : {
                ...blueBorder,
                color: blueBorder.backgroundColor,
                backgroundColor: isDarkMode
                  ? darkBgColor.body
                  : "#FBFBFB",
              }
            : card.suit === "Y"
              ? typeof (winners[trickIndex]) !== "undefined"
                ? winners[trickIndex] === playerIndex
                  ? { ...yellowBorder, color: winningFontColor }
                  : {
                    ...yellowBorder,
                    color: yellowBorder.backgroundColor,
                    backgroundColor: isDarkMode
                      ? darkBgColor.body
                      : "#FBFBFB",
                  } : {
                  ...yellowBorder,
                  color: yellowBorder.backgroundColor,
                  backgroundColor: isDarkMode
                    ? darkBgColor.body
                    : "#FBFBFB",
                }
              : card.suit === "R"
                ? typeof (winners[trickIndex]) !== "undefined"
                  ? winners[trickIndex] === playerIndex
                    ? { ...redBorder, color: winningFontColor }
                    : {
                      ...redBorder,
                      color: redBorder.backgroundColor,
                      backgroundColor: isDarkMode
                        ? darkBgColor.body
                        : "#FBFBFB",
                    } : {
                    ...redBorder,
                    color: redBorder.backgroundColor,
                    backgroundColor: isDarkMode
                      ? darkBgColor.body
                      : "#FBFBFB",
                  }
                : card.suit === "G"
                  ? typeof (winners[trickIndex]) !== "undefined" 
                    ? winners[trickIndex] === playerIndex
                      ? { ...greenBorder, color: winningFontColor }
                      : {
                        ...greenBorder,
                        color: greenBorder.backgroundColor,
                        backgroundColor: isDarkMode
                          ? darkBgColor.body
                          : "#FBFBFB",
                      } : {
                      ...greenBorder,
                      color: greenBorder.backgroundColor,
                      backgroundColor: isDarkMode
                        ? darkBgColor.body
                        : "#FBFBFB",
                    } : {}),
          pointerEvents: "none",
        }}
        key={card.rank + card.suit}
      >
        {card.rank}
      </div>
    )
  }

  return (
    <div className="trick-grid-columns" style={{ '--player-count' : tricks.length}}>
      {
        tricks.map((trick, playerIndex) => {
          return (
            <div
              className="grid-col-solution"
              id={`trick-grid-col-${playerIndex + 1}`}
              style={dullColumn}
              key={nanoid()}
            >
              {
                trick.map((playedCard, trickIndex) => {
                  if (playedCard.card.suit === 'T') {
                    return (
                      <>
                        {
                          winningTrumpTrick(playerIndex, trickIndex, playedCard.card)
                        }
                      </>
                    )
                  } else {
                    return (
                      <>
                        {
                          winningNTtrick(playerIndex, trickIndex, playedCard.card)
                        }
                      </>
                    )
                  }

                })
              }
            </div>
          )
        })
      }
    </div>
  )
}

export default RenderTrick