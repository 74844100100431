import { useEffect } from "react";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Home from "./components/Home";
import "./App.css";

import themes from "./themes/schema.json";
import { GlobalStyles } from "../src/themes/GlobalStyles";
import { darkBgColor } from "./components/Home";

var userID

const App = (props) => {
  userID = props.userID
  const isDarkMode = useSelector((state) => state.game.isDarkMode);
  const deviceSize = useSelector((state) => state.game.deviceSize);
  const language = useSelector(state => state.game.language)
  const playerCount = useSelector(state => state.game.playerCount)
  const cardCount = useSelector(state => state.game.cardCount)
  const cardSize = useSelector(state => state.game.cardSize);
  // console.log("card-size", cardSize )


  const CardStyles = createGlobalStyle`
  body {
    --card-count: ${({ cardCount }) => cardCount};
    --player-count: ${({ playerCount }) => playerCount};
    --card-size: ${({ cardSize }) => cardSize};
  }
  `
  
  const { i18n } = useTranslation()

  const portrait = 'portrait'
  const APP_VERSION = 4;
    if ( localStorage.APP_VERSION === undefined || localStorage.APP_VERSION == '2' || localStorage.APP_VERSION === null || localStorage.APP_VERSION == '3') {
        localStorage.clear();
        localStorage.setItem('APP_VERSION',APP_VERSION);
    }

  localStorage.setItem('orientate', deviceSize < 550 ? portrait : '')

  useEffect(() => {
    if (language.code) { // change language
      i18n.changeLanguage(language.code)
      // console.log('language changed', language)
    }
  }, [language])

  let mybody = document.getElementById("body");
  mybody.style.backgroundColor = isDarkMode ? darkBgColor.body : "#fbfbfb"; //'#332d2d' : '#fbfbfb' pallete black and white
  return (
    <>
      {
        <ThemeProvider
          theme={isDarkMode ? themes.data.dark : themes.data.light}
        >
          <CardStyles cardCount={cardCount} playerCount={playerCount} cardSize={cardSize} />
          <GlobalStyles />
          <Home />

        </ThemeProvider>
      }
    </>
  );
}

// sending userid created in INDEX file, to children components
export { userID }
export default App;