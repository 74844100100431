import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import RenderTrick from "../../utils/renderTrick";
import HandGrid from "../Grid/HandGrid";
import Target from "../Grid/Target";
import useMediaQuery from "../hooks/useMediaQuery";
import { getLocalState } from "../../lib/localStorage";
import { useTranslation } from "react-i18next";
import { pad } from "../Home";

const SolutionModal = (props) => {
  const isDarkMode = useSelector((state) => state.game.isDarkMode);
  const isGuideNeed = useSelector((state) => state.game.isGuideNeed);
  const gamePractice = useSelector((state) => state.game.gamePractice);
  const showProblems = useSelector((state) => state.game.showProblems);
  const langFont = useSelector((state) => state.game.langFont);
  const { t } = useTranslation()
  const dayIndex =
    getLocalState === null ? undefined : getLocalState("day");
  

  const [isToggled, setToggle] = useState(false);

  const isMobile = useMediaQuery("(min-width: 300px) and (max-width: 400px)");

  useEffect(() => {
    if (isGuideNeed) setToggle(false);
  }, [isGuideNeed]);

  const handleChange = (event) => {
    setToggle(event.target.checked);
  };
  let winners = [];
  let tricks;
  if (props.solution) {
    tricks = Array.from(new Array(props.solution[0].length - 1), () => []);
    props.solution.forEach((trick) => {
      trick.forEach((card) => {
        if (typeof card === "object") tricks[card.player].push(card);
        else if (typeof card === "number") winners.push(card);
      });
    });
  }

  const otherSolutionsCount = props.linesOfPlay !== undefined ? props.linesOfPlay - 1 > 1 ? t('labels.solution.one', { count: props.linesOfPlay - 1 }) : t('labels.solution.two', { count: props.linesOfPlay - 1 }) : null
  // const otherSolutionsCount = 1;

  return (
    <>
      <Dialog
        open={props.isSolutionModalOpen}
        onClose={props.closeSolutionModal}
        onBackdropClick={true}
        PaperProps={{
          sx: {
            userSelect: "none",
            width: "30vw",
            overflow: "hidden",
            height: "78vh",
            backgroundColor: isDarkMode
              ? "var(--dark-body)"
              : "var(--light-body)",
            color: isDarkMode ? "#fbfbfb" : "#332d2d",
            ["@media (max-width: 400px)"]: {
              left: 0,
            },
            ["@media (max-width: 468px)"]: {
              width: "calc(100vw - 2rem)",
              left: 0,
            },
            ["@media (min-width: 470px) and (max-width: 550px)"]: {
              width: "60vw",
              left: 0,
            },
            ["@media (min-width: 551px) and (max-width: 767px)"]: {
              width: "55vw",
              left: 0,
            },
            ["@media (min-width: 768px) and (max-width: 1024px)"]: {
              width: "calc(100vw - 17rem)",
              left: 0,
            },
          },
        }}
      >
        <DialogTitle style={{ textAlign: "center", fontFamily: "satoshi-light" }}>
          {isGuideNeed || gamePractice
            ? t('tooltip.solution')
            : isToggled
            ? t('tooltip.yesterdaysCardle')
            : t('tooltip.yesterdaysSolution') }
          <IconButton
            onClick={() => {
              props.closeSolutionModal();
              setToggle(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: isDarkMode ? "#fbfbfb" : "#332d2d",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            paddingTop: isMobile ? "25px" : "20px",
            overflow: "hidden"
          }}
        >
          {isToggled ? (
            <>
              <div className="solution-target-hand">
                <Target
                  target={props.target}
                  forGuide={true}
                  handTarget={true}
                  solution={true}
                />
                <div className="grid-hand">
                  <HandGrid trump={props.trump} hands={props.hands} />
                </div>
              </div>
              <div
                style={{
                  textAlign: "center",
                  fontSize: "1.1rem",
                  marginTop: "calc(var(--card-gap) * 4)",
                  paddingBottom: "0",
                  fontWeight: 500,
                  visibility: "hidden",
                  fontFamily: "satoshi-light",
                  ...langFont.buttons,
                }}
              >
                {!isToggled ? (
                  <></>
                ) : otherSolutionsCount}
              </div>
            </>
          ) : (
            <>
              <div className="solution-target">
                <Target
                  target={props.target}
                  forGuide={true}
                  solution={true}
                  winner={props.solution}
                />
                {tricks !== undefined
                  ? RenderTrick(tricks, winners, props.trump, isDarkMode)
                  : null}
              </div>
              <div
                style={{
                  textAlign: "center",
                  fontSize: "1.1rem",
                  marginTop: "calc(var(--card-gap) * 4)",
                  paddingBottom: "0",
                  fontWeight: 500,
                  fontFamily: "satoshi-light",
                  ...langFont.buttons,
                }}
              >
                {isToggled ? (
                  <></>
                ) : otherSolutionsCount}
              </div>
            </>
          )}

        </DialogContent>
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "1.1rem",
              margin: isMobile ? "2vh" : "3vh",
              marginTop: 0,
              paddingTop: 0,
            }}
          >
            {isGuideNeed || gamePractice ? (
              <></>
            ) : (
              <div
                className="three-buttons"
                onClick={() => setToggle(!isToggled)}
              >
                <button
                  style={{
                    margin: "0px",
                    boxShadow: !isDarkMode ? '1px 2px 4px' : '0px 1px 2.5px var(--button-shadow)',
                    borderWidth: 0,
                    fontFamily: "satoshi-light",
                    borderColor: isDarkMode ? 'var(--dark-container)' : 'var(--light-container)',
                    color:isDarkMode ? 'var(--white)' : 'var(--dark)',
                    backgroundColor: isDarkMode ? 'var(--dark-container)' : 'var(--light-container)',
                    fontWeight: "0",
                    ...langFont.buttons,
                  }}
                  className="solution-button"
                  onClick={handleChange}
                >
                  {" "}
                  {isToggled ? t('tooltip.yesterdaysSolution') : t('tooltip.yesterdaysCardle')}
                </button>
              </div>
            )}
          </div>
          {gamePractice ? 
            <div
              style={{
                fontFamily: "satoshi-light",
                fontWeight: "500",
                position: "relative",
                bottom: "3vh",
                opacity: "0.5",
                display: 'flex',
                justifyContent: 'center',
                marginTop: "3vh",
                ...langFont.stats,
              }}
            >
              {" "}
              {t('labels.practiceWithCount', { count: (showProblems+1) < 10 ? `0${showProblems + 1}` : `${showProblems + 1}` })}
            </div> : 
            <div
              style={{
                ...langFont.stats,
                fontFamily: "satoshi-light",
                fontWeight: "500",
                position: "relative",
                bottom: "3vh",
                opacity: "0.5",
                display: 'flex',
                justifyContent: 'center',
                marginTop: "3vh",
              }}
          >
              {" "}
              {t('labels.edition', { count: `${pad(dayIndex, 2)}` })}
          </div>
        }
        </>
        
      </Dialog>
    </>
  );
};

export default SolutionModal;
