import ls from 'local-storage'

const gameStateKeys = [
  "hands",
  "tricks",
  "score",
  "attempts",
  "target",
  "totalscore",
  "gamewon",
  "gamelost",
  "walkthroughstep",
];

//to set key value pair in local storage
export const setLocalStorageValue = (key, value) => {
  return ls(key, value);
};

//to get the value form local storage
export const getLocalState = (key) => {
  return ls(key);
};

// remove the localstate 
export const removeLocalState = (key) => {
  return ls.remove(key)
}

export const clearLocalGameState = () => {
  gameStateKeys.forEach((key) => {
    ls.remove(key);
  });
};

export const getLocalGameState = () => {
  let gameState = new Map()

  gameStateKeys.forEach(key => {
    if (ls(key) !== null) {
      gameState.set(key, ls(key))

    }
  })
  return gameState
}
