import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import noTrumpProblems from "../../constants/practiceProblems.json";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";

import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { pad } from "../Home";
import {
  SET_NO_TRUMP_PROBLEMS,
  SET_PROBLEMS_PAGE,
} from "../../redux/reducers/gameSlice";
import { getLocalState } from "../../lib/localStorage";

export default function PractiseCardle(props) {
  const isDarkMode = useSelector((state) => state.game.isDarkMode);
  const dispatch = useDispatch();
  const isNoTrump = useSelector((state) => state.game.noTrumpProblems);
  const langFont = useSelector((state) => state.game.langFont)
  const problemPage = useSelector((state) => state.game.problemsPage);
  const { t } = useTranslation();

  const [problmes, setProblems] = useState([...noTrumpProblems].splice(0, 9));

  useEffect(() => {
    if (isNoTrump) {
      // load the no trump page selectors
      if (problemPage === 1) {
        setProblems([...noTrumpProblems].splice(0, 9));
      } else {
        setProblems([...noTrumpProblems].splice(9, 9));
      }
    } else {
      if (problemPage === 1) {
        setProblems([...noTrumpProblems].splice(18, 9));
      } else {
        setProblems([...noTrumpProblems].splice(27, 9));
      }
    }
  }, [isNoTrump, problemPage]);

  useEffect(() => {
    console.log("problems", problmes, problemPage);
  }, [problmes, problemPage]);

  const getBackColor = (priority) => {
    if (priority === "Easy")
      return { backgroundColor: "var(--green)", borderBottom: "yellow" };
    else if (priority === "Medium") return { backgroundColor: "var(--yellow)" };
    else if (priority === "Hard") return { backgroundColor: "var(--red)" };
  };

  const getCardleNumber = (page, noTrump) => {
    if (noTrump) {
      if (page === 1) {
        return 0;
      } else {
        return 9;
      }
    } else {
      if (page === 1) {
        return 18;
      } else {
        return 27;
      }
    }
  };
  // to calculate cardleNumber
  const cardleNumber = useMemo(
    () => getCardleNumber(problemPage, isNoTrump),
    [problemPage, isNoTrump]
  );

  const noOfProblems = noTrumpProblems.length / 2;
  const solvedPracticeProblems = getLocalState("solvedProblems");
  const MakePracticeGrid = () => {

    return problmes.map((item, ind) => {
      return (
        <>
          <div
            className="cards-grid"
            style={{ ...getBackColor(item.level) }}
            onClick={() => {
              props.practiceEvents("start", "practice_problem_selected", ind+1, item.level)
              props.practiceCardleProblems(ind + cardleNumber, item);
            }}
          >
            <div className="practice-tick">
                <FontAwesomeIcon
                  style={{ 
                    visibility: solvedPracticeProblems.length &&  solvedPracticeProblems.includes(ind + cardleNumber) ? "auto" : "hidden"
                  }}
                  icon={faCheck}
                />
            </div>
            <p style={{ fontFamily: "satoshi-light" }}>{`${t(
              "labels.cardoo"
            )} #${pad(ind + 1 + cardleNumber, 2)}`}</p>
            <hr className="line1"></hr>
            <div className="flexx" style={{ gap: noOfProblems <= 9 ? "" : "" }}>
              <span className="" style={{ fontFamily: "satoshi-light" }}>
                {t("labels.level")}
              </span>
              <span style={{ fontFamily: "satoshi-light" }}>
                { item.level === 'Easy' ? t(`labels.Easy`) : item.level === 'Hard' ? t(`labels.Hard`) : t(`labels.Medium`)}
              </span>
            </div>
          </div>
        </>
      );
    });
  };

  const svgButtons = (svg, page) => {
    return (
      <div
        className={`svg-button ${
          isDarkMode ? "color-dull-white" : "color-dull-black"
        } ${page === problemPage ? "disable" : "active"}`}
        onClick={() => dispatch(SET_PROBLEMS_PAGE(page))}
      >
        <FontAwesomeIcon icon={svg} />
      </div>
    );
  };

  return (
    <>
      <div className="container-practice">
        <div className="problem-selector">
          <button
            className="selector-button"
            style={{
              padding: "8px 4px",
              height: isNoTrump ? "auto" : "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "1.5rem 1.5rem 0 0",
              backgroundColor: isNoTrump
                ? "var(--ball-color)"
                : isDarkMode
                ? "var(--dark-body)"
                : "var(--light-body)",
              borderTop: isNoTrump
                ? "2px solid var(--ball-color)"
                : "2px solid var(--light-navbar-line)",
              borderLeft: isNoTrump
                ? "2px solid var(--ball-color)"
                : "2px solid var(--light-navbar-line)",
              borderRight: isNoTrump
                ? "2px solid var(--ball-color)"
                : "2px solid var(--light-navbar-line)",

              fontFamily: "satoshi",
              color: isNoTrump
                ? ""
                : isDarkMode
                ? "var(--white)"
                : "var(--light-active-column)",
              fontSize: "calc(var(--card-size) * 0.36)",
            }}
            onClick={() => {
              dispatch(SET_NO_TRUMP_PROBLEMS(true));
              dispatch(SET_PROBLEMS_PAGE(1));
            }}
          >
            <span
              style={{
                fontFamily: "satoshi",
              }}
            >
              {t("labels.noTrump")}{" "}
            </span>{" "}
          </button>
          <button
            className="selector-button"
            style={{
              fontSize: "calc(var(--card-size) * 0.36)",
              padding: "8px 4px",
              height: !isNoTrump ? "auto" : "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "1.5rem 1.5rem 0 0",
              backgroundColor: !isNoTrump
                ? "var(--ball-color)"
                : isDarkMode
                ? "var(--dark-body)"
                : "var(--light-body)",
              borderTop: !isNoTrump
                ? "2px solid var(--ball-color)"
                : "2px solid var(--light-navbar-line)",
              borderLeft: !isNoTrump
                ? "2px solid var(--ball-color)"
                : "2px solid var(--light-navbar-line)",
              borderRight: !isNoTrump
                ? "2px solid var(--ball-color)"
                : "2px solid var(--light-navbar-line)",

              fontFamily: "satoshi",
              color: !isNoTrump
                ? ""
                : isDarkMode
                ? "var(--white)"
                : "var(--light-active-column)",
            }}
            onClick={() => {
              dispatch(SET_NO_TRUMP_PROBLEMS(false));
              dispatch(SET_PROBLEMS_PAGE(1));
            }}
          >
            <span
              style={{
                fontFamily: "satoshi",
              }}
            >
              {t("labels.trump")}{" "}
            </span>{" "}
          </button>
        </div>
        <div className={"practice-app"}>
          <MakePracticeGrid />
        </div>
        <div className="container-buttons">
          <div className="arrow-buttons">
            {svgButtons(faAngleLeft, 1)}
            {svgButtons(faAngleRight, 2)}
          </div>
        </div>
      </div>
      <div className="practice-footer">
        <div
          style={{
            fontFamily: "satoshi-light",
            fontWeight: "100",
            opacity: "0.5",
            textAlign: "center",
            ...langFont.stats,
          }}
        >
          {t("labels.practice")}
        </div>
      </div>
    </>
  );
}
