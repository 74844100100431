export const Color = {
    WHITE: 'rgb(227 227 227)',
    BLACK: '#332d2d',
    CELL_BACKGROUND_COLOR: '#E5EBB2',
    RED: '#DC4C64',
    GREEN: '#14A44D',
    BLUE: '#3b71ca ',
    DARK_BLUE: '#54b4d3',
    YELLOW: '#E4A11B',
    DARK_BODY: "rgb(51,51,51)",
    LIGHT_BODY: '#fbfbfb',
    DARK_CONTAINER: 'rgb(37, 37, 38)',
    LIGHT_CONTAINER: 'rgb(234, 229, 190)',
    LIGHT_ACTIVE_COLUMN : "rgba(45, 45, 45, 0.7)",
    BALL: '#54b4d3',
    BALL_GREY: "rgba(51,51,51, .9)"
}
