import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { nanoid } from "nanoid";
import { useTranslation } from "react-i18next";
import {
  redBorder,
  greenBorder,
  blueBorder,
  yellowBorder,
  outerTrumpBorder,
  innerTrumpBorder,
} from "./HandGridData";
import { Color } from "../../constants/Colors";
import { SET_FOLLOWSUIT, SET_ALERT_STOP } from "../../redux/reducers/gameSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { darkBgColor, ourTrumpColor, tricks } from "../Home";
import useAlert from "../hooks/useAlert";


const TrickArray = (props) => {
  const { setAlertMessage } = useAlert();
  const { t } = useTranslation();

  const state = useSelector((state) => state.game);
  const isDarkMode = useSelector((state) => state.game.isDarkMode);
  const isGuideNeed = useSelector((state) => state.game.isGuideNeed);
  const dispatch = useDispatch();
  let currentTrickNo = useSelector((state) => state.game.currentTrick);
  let tricksArray = useSelector((state) => state.game.tricks);
  let gameLost = useSelector((state) => state.game.gameLost);
  let gameWon = useSelector((state) => state.game.gameWon);
  let winners = useSelector((state) => state.game.winners);
  const followSuit = useSelector((state) => state.game.followSuit);
  const trumpColor = useSelector((state) => state.game.trumpColor);
  let previousTrick = useSelector((state) => state.game.prevTrick);
  const attempts = useSelector((state) => state.game.attempts);
  const tooltip = useSelector((state) => state.game.tooltip);
  const trick = tricksArray[currentTrickNo - 1];

  const playerCount = useSelector((state) => state.game.playerCount);
  let lastWinner =
    winners.length > 0 ? winners[winners.length - 1].player : undefined;

  /**
   * Getarray :
   *  Intha array la enna irukkumna each player kana tricks mattum irukkum
   *  But whyyyy, to render a trick column as it is in the hand (HIGHLIGHT)
   */
  let getArray = Array.from(new Array(playerCount), () => []);

  previousTrick.map((item) => {
    item.map((card) => {
      if (card !== "") {
        getArray[card.player].push(card);
      }
    });
  });

  let playedCardCount = trick.filter((playedCard) => playedCard !== "");

  // For animation -----------------

  const [win, setWin] = useState(undefined);
  const [tempWinner, setTempWinner] = useState(null);

  useEffect(() => {
    if (win) {
      setTempWinner(win.player);
    }
  }, [win]);

  useEffect(() => {
    if (props.trickArray.some((t) => t != "") && props.gameLoaded) {
      let playedCardCount = props.trickArray.filter(
        (playedCard) => playedCard !== ""
      );
      // This is used for extra object, when played card count is 2
      if (playedCardCount.length) {
        setWin(tricks.getWinningCard(currentTrickNo));
      }
    }
  }, [props.trickArray, playerCount, currentTrickNo]);

  const target = useSelector((state) => state.game.target);

  const normalSuit = "white";

  const winningFontColor = "white";

  const winLight = isDarkMode ? Color.WHITE : "var(--hand-highlight)";
  const winDull = isDarkMode
    ? darkBgColor.containers
    : "var(--light-container)";

  const clickTick = () => {
    if(!isGuideNeed) {
      if(playedCardCount.length === 3){
        setAlertMessage("error", t("alertMessages.clickTick"));
      }
    }
  }
  

  // Trump div for current and finished trick array: Co
  const trumpFunc = (i, j, currentTrickNo, winner, card) => {
    return (
      <div
        style={{
          // paddingTop: tricksArray.length === 1 ? 0 : "var(--card-gap)",
          height: tricksArray.length === 1 ? "fit-content" : "auto",
          backgroundColor:
            tricksArray.length === 1
              ? trick[j] === ""
                ? winLight
                : winDull
              : trick.every((card) => card === "")
              ? lastWinner === j
                ? winLight
                : winDull
              : trick[j] === ""
              ? winLight
              : winDull,
          borderRadius: "0.5rem",
        }}
        key={j}
        className="trick-card1"
      >
        <div
          className={` ${
            playedCardCount.length > 1 && j !== tempWinner
              ? "trick-animate trick-card"
              : ""
          } trick-card`}
          style={
            i === currentTrickNo - 1
              ? target[j] === "-1"
                ? {
                    ...outerTrumpBorder,
                    color: outerTrumpBorder.backgroundColor,
                  }
                : winners[currentTrickNo - 1]
                ? winners[currentTrickNo - 1].player === j
                  ? { ...outerTrumpBorder, color: normalSuit }
                  : {
                      ...outerTrumpBorder,
                      color: outerTrumpBorder.backgroundColor,
                      backgroundColor: isDarkMode
                        ? darkBgColor.body
                        : "#FBFBFB",
                    }
                : {
                    ...outerTrumpBorder,
                    color: normalSuit,
                  }
              : winner
              ? {
                  ...outerTrumpBorder,

                  color: normalSuit,
                }
              : {
                  ...outerTrumpBorder,
                  backgroundColor: isDarkMode ? darkBgColor.body : "#FBFBFB",
                  color: outerTrumpBorder.backgroundColor,
                }
          }
        >
          <div
            key={card.rank}
            className={` trick-card`}
            style={
              i === currentTrickNo - 1 //running trick
                ? target[j] === "-1"
                  ? {
                      ...innerTrumpBorder,
                      backgroundColor: isDarkMode ? "#FBFBFB" : "#332D2D",
                      color: normalSuit,
                      borderColor: normalSuit,
                    }
                  : winners[currentTrickNo - 1]
                  ? winners[currentTrickNo - 1].player === j //choosing winning or losing card
                    ? {
                        //winning card
                        ...innerTrumpBorder,
                        color: normalSuit,
                        borderColor: normalSuit,
                        margin: "0px",
                      }
                    : {
                        //losing card
                        ...innerTrumpBorder,
                        margin: "0px",
                      }
                  : {
                      //finishedTrick
                      ...innerTrumpBorder,
                      color: normalSuit,
                      borderColor: normalSuit,
                      margin: "0px",
                    }
                : winner //finished trick
                ? {
                    ...innerTrumpBorder,

                    backgroundColor: ourTrumpColor,
                    color: normalSuit,
                    borderColor: normalSuit,
                    margin: "0px",
                  }
                : {
                    ...innerTrumpBorder,
                    backgroundColor: isDarkMode ? darkBgColor.body : "#FBFBFB",
                    color: ourTrumpColor,
                    borderColor: ourTrumpColor,
                    margin: "0px",
                    height: "calc(var(--card-size) / 1.5)",
                    width: "calc(var(--card-size) / 1.5)",
                  }
            }
          >
            <p
              className={`${
                playedCardCount.length > 1 && j !== tempWinner ? "font-no" : ""
              }`}
            >
              {card.rank}
            </p>
          </div>
        </div>
      </div>
    );
  };

  // NO-TRUMP div for current and finished trick array: Co
  const cardEnterTrick = useCallback(
    
    (i, j, card, normalSuit, winner, currentTrickNo) => {
      // console.log("for card entering trick")
    // dispatch(SET_ALERT_STOP(false))
      return (
        <div
          style={{
            height: tricksArray.length === 1 ? "fit-content" : "auto",
            backgroundColor:
              tricksArray.length === 1
                ? trick[j] === ""
                  ? winLight
                  : winDull
                : trick.every((card) => card === "")
                ? lastWinner === j
                  ? winLight
                  : winDull
                : trick[j] === ""
                ? winLight
                : winDull,
            borderRadius: "0.5rem",
            pointerEvents: tooltip ? "auto" : "none"
          }}
          key={card.rank + card.suit}
          className="trick-card1"
          onClick={clickTick}
        >
          <div
            className={` ${
              playedCardCount.length > 1 && j !== tempWinner
                ? "trick-animate trick-card"
                : ""
            } trick-card`}
            style={{
              // border: '6px solid var(--red)',
              margin: "0",
              ...(card.suit === "B"
                ? i === currentTrickNo - 1
                  ? target[j] === "-1"
                    ? {
                        ...blueBorder,
                        color: blueBorder.backgroundColor,
                      }
                    : winners[currentTrickNo - 1]
                    ? winners[currentTrickNo - 1].player === j
                      ? { ...blueBorder, color: normalSuit }
                      : {
                          ...blueBorder,
                          color: blueBorder.backgroundColor,
                          backgroundColor: isDarkMode
                            ? darkBgColor.body
                            : "#FBFBFB",
                        }
                    : {
                        ...blueBorder,
                        color: normalSuit,
                      }
                  : winner
                  ? {
                      ...blueBorder,

                      color: normalSuit,
                    }
                  : {
                      ...blueBorder,
                      backgroundColor: isDarkMode
                        ? darkBgColor.body
                        : "#FBFBFB",
                      color: blueBorder.backgroundColor,
                    }
                : card.suit === "Y"
                ? i === currentTrickNo - 1
                  ? target[j] === "-1"
                    ? {
                        ...yellowBorder,
                        color: normalSuit,
                      }
                    : winners[currentTrickNo - 1]
                    ? winners[currentTrickNo - 1].player === j
                      ? { ...yellowBorder, color: normalSuit }
                      : {
                          ...yellowBorder,
                          color: yellowBorder.backgroundColor,
                          backgroundColor: isDarkMode
                            ? darkBgColor.body
                            : "#FBFBFB",
                        }
                    : {
                        ...yellowBorder,
                        color: normalSuit,
                      }
                  : winner
                  ? {
                      ...yellowBorder,

                      color: normalSuit,
                    }
                  : {
                      ...yellowBorder,
                      backgroundColor: isDarkMode
                        ? darkBgColor.body
                        : "#FBFBFB",
                      color: yellowBorder.backgroundColor,
                    }
                : card.suit === "R"
                ? i === currentTrickNo - 1
                  ? target[j] === "-1"
                    ? {
                        ...redBorder,
                        color: normalSuit,
                      }
                    : winners[currentTrickNo - 1]
                    ? winners[currentTrickNo - 1].player === j
                      ? { ...redBorder, color: normalSuit }
                      : {
                          ...redBorder,
                          color: redBorder.backgroundColor,
                          backgroundColor: isDarkMode
                            ? darkBgColor.body
                            : "#FBFBFB",
                        }
                    : {
                        ...redBorder,
                        color: normalSuit,
                      }
                  : winner
                  ? {
                      ...redBorder,

                      color: normalSuit,
                    }
                  : {
                      ...redBorder,
                      backgroundColor: isDarkMode
                        ? darkBgColor.body
                        : "#FBFBFB",
                      color: redBorder.backgroundColor,
                    }
                : card.suit === "G"
                ? i === currentTrickNo - 1
                  ? target[j] === "-1"
                    ? {
                        ...greenBorder,
                        color: normalSuit,
                      }
                    : winners[currentTrickNo - 1]
                    ? winners[currentTrickNo - 1].player === j
                      ? { ...greenBorder, color: normalSuit }
                      : {
                          ...greenBorder,
                          color: greenBorder.backgroundColor,
                          backgroundColor: isDarkMode
                            ? darkBgColor.body
                            : "#FBFBFB",
                        }
                    : {
                        ...greenBorder,
                        color: normalSuit,
                      }
                  : winner
                  ? {
                      ...greenBorder,

                      color: normalSuit,
                    }
                  : {
                      ...greenBorder,
                      backgroundColor: isDarkMode
                        ? darkBgColor.body
                        : "#FBFBFB",
                      color: greenBorder.backgroundColor,
                    }
                : {}),
              pointerEvents: tooltip ? "auto" : "none",
            }}
            key={card.rank + card.suit}
          >
            <p
              className={`${
                playedCardCount.length > 1 && j !== tempWinner ? "font-no" : ""
              }`}
            >
              {card.rank}
            </p>
          </div>
        </div>
      );
    }
  );

  // winning NO-TRUMP for previous trick: Co
  const winningNTtrick = (
    playerInd,
    trickInd,
    card,
    normalSuit,
    currentTrickNo,
    winner
  ) => {
    return (
      <div
        className="win-trick-card"
        style={{
          border: "2px solid",
          fontFamily: "satoshi-bold",
          ...(card.suit === "B"
            ? winners[trickInd]
              ? winners[trickInd].player === playerInd
                ? { ...blueBorder, color: winningFontColor } // winning card
                : {
                    ...blueBorder,
                    color: blueBorder.backgroundColor, // losing card
                    backgroundColor: isDarkMode ? darkBgColor.body : "#FBFBFB",
                  }
              : {
                  ...blueBorder,
                  color: blueBorder.backgroundColor,
                  backgroundColor: isDarkMode ? darkBgColor.body : "#FBFBFB",
                }
            : card.suit === "Y"
            ? winners[trickInd]
              ? winners[trickInd].player === playerInd
                ? { ...yellowBorder, color: winningFontColor }
                : {
                    ...yellowBorder,
                    color: yellowBorder.backgroundColor,
                    backgroundColor: isDarkMode ? darkBgColor.body : "#FBFBFB",
                  }
              : {
                  ...yellowBorder,
                  color: yellowBorder.backgroundColor,
                  backgroundColor: isDarkMode ? darkBgColor.body : "#FBFBFB",
                }
            : card.suit === "R"
            ? winners[trickInd]
              ? winners[trickInd].player === playerInd
                ? { ...redBorder, color: winningFontColor }
                : {
                    ...redBorder,
                    color: redBorder.backgroundColor,
                    backgroundColor: isDarkMode ? darkBgColor.body : "#FBFBFB",
                  }
              : {
                  ...redBorder,
                  color: redBorder.backgroundColor,
                  backgroundColor: isDarkMode ? darkBgColor.body : "#FBFBFB",
                }
            : card.suit === "G"
            ? winners[trickInd]
              ? winners[trickInd].player === playerInd
                ? { ...greenBorder, color: winningFontColor }
                : {
                    ...greenBorder,
                    color: greenBorder.backgroundColor,
                    backgroundColor: isDarkMode ? darkBgColor.body : "#FBFBFB",
                  }
              : {
                  ...greenBorder,
                  color: greenBorder.backgroundColor,
                  backgroundColor: isDarkMode ? darkBgColor.body : "#FBFBFB",
                }
            : {}),
          pointerEvents: "none",
        }}
        key={card.rank + card.suit}
      >
        {card.rank}
      </div>
    );
  };

  // winning TRUMP for previous trick: Co
  const winningTrumpTrick = (playerInd, trickInd, card, winner) => {
    return (
      <div
        className="win-trick-card"
        key={trickInd}
        style={
          winners[trickInd]
            ? winners[trickInd].player === playerInd //choosing winning or losing card
              ? {
                  //winning card
                  ...outerTrumpBorder,
                  color: winningFontColor,
                  backgroundColor: trumpColor,
                  borderColor: trumpColor,
                }
              : {
                  //losing card
                  ...outerTrumpBorder,
                  backgroundColor: !isDarkMode ? "#FBFBFB" : darkBgColor.body,
                  borderColor: trumpColor,
                }
            : {
                //finishedTrick
                ...outerTrumpBorder,
                backgroundColor: !isDarkMode ? "#FBFBFB" : darkBgColor.body,
                borderColor: trumpColor,
              }
        }
      >
        <div
          key={card.rank}
          className="trick-card"
          style={
            winners[trickInd]
              ? winners[trickInd].player === playerInd //choosing winning or losing card
                ? {
                    //winning card
                    ...innerTrumpBorder,
                    color: winningFontColor,
                    borderColor: isDarkMode ? "#fbfbfb" : "#fbfbfb",
                    margin: "0px",
                  }
                : {
                    //losing card
                    ...innerTrumpBorder,
                    backgroundColor: isDarkMode ? darkBgColor.body : "#FBFBFB",
                    color: ourTrumpColor,
                    borderColor: ourTrumpColor,
                    margin: "0px",
                    height: "80%", //calc(var(--card-size) / 1.5)
                    width: "80%",
                  }
              : {
                  //finishedTrick
                  ...innerTrumpBorder,
                  color: normalSuit,
                  borderColor: normalSuit,
                  margin: "0px",
                }
          }
        >
          <p
            style={{
              margin: "3px",
              fontSize: "calc(var(--card-size) * 0.36)",
            }}
          >
            {card.rank}
          </p>
        </div>
      </div>
    );
  };

  // Empty div that 3: :( Co
  const emptyDiv = (i, j) => {
    return (
      <div
        className="trick-card1"
        style={{
          // paddingTop: tricksArray.length === 1 ? 0 : "var(--card-gap)",
          height: tricksArray.length === 1 ? "fit-content" : "auto",
          backgroundColor:
            tricksArray.length === 1
              ? trick[j] === ""
                ? winLight
                : winDull
              : trick.every((card) => card === "")
              ? lastWinner === j
                ? winLight
                : winDull
              : trick[j] === ""
              ? winLight
              : winDull,
        }}
        key={j}
      >
        <div
          key={nanoid()}
          className="trick-card"
          style={{
            ...(followSuit === "B"
              ? {
                  ...blueBorder,
                  backgroundColor: isDarkMode ? darkBgColor.body : "#FBFBFB",
                  color: "#332D2D",
                  border: "var(--card-gap) solid var(--dark-blue)",        
              }
              : followSuit === "T"
              ? {
                  ...outerTrumpBorder,
                  color: isDarkMode ? "#FBFBFB" : "#332D2D",
                  backgroundColor: isDarkMode ? darkBgColor.body : "#FBFBFB",

                  border: "var(--card-gap) solid ",
                  borderColor: ourTrumpColor,
                }
              : followSuit === "Y"
              ? {
                  ...yellowBorder,
                  backgroundColor: isDarkMode ? darkBgColor.body : "#FBFBFB",
                  color: "#332D2D",
                  border: "var(--card-gap) solid var(--yellow)",
                }
              : followSuit === "R"
              ? {
                  ...redBorder,
                  backgroundColor: isDarkMode ? darkBgColor.body : "#FBFBFB",
                  color: "#332D2D",
                  border: "var(--card-gap) solid var(--red)",
                }
              : followSuit === "G"
              ? {
                  ...greenBorder,
                  backgroundColor: isDarkMode ? darkBgColor.body : "#FBFBFB",
                  color: "#332D2D",
                  border: "var(--card-gap) solid var(--green)",
                }
              : {
                  border: props.trickArray.every((card) => card !== "")
                    ? "1px solid"
                    : "",
                  borderColor: isDarkMode
                    ? "var(--placeholder-white)"
                    : "var(--placeholder-black)",
                }),
            pointerEvents: "none",
            backgroundColor: isDarkMode ? darkBgColor.body : "#FBFBFB",
          }}
        ></div>
      </div>
    );
  };

  const GridForTricks = useCallback(() => {
    let empty = [];

    // For trick ( 4 ) iterations:
    for (let i = tricksArray.length - 1; i < tricksArray.length; i++) {
      for (let j = 0; j < playerCount; j++) {
        let card;
        let flag = -1;
        let player;
        let winner = false;
        if (tricksArray[i]) {
          flag = tricksArray[i].findIndex((element) => {
            if (element.player === j) {
              card = element.card;
              player = element.player;
              if (winners[i] !== undefined && winners[i].player === player) {
                winner = true;
              }
            }
            return element.player === j;
          });

          if (
            tricksArray[i].length > 0 ||
            tricksArray[i].length < playerCount
          ) {
            let emptyCard = tricksArray[tricksArray.length - 1].filter(
              (playedCard) => playedCard === ""
            );

            if (
              emptyCard.length === playerCount - 1 &&
              tricksArray[tricksArray.length - 1].length === playerCount
            ) {
              let firstPlayedCard = tricksArray[tricksArray.length - 1].find(
                (card) => card !== ""
              );

              dispatch(SET_FOLLOWSUIT(firstPlayedCard.card.suit));
            }
          }
        }
        if (flag != -1) {
          // this if for TRUMP only:----
          if (card.suit === "T") {
            if (tricksArray.length - 1 === i) {
              // it us a current array => dont push it to the finishedTrick[]
              if (currentTrickNo - 1 === i) {
                //pushing to empty(current running array)
                empty.push(trumpFunc(i, j, currentTrickNo, winner, card));
              }
            }
          }

          // this else for NO-TRUMP log:----
          // for card enters into trick grid
          else if (currentTrickNo - 1 === i) {
            // setAnimate(false)
            empty.push(
              // for card enters into trick grid

              cardEnterTrick(i, j, card, normalSuit, winner, currentTrickNo)
            );
            // setAnimate(false)
          }
        }
        // this else for  EMPTY NANO ID array:----
        else {
          // i think this is the one colors a border when first card played
          empty.push(emptyDiv(i, j));
        }
      }

    }
    //setEmptyArr(empty)
    // console.log("card after 3 cards ", empty)
    return empty;
  }, [props.trickArray, tricksArray, isDarkMode, tempWinner, followSuit, tooltip]);

  return (
    <>
      {previousTrick.length < 1 ? (
        <></>
      ) : (
        <div className="trick-grid-columns" key={nanoid()}>
          {getArray.map((item, i) => {
            return (
              <div
                className="grid-col"
                id={`trick-grid-col-${i + 1}`}
                style={{
                  // dullColumn
                  paddingTop: "0",
                  paddingBottom: !gameWon
                    ? attempts > 0
                      ? tricksArray.length <= 4
                        ? "var(--card-gap)"
                      // ? tricksArray.length === 1
                      //   ? playedCardCount === 0
                      //     ? 0
                      //     : "var(--card-gap)"
                        : props.buttonVisible
                        ? "var(--card-gap)"
                        : ""
                      : "var(--card-gap)"
                    : "var(--card-gap)",
                  borderRadius: !gameWon
                    ? attempts > 0
                      ? tricksArray.length <= 4
                        ? "0 0 .5rem .5rem"
                        // ? playedCardCount === 0
                        //   ? 0
                        //   : "0 0 .5rem .5rem"
                        : props.buttonVisible
                        ? "0 0 .5rem .5rem"
                        : ""
                      : "0 0 .5rem .5rem"
                    : "0 0 .5rem .5rem",
                  backgroundColor:
                    tricksArray.length === 1
                      ? trick[i] === ""
                        ? winLight
                        : winDull
                      : trick.every((card) => card === "")
                      ? lastWinner === i
                        ? winLight
                        : winDull
                      : trick[i] === ""
                      ? winLight
                      : winDull,
                }}
              >
                {item.map((trick, j) => {
                  if (trick.card.suit === "T") {
                    return <>{winningTrumpTrick(i, j, trick.card, winners)}</>;
                  } else {
                    return (
                      <>
                        {winningNTtrick(
                          i,
                          j,
                          trick.card,
                          normalSuit,
                          currentTrickNo - 2,
                          winners
                        )}
                      </>
                    );
                  }
                })}
              </div>
            );
          })}
        </div>
      )}
      {gameLost || gameWon ? (
        <></>
      ) : (
        <div className="trick-item-grid">
          {/* UNDO BUTTON */}
          <div
            className="button-trick"
            key={6540}
            style={{
              backgroundColor: "none",
              border: "none",
              paddingTop: tricksArray.length !== 1 ? 0 : "var(--card-gap)",
              height: tricksArray.length === 1 ? "fit-content" : "auto",
              // margin: 0,
              pointerEvents:
                props.trickArray.some((card) => card !== "") &&
                !(gameWon || gameLost)
                  ? "auto"
                  : "none",
              opacity: "100%",
              visibility:
                gameWon || gameLost || props.buttonVisible
                  ? "hidden"
                  : "visible",
            }}
          >
            {/* this creates undo button only */}
            <div
              className="trick-card"
              onClick={() => {
                {
                  dispatch(SET_FOLLOWSUIT(""));
                  // dispatch(SET_ALERT_STOP(true))
                  props.onUndo();
                  props.buttonClick(props.category, "undo");
                }
              }}
              style={{
                backgroundColor: isDarkMode ? darkBgColor.body : "#FBFBFB",
                color: props.trickArray.some((card) => card !== "")
                  ? isDarkMode
                    ? "#FBFBFB"
                    : "#332D2D"
                  : isDarkMode
                  ? "#FBFBFB"
                  : "#332D2D",
                border: props.trickArray.some((card) => card !== "")
                  ? "1px solid"
                  : "1px solid",
                opacity: props.trickArray.some((card) => card !== "")
                  ? "100%"
                  : isDarkMode
                  ? "20%"
                  : "20%",
                margin: "0",
                visibility:
                  gameWon || gameLost || props.buttonVisible
                    ? "hidden"
                    : "visible",
                borderColor: isDarkMode ? "#fbfbfb" : "#332d2d",
              }}
            >
              <div
                style={{
                  borderColor: "#fbfbfb",
                  opacity: isDarkMode
                    ? props.trickArray.some((card) => card !== "")
                      ? "1"
                      : "1"
                    : "1",
                }}
              >
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.70711 6.70711C9.09763 6.31658 9.09763 5.68342 8.70711 5.29289C8.31658 4.90237 7.68342 4.90237 7.29289 5.29289L8.70711 6.70711ZM5 9L4.29289 8.29289C3.90237 8.68342 3.90237 9.31658 4.29289 9.70711L5 9ZM7.29289 12.7071C7.68342 13.0976 8.31658 13.0976 8.70711 12.7071C9.09763 12.3166 9.09763 11.6834 8.70711 11.2929L7.29289 12.7071ZM5 16C4.44772 16 4 16.4477 4 17C4 17.5523 4.44772 18 5 18V16ZM7.29289 5.29289L4.29289 8.29289L5.70711 9.70711L8.70711 6.70711L7.29289 5.29289ZM4.29289 9.70711L7.29289 12.7071L8.70711 11.2929L5.70711 8.29289L4.29289 9.70711ZM5 10H16V8H5V10ZM19 13C19 14.6569 17.6569 16 16 16V18C18.7614 18 21 15.7614 21 13H19ZM16 10C17.6569 10 19 11.3431 19 13H21C21 10.2386 18.7614 8 16 8V10ZM5 18H16V16H5V18Z"
                    fill={isDarkMode ? "var(--white)" : "var(--black)"}
                  />
                </svg>
              </div>
            </div>
          </div>

          {/* CURRENT TRICK */}
          {props.trickArray.every((card) => card !== "") ? (
            <div style={{backgroundColor: !isDarkMode ? "var(--hand-highlight)" : "var(--lm-highlight)"}} className="full-3-trixk">{GridForTricks()}</div>
          ) : (
            <div className="empty-3-trixk">{GridForTricks()}</div>
          )}

          {/* ENTER BUTTON */}
          <div
            className={`button-trick`}
            key={35500}
            style={{
              backgroundColor: "none", //none
              border: "none",
              paddingTop: tricksArray.length !== 1 ? 0 : "var(--card-gap)",
              height: tricksArray.length === 1 ? "fit-content" : "auto",
              // margin: 0,
              pointerEvents:
                props.trickArray.every((card) => card !== "") &&
                !(gameWon || gameLost)
                  ? "auto"
                  : "none",
              opacity: "100%",
              visibility:
                gameWon || gameLost || props.buttonVisible
                  ? "hidden"
                  : "visible",
            }}
          >
            <div
              className="enter-button  trick-card"
              onClick={() => {
                props.onEnter();
                dispatch(SET_FOLLOWSUIT(""));
                props.buttonClick(props.category, "enter");
              }}
              style={{
                backgroundColor: isDarkMode ? darkBgColor.body : "#FBFBFB",
                color: props.trickArray.every((card) => card !== "")
                  ? isDarkMode
                    ? "#FBFBFB"
                    : "#332D2D"
                  : isDarkMode
                  ? "#FBFBFB"
                  : "#332D2D",
                border: props.trickArray.every((card) => card !== "")
                  ? "1px solid"
                  : "1px solid",
                opacity: props.trickArray.every((card) => card !== "")
                  ? "100%"
                  : isDarkMode
                  ? "20%"
                  : "20%",
                margin: "0",
                borderColor: isDarkMode ? "#fbfbfb" : "#332d2d",
              }}
            >
              <FontAwesomeIcon
                icon={faCheck}
                fontSize={20}
                // width='calc(var(--card-size) * .36)'
                // height='calc(var(--card-size) * .36)'
                color={isDarkMode ? "#fbfbfb" : "#332d2d"}
                opacity={
                  isDarkMode
                    ? props.trickArray.every((card) => card !== "")
                      ? "1"
                      : "1"
                    : "1"
                }
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TrickArray;
