import { getLocalState, setLocalStorageValue } from "./localStorage";


const stats = {
  winningPercentage: '',
  currentStreak: 0,
  maximumStreak: 0,
  gamesLost: 0,
  gamesWon: 0,
  gamesPlayed: 0,
  attempts: Array.from(new Array(5), () => 0)
}


export const saveStats = (gameWon,attempts,allAttempts) => {
  let gameStats = getLocalState('gamestats')
  if (gameStats === null) {
    gameStats = { ...stats }
    setLocalStorageValue('gamestats',gameStats)
  }

  gameStats.gamesPlayed += 1

  if (gameWon) {
    gameStats.gamesWon += 1
    gameStats.currentStreak += 1
    
    if (gameStats.maximumStreak < gameStats.currentStreak)
      gameStats.maximumStreak = gameStats.currentStreak
    
    gameStats.winningPercentage = 
        Math.round((gameStats.gamesWon / gameStats.gamesPlayed) * 100)
    
    let usedAttempt = allAttempts - attempts 

    gameStats.attempts[usedAttempt] += 1

    setLocalStorageValue('gamestats', gameStats)
    return
  }
  
  gameStats.winningPercentage = Math.round((gameStats.gamesWon / gameStats.gamesPlayed) * 100)
  
  gameStats.gamesLost += 1
  gameStats.currentStreak = 0
  
  setLocalStorageValue('gamestats', gameStats)
  return
}
