export const shareStatus = async (
  gameIndex,
  target,
  totalScore,
  winners,
  playerCount,
  cardCount,
  isDarkMode,
  isHardMode,
  handleShareToClipboard,
  handleShareFailure
) => {
  const textToShare =
    `Cardoo ${gameIndex} ${
      totalScore.length
    }/${5}\n\n` +
    generateEmojiGrid(
      target,
      playerCount,
      cardCount,
      winners,
      getEmojiTiles(isDarkMode),
    ) + '\n' + 'https://cardoo.today/'
  
  let shareSuccess = false
  // console.log('cardle share', winners)

  try {
    if (!shareSuccess ) {
      if (navigator.clipboard) {
        navigator.clipboard
          .writeText(textToShare)
          .then(() => {
            handleShareToClipboard()
          })
          .catch(() => {
            handleShareFailure()
          })
      } else {
        handleShareFailure()
      }
    }
  } catch (error) {
    handleShareFailure()
  }
}

export const generateEmojiGrid = (target, playerCount, cardCount, winners, tiles) => {
  let emojiGrid = []
  
  target.forEach(t => {
    emojiGrid.push(numbers[t])
  });
  emojiGrid.push('\n')

  for (let c = 0; c < cardCount; c++){
    for (let p = 0; p < playerCount; p++){
      if (winners[c] && winners[c].player == p) {
        emojiGrid.push(tiles.get(winners[c].card.suit))
      } else {
        emojiGrid.push(tiles.get('Empty'))
      }
    }
    emojiGrid.push('\n')
  }

  return emojiGrid.join('')

}

export const getEmojiTiles = (isDarkMode) => {
  let tiles = new Map()
  tiles.set('R', red)
  tiles.set('G', green)
  tiles.set('B', blue)
  tiles.set('Y', yellow)
  tiles.set('Empty', isDarkMode ? black : white)
  return tiles
}


const red = '🟥'
const green = '🟩'
const blue = '🟦'
const yellow = '🟨'
const black = '⬛'
const white = '⬜'
const numbers = ['0️⃣', '1️⃣', '2️⃣', '3️⃣', '4️⃣', '5️⃣', '6️⃣', '7️⃣', '8️⃣', '9️⃣', '🔟']