import { createGlobalStyle} from "styled-components";

export const GlobalStyles = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.colors.body};
    color: ${({ theme }) => theme.colors.text};
    font-family: ${({ theme }) => theme.font};
    font-weight: bold;
  }


  div.card1 {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    padding: 0px;
    font-size: calc(var(--card-size) * 0.36)
    margin: 0;
    border-radius: 4px;
    border: 1px solid #3a3a3c;
    width: var(--card-size);
    height: var(--card-size)
  }

  div.styles1{
    background-color: ${({ theme }) => theme.colors.body};
    color: ${({ theme }) => theme.colors.text};
    font-family: ${({ theme }) => theme.font};
    overflow: hidden;
    min-width: 20vw;
    left: auto;
    height: 50vh;
    outline: none;
    box-shadow: 0 4px 23px 0 rgb(0 0 0 / 20%);
    border-radius: 6px;
  }
 

  div.modal-help {
    background-color: ${({ theme }) => theme.colors.body};
    color: ${({ theme }) => theme.colors.text};
    font-family: ${({ theme }) => theme.font};
    overflow-y: scroll;
    min-width: 20vw;
    left: auto;
    height: 50vh;
    outline: none;
    box-shadow: 0 4px 23px 0 rgb(0 0 0 / 20%);
    border-radius: 6px;
  }

  div.squareCards {
    width: 10px;
    height: 10px;
    background-color:  ${({ theme }) => theme.colors.text};
    border-radius: 3px;
    margin: 2px 2px 2px 2px;
  }
  

  a {
    color: ${({ theme }) => theme.colors.text};
    cursor: pointer;
  }
  
  nav {
    background: ${({ theme }) => theme.colors.body};
    color: ${({ theme }) => theme.colors.text};
    font-family: ${({ theme }) => theme.font};
    box-shadow: 0px 2px 0px ${({ theme }) => theme.colors.shadow};
  }

  .tooltip .tooltip-text {
    font-size: 15px;
    visibility: hidden;
    width: 120px;
    background-color: ${({ theme }) => theme.colors.tooltip};
    color: ${({ theme }) => theme.colors.coltool};
    text-align: center;
    border-radius: 8px;
    padding: 4px 0;
    position: absolute;
    z-index: 1;
    top: 10px;
    left: 120%;
  }

  div.promptcontainer {
      text-align: center;
      border: 1px dashed ${({ theme }) => theme.colors.body};
      border-radius: 1rem;
      background-color:  ${({ theme }) => theme.colors.tooltip};
      color: ${({ theme }) => theme.colors.coltool};
  }
  
  .tooltip .tooltip-text::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color:  transparent ${({ theme }) => theme.colors.tooltip} transparent transparent;
  }

  .tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted ${({ theme }) => theme.colors.tooltip};
  }



  button {
    border: 0;
    display: inline-block;
    padding: 12px 24px;
    font-size: 14px;
    border-radius: 4px;
    margin-top: 5px;
    cursor: pointer;
    background-color: #1064EA;
    color: #FFFFFF;
    font-family: ${({ theme }) => theme.font};
  }

  button.btn {
    background-color: ${({ theme }) => theme.colors.button.background};
    color: ${({ theme }) => theme.colors.button.text};
  }
  div.target-container {
    display: flex;
    text-align: center;
    gap:20px;
  }
  div.target-item {
    align-items: center;
    justify-content: center;
  }

  div.help-card1 {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    padding: 0px;
    font-size: 20px;
    margin: 0;
    border-radius: 4px;
    border: 1px solid #3a3a3c;
    width: min(calc(80vw / (3)), calc((calc((80vh - 60px - 30vh)/7))));
    height: min(calc(80vw / (3)), calc((calc((80vh - 60px - 30vh)/7))));
    
  }

  div.help-target-container {
    display: grid;
    grid-template-columns: repeat(
      3,
      min(calc(80vw / (3)), calc((calc((80vh - 60px - 30vh) / 7))))
    );
    align-items: center;
    justify-content: center;
    margin-bottom: 1vh;
  }
  div.help-target-item {
    margin: auto;
    align-items: center;
    justify-content: center;
  }
`

