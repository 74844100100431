import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart,
  faHeartBroken,
  faArrowsRotate,
} from "@fortawesome/free-solid-svg-icons";
import "../../App.css";
import { useSelector } from "react-redux";

export default function SideGrid(props) {
  const tricks = useSelector((state) => state.game.tricks);
  const isDarkMode = useSelector((state) => state.game.isDarkMode);
  const attempts = useSelector((state) => state.game.attempts);
  const gameWon = useSelector((state) => state.game.gameWon);
  const gameLost = useSelector((state) => state.game.gameLost);
  const isAnimationComplete = useSelector(
    (state) => state.game.isAnimationComplete
  );

  const Attempts = () => {
    let attemptsArray = [];
    for (let i = 0; i < attempts; i++) {
      attemptsArray.push(
        <div className="lives" key={i} style={{ pointerEvents: "none" }}>
          <FontAwesomeIcon icon={faHeart} size="lg" overflow="visible" />
        </div>
      );
    }

    return attemptsArray;
  };

  const LoseOfAttempts = () => {
    let lose = 5 - attempts;
    let loseArray = [];
    for (let i = 0; i < lose; i++) {
      loseArray.push(
        <div
          className="lives"
          key={i}
          style={{
            opacity: "0.15",
            pointerEvents: "none",
          }}
        >
          <FontAwesomeIcon
            icon={faHeartBroken}
            color={isDarkMode ? "var(--white)" : "var(--black)"}
            size="lg"
            overflow="visible"
          />
        </div>
      ); //or broken heart 💔 //changed this also size of ullam
    }
    return loseArray;
  };

  return (
    <>
      <div className="lives-container">
        <div
          className={
            !gameWon &&
            attempts > 0 &&
            ((tricks.length > 1 && isAnimationComplete) || gameLost)
              ? "lives retry"
              : "lives"
          }
          onClick={() => {
            props.reset();
            props.buttonClick("real_game", "reset")
          }}
          style={{
            // transition: "transform 100ms linear",
            opacity:
              (tricks.length === 1 && !gameLost) ||
              attempts === 0 ||
              gameWon ||
              !isAnimationComplete
                ? ".2"
                : "1",
            border: "1px solid #3d3d3d",
            width: "calc(var(--card-size) * .7)",
            height: "calc(var(--card-size) * .7)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "5px",
            borderColor: isDarkMode ? "#ffffff" : "#3d3d3d",
          }}
        >
          <FontAwesomeIcon
            icon={faArrowsRotate}
            color={isDarkMode ? "var(--white)" : "var(--black)"}
            size="lg"
          />
        </div>
        {Attempts()}
        {LoseOfAttempts()}
      </div>
    </>
  );
}
