import { useDispatch, useSelector } from "react-redux"

import useAlert from "./useAlert"
import {
  SET_INFINITE_ANIMATION,
  SET_IS_PLAYED,
  SET_TRICKS
} from '../../redux/reducers/gameSlice'
import { useState } from "react"
import { useTranslation } from "react-i18next"

const success = 'success'
const error = 'error'

const useHandlePlay = () => {
  const [cardValid, setCardValid] = useState(false)
  const dispatch = useDispatch()
  const { setAlertMessage } = useAlert();
  const hands = useSelector(state => state.game.hands)
  const tricksArray = useSelector(state => state.game.tricks)
  const playerCount = useSelector(state => state.game.playerCount)
  const currentTrick = useSelector(state => state.game.currentTrick)
  const cardCount = useSelector(state => state.game.cardCount)
  const isGuideNeed = useSelector(state => state.game.isGuideNeed)
  const { t } = useTranslation()

  const cardPlay = (tricks, trickArray, card, player, setTrickArray) => {
    let hand = hands[player]
    let isCardValid = filterCards(
      card,
      player,
      tricks,
      trickArray,
      playerCount,
      setAlertMessage,
      tricksArray,
      hand,
      setCardValid,
      cardCount,
      t
    )

    if (isCardValid) {
      tricks.play({ card, player })

      let turnOrder = 1
      //counting the turn
      trickArray.forEach(playedCard => {
        if (playedCard !== '')
          turnOrder ++
      })

      // if the last card in the current trick played, enable infinite animation
      if (trickArray.filter(card => card == '').length === 1) {
        if (isGuideNeed) {
          dispatch(SET_INFINITE_ANIMATION(true))
        }
      }


      //adding the card to current trick
      let newTricksArray = [...trickArray]
      newTricksArray.forEach((_,index) => {
        if (player === index)
           newTricksArray.splice(index,1,{ card, player , turnOrder})
      })
      setTrickArray(newTricksArray)

      //changing the card played flag to true
      dispatch(
        SET_IS_PLAYED({
          card,
          player,
          isPlayed: true,
        })
      )

      //adding the played card to tricks array
      let gameTricks = [...tricksArray]
      gameTricks.splice(currentTrick - 1, 1, newTricksArray)
      dispatch(SET_TRICKS(gameTricks))

      
    }

    return tricks
  }

  return { cardPlay }
  
}

export default useHandlePlay


const filterCards = (
  card,
  player,
  tricks,
  trickArray,
  playerCount,
  setAlertMessage,
  tricksArray,
  hand,
  setCardValid,
  cardCount,
  t
) => {
  if (trickArray.every(card => card !== '')) {
    //if the currentTrick array length is equals to the player count just return
    setAlertMessage(success, t('alertMessages.clickEnter'))
    return
  }

  if (
    trickArray.every(card => card === '') &&
    tricksArray.length > 1 
  ) {
    let trickWinner = tricks.getWinningCard()
    if (trickWinner.player !== player) {
      setAlertMessage(error, t('alertMessages.winnerStartsFirst'))
      return
    }
  }

  //Checking the current turn
  if (trickArray.some(card => card.player === player)) {
    setAlertMessage(error, t('alertMessages.alreadyPlayedTheCard'))
    return
  }

  //filtering only unplayed cards
  let validCards = tricks.getValidCards(hand.filter(c => !c.isPlayed ))

  //Checking the played card
  if (
    validCards.findIndex(c => card.suit === c.suit && card.rank === c.rank) ===
    -1
  ) {
    setAlertMessage(error, t('alertMessages.followColor'))
    return
  }

  return true
}