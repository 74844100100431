import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import store from '../src/redux/index'
import './i18next'

// Google Analytics Modules - GAM
import { v4 as uuidv4 } from 'uuid';
import { logEvent } from "firebase/analytics";

import App from './App';
import './App.css'
import { analytics } from './firebase_setup/firebase';
import {
  setLocalStorageValue,
  getLocalState,
} from "./lib/localStorage";

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

let userType
let userID

// For checkiing the userType -- NEWWWWW or RETURNINGGGGG
// ALSO, set the userId for new user.....

if(getLocalState("userId")) {
  userID = getLocalState("userId")
  userType = "exist"
} else {
  setLocalStorageValue("userId", uuidv4())
  userID = getLocalState("userId")
  userType = "new"
}

// GA log events
logEvent(analytics, 'session_star', {
  event_id: uuidv4(), 
  event_timestamp: Date.now(), 
  user_id: userID,
  session_id: uuidv4(),
  user_type: userType,
  utm_source: 'website',
  utm_medium: 'whatsapp',
  utm_campaign: 'Kaadhaludan Cardoo',
});

const Index = () => {
  return(
  <React.StrictMode>
    <Provider store={store}>
        <App userID={userID}/>
    </Provider>
  </React.StrictMode>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Index />
  // document.getElementById('root')
);
