import { React } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import "../../App.css";
import useAlert from "../hooks/useAlert";
import { v4 as uuidv4 } from 'uuid';
import { analytics } from '../../firebase_setup/firebase';
import { logEvent } from "firebase/analytics";
import { userID } from "../../App";

let darkmode;

export default function HandGridData(props) {
  const { setAlertMessage } = useAlert();
  const { t } = useTranslation();
  const state = useSelector((state) => state);
  const hands = state.game.hands;
  const isDarkMode = state.game.isDarkMode;
  const playerCount = state.game.playerCount
  darkmode = isDarkMode;
  const winners = useSelector((state) => state.game.winners);
  let currentColumn = 0;
  let currentTrick = state.game.tricks[state.game.tricks.length - 1];
  let playedCards = currentTrick.filter((card) => card !== "");
  let currentTurn = state.game.currentTurn;
  if (state.game.tricks.length > 0) {
    currentColumn = currentTrick.length !== playerCount ? currentTurn : -1;
  }

  innerTrumpBorder.backgroundColor = props.trump
    ? state.game.suitColor.get(props.trump)
    : state.game.trumpColor;
  outerTrumpBorder.backgroundColor = props.trump
    ? state.game.suitColor.get(props.trump)
    : state.game.trumpColor;
  outerTrumpBorder.borderColor = state.game.trumpColor;
  //for checking the current Card is valid or not

  const validCard = () => {
    if (state.game.gameLost) return false;
    if (
      state.game.tricks[state.game.tricks.length - 1].some(
        (playedCard) => playedCard !== ""
      )
    ) {
      //if we already played from that column return false
      if (
        state.game.tricks[state.game.tricks.length - 1].find(
          (card) => card.player === props.player
        )
      )
        return false;

      let playedCard = state.game.tricks[state.game.tricks.length - 1].find(
        (playedCard) => playedCard.turnOrder === 1
      );
      if (playedCard) {
        if (playedCard.player === props.player) return false;

        if (
          hands[props.player].every(
            (handCard) => handCard.suit !== playedCard.card.suit
          )
        )
          return true;

        if (playedCard.card.suit === props.card.suit) return true;
        else return false;
      }
      return true;
    } else {
      if (winners.length > 0) {
        if (props.player === winners[winners.length - 1].player) return true;
        else return false;
      }
      return true;
    }
  };

  const startCardLog = (card) => {
    logEvent(analytics, 'tutorial_card_played', {
      event_id: uuidv4(), 
      event_timestamp: Date.now(), 
      user_id: userID,
      session_id: uuidv4(),
      played_card: card
    });
  }

  function handleMouseOver() {
    if (!state.game.lock) {

      if (state.game.isGuideNeed) {
        if (
          (state.game.currentTrick === 1 || state.game.currentTrick === 2) &&
          currentTrick.every((card) => card === "")
        ) {
          if (props.card.suit === "G") {
            props.onPlay(props.card, props.player);
            startCardLog(0)
          } else {
            setAlertMessage("error", t("alertMessages.startGreenCard"));
            startCardLog(props.card.rank)
          }
        } else if (
          state.game.currentTrick === 3 &&
          currentTrick.every((card) => card === "")
        ) {
          if (props.card.suit === "R") {
            props.onPlay(props.card, props.player);
          } else {
            setAlertMessage("error", t("alertMessages.startRedCard"));
          }
        } else {
          props.onPlay(props.card, props.player);
        }
      } 
      else {
        
        if ( validCard()) {
          props.onPlay(props.card, props.player);
        } else if(state.game.tooltip) {

          if (props.columnStatus === "played") {
            if (playedCards.length === state.game.playerCount)
              setAlertMessage("error", t("alertMessages.clickTick"));
            else if (playedCards.length !== (state.game.playerCount - 1))
              setAlertMessage("error", t("alertMessages.followColumns"));
             else 
              setAlertMessage("error", t("alertMessages.followColumn"));
            
          } else {
            if (state.game.tricks.length > 1 && !playedCards.length) {
              setAlertMessage("error", t("alertMessages.leadTheCard"));
            } else {
              setAlertMessage("error", t("alertMessages.followColor"));
            }
          }
        }
      }
    }
  }
  let isEmptyTrick = true;
  if (currentTrick.find((card) => card !== "")) {
    isEmptyTrick = false;
  }
  const handlePointerEvents = () => {
    if (!state.game.lock) {
      if (props.columnStatus === "played") {
        return "none";
      } else if (isEmptyTrick) {
        if (props.winnerCard) {
          return "auto";
        } else if (state.game.tricks.length === 1) {
          return "auto";
        } else {
          return "none";
        }
      } else {
        return "auto";
      }
    } else {
      return "none";
    }
  };

  const colorWhite = "var(--white)";
  const colorBlack = "#333";
  return (
    <div>
      {props.card.suit === "T" ? (
        <div
          className="card1"
          style={
            props.card.isPlayed
              ? { backgroundColor: "", border: "unset" }
              : {
                  ...outerTrumpBorder,
                  border: "unset",
                  /* borderColor:!isDarkMode ? '#332d2d' : '#fbfbfb' */
                  pointerEvents: state.game.lock ? "none" : "auto",
                  opacity: props.isHovered ? (validCard() ? "1" : "0.5") : "1",
                }
          }
        >
          <div
            key={props.rank}
            onClick={handleMouseOver}
            className="card1"
            style={
              props.card.isPlayed
                ? {
                    ...opacityFor,
                    backgroundColor: !isDarkMode
                      ? "#fbfbfb"
                      : "rgb(51 45 45 / 70%)",
                    color: colorWhite, //!isDarkMode ? "white" : "white",
                  }
                : {
                    ...innerTrumpBorder,
                    height: "80%",
                    width: "80%",
                    borderColor: colorWhite, //isDarkMode ?  '#fbfbfb' : '#fbfbfb',
                  }
            }
          >
            <p
              style={{
                margin: "3px",
                fontSize: "calc(var(--card-size) * 0.36)",
                color: colorWhite, //'#fbfbfb' : '#333',
              }}
            >
              {props.card.rank}
            </p>
          </div>
        </div>
      ) : (
        <div
          key={props.rank}
          onClick={handleMouseOver}
          className="card1"
          style={
            props.card.suit === "B"
              ? props.card.isPlayed
                ? {
                    ...blueBorder,
                    ...opacityFor,
                    color: colorWhite, //isDarkMode ? 'white' : '#333',
                  }
                : {
                    ...blueBorder,
                    pointerEvents: handlePointerEvents(),
                    pointerEvents: state.game.lock
                      ? "none"
                      : validCard()
                      ? "auto"
                      : ! state.game.isGuideNeed && state.game.tooltip
                      ? "auto"
                      : "none",
                    opacity: props.isHovered
                      ? validCard()
                        ? "1"
                        : "0.5"
                      : "1",
                    backgroundColor: props.forLayer
                      ? isDarkMode
                        ? "var(--dark-body)"
                        : "var(--light-body)"
                      : blueBorder.backgroundColor,
                    color: props.forLayer
                      ? isDarkMode
                        ? "var(--dark-body)"
                        : "var(--light-body)"
                      : props.share
                      ? "var(--blue)"
                      : colorWhite,
                    borderColor: props.forLayer
                      ? isDarkMode
                        ? "var(--dark-body)"
                        : "var(--light-body)"
                      : blueBorder.backgroundColor,

                    //backgroundColor: props.isHovered ? validCard() ? 'black' : {...blueBorder.backgrounColor} : {...blueBorder.backgrounColor}
                  }
              : props.card.suit === "Y"
              ? props.card.isPlayed
                ? {
                    ...yellowBorder,
                    ...opacityFor,
                    color: colorWhite, //isDarkMode ? 'white' : '#333',
                  }
                : {
                    ...yellowBorder,
                    pointerEvents: handlePointerEvents(),
                    pointerEvents: state.game.lock
                      ? "none"
                      : validCard()
                      ? "auto"
                      : ! state.game.isGuideNeed && state.game.tooltip
                      ? "auto"
                      : "none",
                    opacity: props.isHovered
                      ? validCard()
                        ? "1"
                        : "0.5"
                      : "1",
                    backgroundColor: props.forLayer
                      ? isDarkMode
                        ? "var(--dark-body)"
                        : "var(--light-body)"
                      : yellowBorder.backgroundColor,
                    color: props.forLayer
                      ? isDarkMode
                        ? "var(--dark-body)"
                        : "var(--light-body)"
                      : props.share
                      ? "var(--yellow)"
                      : colorWhite,
                    borderColor: props.forLayer
                      ? isDarkMode
                        ? "var(--dark-body)"
                        : "var(--light-body)"
                      : yellowBorder.backgroundColor,
                  }
              : props.card.suit === "R"
              ? props.card.isPlayed
                ? {
                    ...redBorder,
                    ...opacityFor,
                    color: colorWhite, //isDarkMode ? 'white' : '#333',
                  }
                : {
                    ...redBorder,
                    pointerEvents: handlePointerEvents(),
                    pointerEvents: state.game.lock
                      ? "none"
                      : validCard()
                      ? "auto"
                      : ! state.game.isGuideNeed && state.game.tooltip
                      ? "auto"
                      : "none",
                    opacity: props.isHovered
                      ? validCard()
                        ? "1"
                        : "0.5"
                      : "1",
                    backgroundColor: props.forLayer
                      ? isDarkMode
                        ? "var(--dark-body)"
                        : "var(--light-body)"
                      : redBorder.backgroundColor,
                    color: props.forLayer
                      ? isDarkMode
                        ? "var(--dark-body)"
                        : "var(--light-body)"
                      : props.share
                      ? "var(--red)"
                      : colorWhite,
                    borderColor: props.forLayer
                      ? isDarkMode
                        ? "var(--dark-body)"
                        : "var(--light-body)"
                      : redBorder.backgroundColor,
                  }
              : props.card.suit === "G"
              ? props.card.isPlayed
                ? {
                    ...greenBorder,
                    ...opacityFor,
                    color: colorWhite, //isDarkMode ? 'white' : '#333',
                  }
                : {
                    ...greenBorder,
                    pointerEvents: handlePointerEvents(),
                    pointerEvents: state.game.lock
                      ? "none"
                      : validCard()
                      ? "auto"
                      : ! state.game.isGuideNeed && state.game.tooltip
                      ? "auto"
                      : "none",
                    opacity: props.isHovered
                      ? validCard()
                        ? "1"
                        : "0.5"
                      : "1",
                    backgroundColor: props.forLayer
                      ? isDarkMode
                        ? "var(--dark-body)"
                        : "var(--light-body)"
                      : greenBorder.backgroundColor,
                    color: props.forLayer
                      ? isDarkMode
                        ? "var(--dark-body)"
                        : "var(--light-body)"
                      : props.share
                      ? "var(--green)"
                      : colorWhite,
                    borderColor: props.forLayer
                      ? isDarkMode
                        ? "var(--dark-body)"
                        : "var(--light-body)"
                      : greenBorder.backgroundColor,
                  }
              : {
                  ...itemStyle,
                  border: isDarkMode ? "2px solid white" : "2px solid black",
                  color: colorWhite, //isDarkMode ?  '#fbfbfb' : '#332d2d',
                  pointerEvents: handlePointerEvents(),
                  pointerEvents: state.game.lock
                    ? "none"
                    : validCard()
                    ? "auto"
                    : "none",
                  opacity: props.isHovered ? (validCard() ? "1" : "0.5") : "1",
                }
          }
        >
          <p style={{ margin: "3px" }}>{props.card.rank}</p>
        </div>
      )}
    </div>
  );
}

const itemStyle = {
  textAlign: "center",
  justifyContent: "center",
  color: "white",
  fontSize: "calc(var(--card-size) * 0.36)",
};
export const opacityFor = {
  opacity: 0,
  pointerEvents: "none",
};

export const redBorder = {
  ...itemStyle,
  border: "3px solid #DC4C64",
  backgroundColor: "#DC4C64",
};
export const greenBorder = {
  ...itemStyle,
  border: "3px solid #14A44D",
  backgroundColor: "#14A44D",
};
export const blueBorder = {
  ...itemStyle,
  border: "3px solid var(--dark-blue)",
  backgroundColor: "var(--dark-blue)",
};
export const yellowBorder = {
  ...itemStyle,
  border: "3px solid #E4A11B",
  backgroundColor: "#E4A11B",
};
export const outerTrumpBorder = {
  ...itemStyle,
  border: `3px solid`,
  borderColor: undefined,
  backgroundColor: undefined,
  height: "var(--card-size)",
  width: "var(--card-size)",
};
export const innerTrumpBorder = {
  ...outerTrumpBorder,
  borderRadius: "80%",
  height: "calc(var(--card-size) / 1.3)",
  width: "calc(var(--card-size) / 1.3)",
  backgroundColor: undefined,
  color: darkmode ? "#fbfbfb" : "#332d2d",
  borderColor: darkmode ? "#fbfbfb" : "#332d2d",
};
