import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Typography, Checkbox } from "@mui/material";
import { nanoid } from "nanoid";
import { greenBorder, redBorder, blueBorder } from "../Grid/HandGridData";
import { v4 as uuidv4 } from "uuid";
import { analytics } from "../../firebase_setup/firebase";
import { logEvent } from "firebase/analytics";
import { Color } from "../../constants/Colors";
import { emptyCard } from "../Walkthrough";
import { userID } from "../../App";

export default function Intro(props) {
  const state = useSelector((state) => state);
  const { t } = useTranslation();
  const isDarkMode = state.game.isDarkMode;
  const deviceSize = state.game.deviceSize;
  const langFont = state.game.langFont;

  const modalTextTwo = t("modalMessages.two").split(" ");
  const modalTextFour = t("modalMessages.four").split(" ");

  const rulesModalTextTwo = t("modalMessages.seven").split(" ");
  const colorFirstText = t("labels.colorFirst").split(" ");

  const trickyBalls = {
    //trick-balls comp
    width: "calc(var(--guide-card) / 3.4)",
    height: "calc(var(--guide-card) / 3.4)",
    backgroundColor: "var(--ball-color)",
    borderRadius: "50%",
  };

  const targetCards = {
    width: "calc(var(--guide-card) + (2 * var(--card-gap)))",
    height: "calc(var(--guide-card) + (2 * var(--card-gap)))",
    display: "grid",
    gridTemplateColumns: "repeat(2, calc(var(--guide-card) / 2))",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 auto",
    borderRadius: "0.5rem",
    border: "6px solid",
    rowGap: "2%",
    borderColor: isDarkMode
      ? "var(--dark-container)"
      : "var(--light-container)",
    backgroundColor: isDarkMode ? "rgb(51,51,51)" : "#ffffff",
  };

  const emptyColor = isDarkMode ? Color.DARK_BODY : Color.LIGHT_BODY;

  const targetBalls = {
    //target-balls comp
    width: "70%",
    height: "70%",
    backgroundColor: "var(--ball-color)",
    boxShadow: `0px 0px 0px 0.1rem ${
      isDarkMode ? "var(--dark-body)" : "var(--light-body)"
    }, 0px 0px 0px 0.17rem var(--ball-color)`,
    borderRadius: "50%",
    overFlow: "visible",
  };

  const targetBallsPlaceHolder = {
    width: "calc(var(--guide-card) / 2.51)", //calc(var(--guide-card) / 3.6)
    height: "calc(var(--guide-card) / 2.51)",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderColor: "var(--ball-color)",
    overFlow: "visible",
  };

  const tabCommon = {
    padding: "8px 4px",
    width: "50%",
    height: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "1.5rem 1.5rem 0 0",
  };

  const italicText = (text) => (
    <i style={{ fontFamily: "satoshi-light" }}> {` ${text} `} </i>
  );

  const trickItalic = (textArray) => {
    if (textArray) {
      return textArray.map((text) => {
        if (text.startsWith("<i>") && text.endsWith("</i>")) {
          // for italic texts
          return italicText(text.slice(3, -4));
        }
        // for all other texts
        return (
          <span
            key={nanoid()}
            style={{ fontFamily: "satoshi-light" }}
          >{` ${text} `}</span>
        );
      });
    } else {
      return null;
    }
  };

  const makeTrickBalls = () => {
    let trickBalls = [];

    for (let i = 0; i < 4; i++) {
      trickBalls.push(
        <div
          key={i + 3}
          className={i === 0 ? `infiniteBallFadeOut` : ""}
          style={{
            ...trickyBalls,
            backgroundColor: "var(--ball-color)",
          }}
        ></div>
      );
    }
    return trickBalls;
  };

  return (
    <>
      {/* This is top svg, don't touch it */}
      <Typography component={"div"}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
            height: "calc(var(--card-size) * 1.5)",
          }}
        >
          <svg
            width="40%"
            height="100%"
            viewBox="0 0 337 234"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            id="group"
          >
            <style></style>
            <rect
              x="116.532"
              y="116.084"
              width="94.0116"
              height="94.0116"
              rx="10.9195"
              transform="rotate(16.6221 116.532 116.084)"
              fill="var(--green)"
            />
            <path
              d="M134.346 178.241C132.684 183.808 136.172 188.769 143.245 190.881C150.36 193.005 156.009 190.725 157.658 185.201C158.757 181.518 157.552 177.922 154.667 175.829C157.724 175.512 159.918 173.659 160.805 170.688C162.292 165.707 159.052 161.139 152.774 159.265C146.454 157.378 141.144 159.44 139.695 164.295C138.808 167.266 139.585 170.005 141.901 172.019C138.358 172.283 135.433 174.6 134.346 178.241ZM144.412 166.204C145.162 163.693 147.855 162.765 151.329 163.802C154.76 164.826 156.462 167.067 155.7 169.62C154.938 172.173 152.287 173.113 148.855 172.089C145.381 171.052 143.65 168.757 144.412 166.204ZM139.69 179.427C140.577 176.455 143.596 175.351 147.53 176.525C151.464 177.7 153.384 180.278 152.497 183.25C151.61 186.221 148.591 187.326 144.698 186.164C140.722 184.977 138.803 182.398 139.69 179.427Z"
              fill="white"
            />
            <rect
              y="46.7969"
              width="94.0116"
              height="94.0116"
              rx="10.9195"
              transform="rotate(-18.0738 0 46.7969)"
              fill="var(--red)"
            />
            <path
              d="M75.1114 89.0405L73.4989 84.0993L60.8759 88.2187L64.8257 81.7379C68.1474 76.2891 69.4293 72.333 68.0471 68.0977C66.0416 61.9523 60.8079 59.5712 54.3303 61.6851C47.8943 63.7855 45.0682 69.486 47.1948 76.1432L52.4682 74.4223C51.236 70.5056 52.5533 67.5028 55.8751 66.4187C59.0724 65.3753 61.5778 66.7171 62.7025 70.1635C63.6511 73.0701 62.9312 75.5104 59.9587 80.3398L52.772 91.9661L54.0729 95.9523L75.1114 89.0405Z"
              fill="white"
            />
            <rect
              x="182.365"
              width="94.0116"
              height="94.0116"
              rx="10.9195"
              transform="rotate(6.4146 182.365 0)"
              fill="var(--yellow)"
            />
            <path
              d="M222.634 52.5223C227.061 53.02 228.881 55.5981 228.52 58.8101C228.125 62.3258 225.57 64.3242 222.098 63.9338C218.712 63.5532 216.69 61.2161 217.1 57.5701L211.631 56.9552C210.86 63.8131 215.248 68.1744 221.455 68.8722C227.879 69.5944 233.421 66.0419 234.153 59.5312C234.782 53.932 231.406 49.9483 226.652 48.4469L235.178 42.1972L235.676 37.7699L215.536 35.5057L214.985 40.4104L228.18 41.8938L219.176 48.4854L218.771 52.088L222.634 52.5223Z"
              fill="white"
            />
            <rect
              x="251.222"
              y="123.527"
              width="66.4617"
              height="66.4617"
              rx="7.71957"
              transform="rotate(-20.8006 251.222 123.527)"
              fill="var(--dark-blue)"
            />
            <path
              d="M291.444 136.285L298.199 154.066L301.749 152.718L293.733 131.617L285.564 134.72L286.825 138.04L291.444 136.285Z"
              fill="white"
            />
          </svg>
        </div>
      </Typography>

      {/* This is the inner-container, has 2 button tabs */}
      <Typography component={"div"} style={{ height: "fit-content" }}>
        {/* tab1: Which card wins? */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "20px",
          }}
        >
          <button
            style={{
              ...tabCommon,
              backgroundColor: props.isTab1Active
                ? "var(--ball-color)"
                : isDarkMode
                ? "var(--dark-body)"
                : "var(--light-body)",
              borderTop: props.isTab1Active
                ? "2px solid var(--ball-color)"
                : "2px solid var(--light-navbar-line)",
              borderLeft: props.isTab1Active
                ? "2px solid var(--ball-color)"
                : "2px solid var(--light-navbar-line)",
              borderRight: props.isTab1Active
                ? "2px solid var(--ball-color)"
                : "2px solid var(--light-navbar-line)",
              fontFamily: "satoshi",
              color: props.isTab1Active
                ? ""
                : isDarkMode
                ? "var(--white)"
                : "var(--light-active-column)",
              ...langFont.buttons,
            }}
            onClick={() => {
              props.setTab2Active(!props.isTab2Active);
              props.setTab1Active(!props.isTab1Active);
              props.buttonClick("intro", "win_cards");
            }}
          >
            <span>{t("buttons.whichCardWins")} </span>{" "}
          </button>
          <button
            style={{
              ...tabCommon,
              backgroundColor: props.isTab2Active
                ? "var(--ball-color)"
                : isDarkMode
                ? "var(--dark-body)"
                : "var(--light-body)",
              borderTop: props.isTab2Active
                ? "2px solid var(--ball-color)"
                : "2px solid var(--light-navbar-line)",
              borderLeft: props.isTab2Active
                ? "2px solid var(--ball-color)"
                : "2px solid var(--light-navbar-line)",
              borderRight: props.isTab2Active
                ? "2px solid var(--ball-color)"
                : "2px solid var(--light-navbar-line)",
              fontFamily: "satoshi",
              color: props.isTab2Active
                ? ""
                : isDarkMode
                ? "var(--white)"
                : "var(--light-active-column)",
              ...langFont.buttons,
            }}
            onClick={() => {
              props.setTab2Active(!props.isTab2Active);
              props.setTab1Active(!props.isTab1Active);
              props.buttonClick("intro", "solve_cardoo");
            }}
          >
            <span>{t("buttons.solveCardle")} </span>{" "}
          </button>
        </div>

        {props.isTab1Active ? (
          <div
            className="rules"
            style={{
              ...langFont.tutorial,
              height: deviceSize < 550 ? "calc(var(--card-size) * 8)" : "calc(var(--card-size) * 7.5)",
            }}
          >
            <div style={{ textAlign: "center", fontFamily: "satoshi" }}>
              {trickItalic(colorFirstText)}
            </div>
            <div className="light-font" style={{ textAlign: "center" }}>
              {t("labels.numberSecond")}
            </div>
            <div className="rule1">
              <div className="modal-trick">
                <div
                  style={{
                    backgroundColor: isDarkMode
                      ? "var(--dark-container)"
                      : "var(--light-container)",

                    borderRadius: "0.5rem 0.5rem 0.5rem 0.5rem",
                    pointerEvents: "none",
                  }}
                  className="trick-card1"
                >
                  <div
                    className="modal-card shrinkCard"
                    style={{
                      ...redBorder,
                      margin: "0",
                    }}
                  >
                    <p
                      className="scaleUpNumber"
                      style={{ fontFamily: "satoshi-bold" }}
                    >
                      1
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    backgroundColor: isDarkMode
                      ? "var(--dark-container)"
                      : "var(--light-container)",

                    borderRadius: "0.5rem",
                    pointerEvents: "none",
                  }}
                  className="trick-card1"
                >
                  <div
                    className="modal-card"
                    style={{
                      ...redBorder,
                      margin: "0",
                    }}
                  >
                    <p style={{ fontFamily: "satoshi-bold" }}>4</p>
                  </div>
                </div>
                <div
                  style={{
                    //paddingTop: tricksArray.length === 1 ? 0 : "var(--card-gap)",
                    //height: tricksArray.length === 1 ? "fit-content" : "auto",
                    backgroundColor: isDarkMode
                      ? "var(--dark-container)"
                      : "var(--light-container)",

                    borderRadius: "0.5rem",
                    pointerEvents: "none",
                  }}
                  className="trick-card1"
                >
                  <div
                    className="modal-card shrinkCard"
                    style={{
                      ...greenBorder,

                      margin: "0",
                    }}
                  >
                    <p
                      className="scaleUpNumber"
                      style={{ fontFamily: "satoshi-bold" }}
                    >
                      8
                    </p>
                  </div>
                </div>
              </div>
              <div className="light-font" style={{ textAlign: "center" }}>
                {t("modalMessages.one")}
                <div
                  className="light-font"
                  style={{
                    textAlign: "center",
                  }}
                >
                  {
                    // manipulating the text for translation
                    modalTextTwo.map((text) => {
                      if (text === "<card/>") {
                        return <span> {emptyCard(Color.RED, 4)}</span>;
                      }

                      if (text.startsWith("<i>") && text.endsWith("</i>")) {
                        return (
                          <i className="light-font">
                            {" " + text.slice(3, -4) + " "}
                          </i>
                        );
                      }

                      return (
                        <span key={nanoid()} className="light-font">
                          {" " + text + " "}
                        </span>
                      );
                    })
                  }
                </div>
              </div>
            </div>

            <div className="rule1">
              <div className="modal-trick">
                <div
                  style={{
                    backgroundColor: isDarkMode
                      ? "var(--dark-container)"
                      : "var(--light-container)",

                    borderRadius: "0.5rem",
                    pointerEvents: "none",
                  }}
                  className="trick-card1"
                >
                  <div
                    className="modal-card"
                    style={{
                      ...greenBorder,

                      margin: "0",
                    }}
                  >
                    <p>2</p>
                  </div>
                </div>
                <div
                  style={{
                    backgroundColor: isDarkMode
                      ? "var(--dark-container)"
                      : "var(--light-container)",

                    borderRadius: "0.5rem",
                    pointerEvents: "none",
                  }}
                  className="trick-card1"
                >
                  <div
                    className="modal-card shrinkCard"
                    style={{
                      ...blueBorder,

                      margin: "0",
                    }}
                  >
                    <p className="scaleUpNumber">5</p>
                  </div>
                </div>
                <div
                  style={{
                    backgroundColor: isDarkMode
                      ? "var(--dark-container)"
                      : "var(--light-container)",

                    borderRadius: "0.5rem",
                    pointerEvents: "none",
                  }}
                  className="trick-card1"
                >
                  <div
                    className="modal-card shrinkCard"
                    style={{
                      ...redBorder,

                      margin: "0",
                    }}
                  >
                    <p className="scaleUpNumber">9</p>
                  </div>
                </div>
              </div>
              <div className="light-font" style={{ textAlign: "center" }}>
                {t("modalMessages.three")}
                <div className="light-font" style={{ textAlign: "center" }}>
                  {
                    // manipulating the text for translation
                    modalTextFour.map((text) => {
                      if (text.startsWith("<i>") && text.endsWith("</i>")) {
                        return (
                          <i className="light-font">
                            {" " + text.slice(3, -4) + " "}
                          </i>
                        );
                      }

                      if (text === "<card/>") {
                        return <span> {emptyCard(Color.GREEN, 2)}</span>;
                      }

                      return (
                        <span key={nanoid()} className="light-font">
                          {" " + text}
                        </span>
                      );
                    })
                  }
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="rules"
            style={{
              ...langFont.tutorial,
              height: deviceSize < 550 
                ? "calc(var(--card-size) * 8)" : "calc(var(--card-size) * 7.5)",
            }}
          >
            <div
              style={{
                fontFamily: "satoshi-light",
                textAlign: "center",
                marginBottom: "calc(var(--card-gap) * 3)",
              }}
            >
              {rulesModalTextTwo.map((text) => {
                if (text.startsWith("<i>") && text.endsWith("</i>")) {
                  return (
                    // italic words
                    <i className="light-font">
                      {` ` + text.slice(3, -4) + ` `}
                    </i>
                  );
                }

                if (text === "<card/>") {
                  // for card text
                  return (
                    <span>
                      {" - "}
                      {emptyCard(Color.RED, 8)}
                      {" - "}
                    </span>
                  );
                }
                // for all other texts
                return (
                  <span key={nanoid()} className="light-font">
                    {" " + text + " "}
                  </span>
                );
              })}
            </div>

            <div className="rules-target-balls flex">{makeTrickBalls()}</div>

            <div className="rules-target-trick-container">
              <div className="rules-target-trick-item">
                {/* This is for target */}
                <div className="rules-target flex" style={targetCards}>
                  <div
                    style={targetBallsPlaceHolder}
                    id="guide-ball-placeholder-1"
                  >
                    <div style={targetBalls} className={`target-balls `}></div>
                  </div>
                  <div
                    style={targetBallsPlaceHolder}
                    id="guide-ball-placeholder-2"
                  >
                    <div style={targetBalls} className={`target-balls `}></div>
                  </div>
                  <div
                    style={targetBallsPlaceHolder}
                    id="guide-ball-placeholder-3"
                  >
                    <div style={targetBalls} className={`target-balls `}></div>
                  </div>
                  <div
                    style={targetBallsPlaceHolder}
                    id="guide-ball-placeholder-4"
                  >
                    <div style={targetBalls} className={`target-balls `}></div>
                    {/* </div> *className="target-balls"  style={targetBalls} */}
                  </div>
                </div>
              </div>
              {/* item2 */}
              <div className="rules-target-trick-item">
                <div className="rules-target flex" style={targetCards}>
                  <div
                    style={{
                      ...targetBallsPlaceHolder,
                      border: ".03rem solid var(--ball-color)",
                    }}
                    id="guide-ball-placeholder-1"
                  ></div>
                  <div
                    style={targetBallsPlaceHolder}
                    id="guide-ball-placeholder-2"
                  >
                    <div style={targetBalls} className={`target-balls `}></div>
                  </div>
                  <div
                    style={targetBallsPlaceHolder}
                    id="guide-ball-placeholder-3"
                  >
                    <div style={targetBalls} className={`target-balls `}></div>
                  </div>
                  <div
                    style={targetBallsPlaceHolder}
                    id="guide-ball-placeholder-4"
                  >
                    <div style={targetBalls} className={`target-balls `}></div>
                    {/* </div> *className="target-balls"  style={targetBalls} */}
                  </div>
                </div>
              </div>
              {/* item3 */}
              <div className="rules-target-trick-item">
                <div className="rules-target flex" style={targetCards}>
                  <div
                    style={targetBallsPlaceHolder}
                    id="guide-ball-placeholder-1"
                  >
                    <div
                      style={{
                        ...targetBalls,
                        backgroundColor: emptyColor,
                      }}
                    ></div>
                  </div>

                  <div
                    style={targetBallsPlaceHolder}
                    id="guide-ball-placeholder-2"
                  >
                    <div
                      style={{
                        ...targetBalls,
                        backgroundColor: emptyColor,
                      }}
                    ></div>
                  </div>
                  <div
                    style={{
                      ...targetBallsPlaceHolder,
                      border: ".03rem solid var(--ball-color)",
                    }}
                    id="guide-ball-placeholder-3"
                  >
                    <div
                      style={targetBalls}
                      className={`target-balls infiniteBallFadeIn `}
                    ></div>
                  </div>
                  <div
                    style={targetBallsPlaceHolder}
                    id="guide-ball-placeholder-4"
                  >
                    <div style={targetBalls} className={`target-balls`}></div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal-trick"
              style={{ marginTop: "calc(var(--card-gap) * 2)" }}
            >
              <div className="rules-trick">
                <div>
                  <div
                    style={{
                      backgroundColor: isDarkMode
                        ? "var(--dark-container)"
                        : "var(--light-container)",

                      borderRadius: "0.5rem",
                    }}
                    className="trick-card1"
                  >
                    <div
                      className="modal-card shrinkCard"
                      style={{
                        ...redBorder,
                        margin: "0",
                      }}
                    >
                      <p
                        className="scaleUpNumber"
                        style={{
                          fontFamily: "satoshi-bold",
                        }}
                      >
                        4
                      </p>
                    </div>
                  </div>
                </div>
                {/* This is the enter trick */}
              </div>
              <div className="rules-trick">
                <div>
                  <div
                    style={{
                      backgroundColor: isDarkMode
                        ? "var(--dark-container)"
                        : "var(--light-container)",

                      borderRadius: "0.5rem",
                    }}
                    className="trick-card1"
                  >
                    <div
                      className="modal-card shrinkCard"
                      style={{
                        ...redBorder,

                        margin: "0",
                      }}
                    >
                      <p
                        className="scaleUpNumber"
                        style={{
                          fontFamily: "satoshi-bold",
                        }}
                      >
                        1
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rules-trick">
                <div>
                  <div
                    style={{
                      backgroundColor: isDarkMode
                        ? "var(--dark-container)"
                        : "var(--light-container)",

                      borderRadius: "0.5rem",
                    }}
                    className="trick-card1"
                  >
                    <div
                      className="modal-card"
                      style={{
                        ...redBorder,

                        margin: "0",
                      }}
                    >
                      <p
                        style={{
                          fontFamily: "satoshi-bold",
                        }}
                      >
                        8
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="light-font"
              style={{
                textAlign: "center",
                marginTop: "calc(var(--card-gap) * 3)",
              }}
            >
              {t("modalMessages.five")}
            </div>
          </div>
        )}
      </Typography>
      <Typography component={"div"}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
          }}
        >
          {state.game.isGuideNeed ? (
            <></>
          ) : (
            <button
              className="no-breaking"
              style={{
                padding: "8px 4px",
                width: "40%",
                height: "70%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "0.5rem",
                backgroundColor: "var(--yellow)",
                fontFamily: "satoshi",
                margin: "0",
                ...langFont.buttons,
              }}
              onClick={() => {
                props.handleTutorial();
                props.handleHelp();
                props.buttonClick("intro", "goto_tutorial");
              }}
            >
              {t("labels.goToTutorial")}
            </button>
          )}
          <button
            className="no-breaking"
            style={{
              padding: "8px 4px",
              width: "40%",
              height: "70%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "0.5rem",
              backgroundColor: "var(--yellow)",
              fontFamily: "satoshi",
              margin: "0",
              opacity: "0.5",
              pointerEvents: "none",
              fontSize: "calc(var(--card-size) * .15)",
              ...langFont.tutorial,
            }}
            onClick={() => {
              // handleTutorial();
              // props.handleHelp();
              props.buttonClick("intro", "watch_video");
            }}
          >
            {t("labels.watchVideo")}
          </button>
        </div>
        {state.game.helpIntroFlag ? null /* for dont show again message */ : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              //gap: "20px",
            }}
          >
            <Checkbox
              checked={props.check}
              style={{
                padding: "4px",
                color: isDarkMode
                  ? "var(--white)"
                  : "var(--light-active-column)",
              }}
              size="small"
              onChange={() => {
                props.setCheck((prev) => !prev);
              }}
              onClick={() => {
                props.buttonClick("intro", "checkbox");
              }}
              // onClick={checkTutorial} //setLocalStorageValue("tutorial", props.showTutoModal)
            />
            <p
              style={{
                fontSize: "small",
                fontFamily: "satoshi-light",
                color: isDarkMode
                  ? "var(--white)"
                  : "var(--light-active-column)",
              }}
            >
              {t("labels.dontShowAgain")}
            </p>
          </div>
        )}
      </Typography>
    </>
  );
}
