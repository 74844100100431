import { createSlice } from "@reduxjs/toolkit";
import { getLocalState } from "../../lib/localStorage";
import { Color } from "../../constants/Colors";

const suitColor = new Map([
  ["G", Color.GREEN],
  ["R", Color.RED],
  ["Y", Color.YELLOW],
  ["B", Color.BLUE],
]);

const initialState = {
  playerCount: 4,
  cardCount: 4,
  hands: [],
  tricks: [["", "", "", ""]],
  target: [],
  animatedTarget: [],
  infiniteAnimatedTarget: [],
  winners: [],
  score: [0, 0, 0, 0],
  attempts: 5,
  currentTrick: 1,
  currentTurn: 0,
  alertMessage: null,
  gameLost: false,
  gameWon: false,
  lock: false,
  totalScore: [],
  isDarkMode:
    getLocalState("lightmode") !== null ? getLocalState("lightmode") : false,
  isHighContrast: false,
  columnHint: null,
  followSuit: "",
  walkthroughStep: 1,
  isGuideNeed: false,
  helpIntroFlag: false,
  isPreviouslyVisitedTrump: false,
  prevTrick: [],
  showTutorial: getLocalState("tutorial") ? false : true,
  deviceSize: "",
  saveStats: false,
  trumpColor: null,
  suitColor: suitColor,
  gameIndex: 0,
  ballCount: 0,
  isAnimationNeed: false,
  fadeIn: false,
  infiniteAnimation: false,
  stopAnimation: false,
  isAnimationComplete: false,
  gamePractice: false,
  showProblems: null,
  emptyCard: 0,
  practiceSelection: false,
  langFont: '',
  problemsPage: 1,
  noTrumpProblems: true,
  language : getLocalState("language") !== null ? getLocalState("language") : { "eng-script" : "English", "own-script" : "ENGLISH", code: "en-IN" },
  tooltip:
    getLocalState("tooltip") !== null ? getLocalState("tooltip") : true,
  solvedPracticeProblems: getLocalState("solvedProblems") !== null ? getLocalState("solvedProblems") : [],
  cardSize: '',
  alertStop: false
};

export const gameSlice = createSlice({
  name: "game",
  initialState,
  reducers: {

    CHANGE_LANG: (state,action) => {
      state.language = action.payload
    },

    CHANGE_CARD_COUNT: (state, action) => {
      state.cardCount = action.payload;
    },

    CHANGE_PLAYER_COUNT: (state, action) => {
      state.playerCount = action.payload;
    },
    SET_HANDS: (state, action) => {
      state.hands = action.payload;
    },

    SET_TRICKS: (state, action) => {
      state.tricks = action.payload;

      // state.prevTrick = action.payload;
      if (
        state.tricks.length > 1 &&
        state.prevTrick.length < state.tricks.length - 1
      ) {
        state.prevTrick.push(state.tricks[state.tricks.length - 2]);
      } else if (state.tricks.length === 1) {
        state.prevTrick = [];
      }
    },
    SET_PREV_TRICK: (state, action) => {
      state.prevTrick = action.payload;
    },
    SET_TARGET: (state, action) => {
      state.target = action.payload;
    },
    UPDATE_TARGET: (state, action) => {
      state.target[action.payload] = (
        state.target[action.payload] - 1
      ).toString();
    },
    SET_SCORE: (state, action) => {
      state.score = action.payload;
    },
    SET_GAME_WON: (state, action) => {
      state.gameWon = action.payload;
    },
    UPDATE_ATTEMPTS: (state, action) => {
      state.attempts = action.payload;
      if (state.attempts === 0) state.lock = true;
    },
    ADD_WINNERS: (state, action) => {
      state.winners.push(action.payload);
    },
    SET_CURRENT_TRICK: (state, action) => {
      state.currentTrick = action.payload;
    },
    SET_CURRENT_TURN: (state, action) => {
      if (action.payload !== undefined) {
        state.currentTurn = action.payload;
      } else {
        state.currentTurn = (state.currentTurn + 1) % 3;
      }
    },
    SET_ALERT_MESSAGE: (state, action) => {
      state.alertMessage = action.payload;
    },
    SET_IS_PLAYED: (state, action) => {
      state.hands[action.payload.player].forEach((card, index) => {
        if (
          action.payload.card.suit === card.suit &&
          action.payload.card.rank === card.rank
        )
          state.hands[action.payload.player][index].isPlayed =
            action.payload.isPlayed;
      });
    },
    SET_GAME_LOST: (state, action) => {
      state.gameLost = action.payload;
    },
    UNDO_CARD: (state) => {
      let newTricksArray = [];
      for (let i = 0; i < state.playerCount; i++) newTricksArray.push("");

      state.tricks[state.tricks.length - 1] = newTricksArray;
      if (state.currentTurn === 0) {
        state.currentTurn = 2;
      } else if (state.currentTurn === 1) {
        state.currentTurn = 0;
      } else state.currentTurn = 1;
    },
    SET_TOTAL_SCORE: (state, action) => {
      state.totalScore = action.payload;
    },
    CHANGE_DARK_MODE: (state, action) => {
      state.isDarkMode = action.payload;
    },
    SHOW_TUTORIAL_MODAL: (state, action) => {
      state.showTutorial = action.payload;
    },
    RESET_WINNERS: (state) => {
      state.winners = [];
    },
    REMOVE_PLAYED_CARDS: (state) => {
      state.hands.forEach((hand, i) => {
        hand.forEach((cards, j) => {
          if (cards.isPlayed) state.hands[i].splice(j, 1);
        });
      });
    },
    GAME_UNLOCK: (state) => {
      state.lock = false;
    },
    GAME_LOCK: (state) => {
      state.lock = true;
    },
    SET_HINT: (state, action) => {
      state.columnHint = action.payload;
    },
    SET_FOLLOWSUIT: (state, action) => {
      state.followSuit = action.payload;
    },
    UPDATE_WALKTHROUGH_STEP: (state, action) => {
      state.walkthroughStep = action.payload;
    },
    ENABLE_GUIDE: (state) => {
      state.isGuideNeed = true;
    },
    DISABLE_GUIDE: (state) => {
      state.isGuideNeed = false;
    },
    SET_INTRO_FLAG: (state, action) => {
      state.helpIntroFlag = action.payload;
    },
    SET_VISITED_TRUMP: (state, action) => {
      state.isPreviouslyVisitedTrump = action.payload;
    },
    SET_DEVICE_SIZE: (state, action) => {
      state.deviceSize = action.payload;
    },
    LOAD_DATA: (state, action) => {
      let data = action.payload;

      // for calculting the prevTrick
      data.prevTrick = [];
      if (data.tricks) {
        if (data.tricks.length > 1) {
          data.tricks.forEach((trick, index) => {
            if (
              !data.gameLost &&
              !data.gameWon &&
              index + 1 === data.tricks.length
            )
              return;

            data.prevTrick.push(trick);
          });
        } else if (data.tricks.length === 1) {
          if (data.gameLost) data.prevTrick = data.tricks;
          else data.prevTrick = [];
        }
      }

      // to load the data
      state = {
        ...state,
        ...data,
      };
      return state;
    },
    SET_SAVE_STATS: (state, action) => {
      state.saveStats = action.payload;
    },
    SET_BALL_COUNT: (state, action) => {
      state.ballCount = action.payload;
    },
    REDUCE_BALL_COUNT: (state) => {
      state.ballCount = state.ballCount - 1;
    },
    SET_ANIMATION: (state, action) => {
      state.isAnimationNeed = action.payload;
    },
    SET_ANIMATED_TARGET: (state) => {
      state.animatedTarget = state.target;
      if (state.target.every((t) => t !== -1)) state.fadeIn = true;
    },
    UPDATE_ANIMATED_TARGET: (state, action) => {
      state.animatedTarget[action.payload] = (
        state.animatedTarget[action.payload] - 1
      ).toString();

      state.fadeIn = false;
    },
    SET_FADE_IN: (state, action) => {
      state.fadeIn = action.payload;
    },
    SET_INFINITE_ANIMATION: (state, action) => {
      state.infiniteAnimation = action.payload;
    },
    UPDATE_INFINITE_ANIMATED_TARGET: (state, action) => {
      state.infiniteAnimatedTarget[action.payload] = (
        state.infiniteAnimatedTarget[action.payload] - 1
      ).toString();
    },
    SET_INFINITE_ANIMATED_TARGET: (state) => {
      state.infiniteAnimatedTarget = state.target;
    },
    SET_TRUMP_COLOR: (state, action) => {
      state.trumpColor = action.payload;
      state.suitColor.set("T", action.payload);
    },
    SET_GAME_INDEX: (state, action) => {
      state.gameIndex = action.payload;
    },
    SET_STOP_ANIMATION: (state, action) => {
      state.stopAnimation = action.payload;
    },
    SET_ANIMATION_COMPLETE: (state, action) => {
      state.isAnimationComplete = action.payload;
    },
    SET_GAME_PRACTICE: (state, action) => {
      state.gamePractice = action.payload;
    },
    SHOW_PROBLEMS: (state, action) => {
      state.showProblems = action.payload;
    },
    SET_EMPTY_CARD: (state, action) => {
      state.emptyCard = action.payload;
    },
    SET_PRACTICE_SELECTION: (state, action) => {
      state.practiceSelection = action.payload;
    },
    SET_LANG_FONT_SIZE: (state, action) => {
      state.langFont = action.payload;
    },
    SET_PROBLEMS_PAGE: (state, action) => {
      state.problemsPage = action.payload
    },
    SET_NO_TRUMP_PROBLEMS: (state, action) => {
      state.noTrumpProblems = action.payload
    },
    TOGGLE_TOOLTIP : (state,action) => {
      state.tooltip = action.payload;
    },
    SET_SOLVED_PROBLEMS : (state,action) => {
      if(!state.solvedPracticeProblems.includes(action.payload)){
          state.solvedPracticeProblems.push(action.payload);
      }
    },
    SET_CARD_SIZE : (state, action) => {
      state.cardSize = action.payload
    },
    SET_ALERT_STOP: (state, action) => {
      state.alertStop = action.payload
    }
  },
});

export const {
  SET_SOLVED_PROBLEMS,
  TOGGLE_TOOLTIP,
  CHANGE_CARD_COUNT,
  CHANGE_PLAYER_COUNT,
  SET_HANDS,
  SET_TRICKS,
  SET_TARGET,
  SET_SCORE,
  SET_GAME_WON,
  SET_GAME_LOST,
  UPDATE_ATTEMPTS,
  SET_CURRENT_TRICK,
  SET_ALERT_MESSAGE,
  SET_IS_PLAYED,
  ADD_WINNERS,
  UNDO_CARD,
  SET_CURRENT_TURN,
  SET_TOTAL_SCORE,
  CHANGE_DARK_MODE,
  RESET_WINNERS,
  REMOVE_PLAYED_CARDS,
  UPDATE_TARGET,
  GAME_LOCK,
  GAME_UNLOCK,
  SET_HINT,
  SET_FOLLOWSUIT,
  UPDATE_WALKTHROUGH_STEP,
  ENABLE_GUIDE,
  DISABLE_GUIDE,
  SET_INTRO_FLAG,
  SET_VISITED_TRUMP,
  SET_PREV_TRICK,
  SHOW_TUTORIAL_MODAL,
  SET_DEVICE_SIZE,
  LOAD_DATA,
  SET_SAVE_STATS,
  SET_TRUMP_COLOR,
  SET_GAME_INDEX,
  SET_BALL_COUNT,
  REDUCE_BALL_COUNT,
  SET_ANIMATION,
  SET_ANIMATED_TARGET,
  SET_FADE_IN,
  UPDATE_ANIMATED_TARGET,
  SET_INFINITE_ANIMATION,
  UPDATE_INFINITE_ANIMATED_TARGET,
  SET_INFINITE_ANIMATED_TARGET,
  SET_STOP_ANIMATION,
  SET_ANIMATION_COMPLETE,
  SET_GAME_PRACTICE,
  SHOW_PROBLEMS,
  SET_EMPTY_CARD,
  SET_PRACTICE_SELECTION,
  CHANGE_LANG,
  SET_LANG_FONT_SIZE,
  SET_PROBLEMS_PAGE,
  SET_NO_TRUMP_PROBLEMS,
  SET_CARD_SIZE,
  SET_ALERT_STOP,
} = gameSlice.actions;

export default gameSlice.reducer;
