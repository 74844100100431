import { React, useEffect, useState } from "react";
import HandGridData from "./HandGridData";
import { useSelector, useDispatch } from "react-redux";
import { Color } from "../../constants/Colors";

import { darkBgColor } from "../Home";

function HandDetails(card, player) {
  this.card = card;
  this.player = player;
}

let columnStatus;

const HandGrid = (props) => {

  const [isHovered, setHovered] = useState(false);
  const dispatch = useDispatch()
  const game = useSelector((state) => state.game);
  const playerCount = useSelector(state => state.game.playerCount)
  let Hands = props.hands ? props.hands :  game.hands;
  let lastTrick = game.tricks ? game.tricks[game.tricks.length - 1] : undefined;
  let lastWinner =
    game.winners.length > 0
      ? game.winners[game.winners.length - 1].player
      : undefined;

  if (game.lock) {
    columnStatus = undefined;
  }
  let gridCards = [];
  if (Hands.length > 0) {
    for (let i = 0; i < Hands[0].length; i++) {
      for (let j = 0; j < Hands.length; j++) {
        let eachCardDetail = new HandDetails(Hands[j][i], j);
        gridCards.push(eachCardDetail);
      }
    }
  }
  let emptyTrick = true;
  let emptyCards = lastTrick
    ? lastTrick.filter((card) => card === "")
    : undefined;
  if (emptyCards && emptyCards.length !== playerCount) {
    emptyTrick = false;
  }

  let winnerCard = false;

  const highlightColumn = () => {
    const highlight = {
      backgroundColor: game.isDarkMode ? Color.WHITE : "var(--hand-highlight)", //"rgb(45 45 45 / 70%)"
      borderRadius: "0.5rem",
    };
    return highlight;
  };

  const dullColumn = () => {
    const dull = {
      borderRadius: "0.5rem",
      backgroundColor: props.forLayer
        ? game.isDarkMode
          ? "var(--dark-body)"
          : "var(--light-body)"
        : game.isDarkMode ? darkBgColor.containers : "#EAE5BE",
    };

    return dull;
  };
  //rgb(159 166 178 / 50%)
  //#FFDDD2
  //emptyTrick && secondLastWinner ? doAnimation() : <></>
  return (
    <>
      {
      
      Hands.length > 0 ? (
       
         Hands.map((hand, i) => {
          {props.hands ? <></> :
            lastTrick[i] === ""
              ? (columnStatus = "unplayed")
              : (columnStatus = "played");
            emptyTrick && game.tricks.length > 1 && lastWinner === i
              ? (winnerCard = true)
              : (winnerCard = false);
          }
          return !props.hands && game.hands[0].length === 0 ? (
            <div style={{ padding: "0px" }}></div>
          ) : (
            <div
              className="col" // hand-grid-columns
              id={`hand-grid-col-${i + 1}`}
              key={i}
              style={
                props.hands || props.share
                  ? {...dullColumn(), marginTop: (i + 1) % 2 === 0 ? "calc(var(--card-size) * .5)" : 0}
                  : {
                      ...(!game.lock
                        ? emptyTrick
                          ? game.tricks.length === 1
                            ? highlightColumn(winnerCard, i)
                            : game.tricks.length > 1
                            ? lastWinner === i
                              ? highlightColumn(winnerCard, i)
                              : dullColumn(lastWinner, i)
                            : {}
                          : columnStatus === "unplayed"
                          ? highlightColumn(winnerCard, i)
                          : dullColumn(lastWinner, i)
                        : dullColumn(lastWinner, i)),
                        marginTop: (i + 1) % 2 === 0 ?  "calc(var(--card-size) * .5)" : 0
                    }
              }
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
            >
              {hand.map((card, j) => {
                return props.hands ? (
                  <HandGridData
                    key={`${card.rank}${card.suit}`}
                    card={card}
                    player={i}
                    trump={props.trump}
                    share={props.share}
                    forLayer={props.forLayer}
                  />
                ) : (
                  <HandGridData
                    key={`${card.rank}${card.suit}`}
                    card={card}
                    player={i}
                    onPlay={props.onPlay}
                    columnStatus={columnStatus}
                    winnerCard={winnerCard}
                    isHovered={isHovered}
                    trump={props.trump}
                  />
                );
              })}
              {/*  {emptyTrick && lastWinner === i ? doAnimation(i) : <></>} */}
            </div>
          );
        })
        
      ) : null
      }
    </>
  );
}


export { columnStatus }
export default HandGrid